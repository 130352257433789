;(function ($, window, document, undefined) {
  'use strict';

  Foundation.libs.gsclearing = {
    name : 'gsclearing',

    version: '5.3.3',

    settings : {
      templates : {
        viewing : '<a href="#" class="gsclearing-close">&times;</a>' +
          '<div class="visible-img" style="display: none"><div class="gsclearing-touch-label"></div><img src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D" alt="" >' +
          '<p class="gsclearing-caption"></p><a href="#" class="gsclearing-main-prev"><span></span></a>' +
          '<a href="#" class="gsclearing-main-next"><span></span></a>'+
          '<div class="gsclearing-social" style="display: none"><span><i class="fa fa-facebook"></i></span></div>' +
          '</div>'
      },
        //<span><i class="fa fa-google-plus"></i></span> 

      // comma delimited list of selectors that, on click, will close gsclearing,
      // add 'div.gsclearing-blackout, div.visible-img' to close on background click
      close_selectors : '.gsclearing-close, div.gsclearing-blackout', 

      // Default to the entire li element.
      open_selectors : '',

      // Image will be skipped in carousel.
      skip_selector : '',
        
      touch_label : '&larr;&nbsp;Swipe to Advance&nbsp;&rarr;',

      // event initializers and locks
      init : false,
      locked : false,
      closed: true,
        
      //customizzazioni e bugfix
      last_opened: null
    },

    init : function (scope, method, options) {
      var self = this;
      Foundation.inherit(this, 'throttle image_loaded');

      this.bindings(method, options);

      if (self.S(this.scope).is('[' + this.attr_name() + ']')) {
        this.assemble(self.S('li', this.scope));
      } else {
        self.S('[' + this.attr_name() + ']', this.scope).each(function () {
          self.assemble(self.S('li', this));
        });
      }
    },

    events : function (scope) {
      var self = this,
          S = self.S,
          $scroll_container = $('.scroll-container'),
          $loader = $('.gsclearing-loading'),
          last_opened = this.settings.last_opened;

      if ($scroll_container.length > 0) {
        this.scope = $scroll_container;
      }

      S(this.scope)
        .off('.gsclearing')
        .on('click.fndtn.gsclearing', 'ul[' + this.attr_name() + '] li ' + this.settings.open_selectors,
          function (e, current, target ) {
            var current = current || S(this),
                target = target || current,
                next = current.next('li'),
                settings = current.closest('[' + self.attr_name() + ']').data(self.attr_name(true) + '-init'),
                image = S(e.target),
                generaNotify = true ;

            e.preventDefault();

            if (!settings) {
              self.init();
              settings = current.closest('[' + self.attr_name() + ']').data(self.attr_name(true) + '-init');
            }

            //genero notify solo se target == current o se è diverso da ultimo cliccato
            if(current.index() == S(this).index() || (last_opened && S(this).index() != last_opened.index() )) {
                generaNotify = true;
            }
            else {
                generaNotify = false;
            }
            last_opened = S(this);
              
            // if gsclearing is open and the current image is
            // clicked, go to the next image in sequence
            if (target.hasClass('visible') &&
              current[0] === target[0] &&
              next.length > 0 && self.is_open(current)) {
              target = next;
              image = S('img', target);
              //console.log('asd');
            }
            //console.debug('from ev' + settings.closed);
            // set current and target to the clicked li if not otherwise defined.
            //  $loader.fadeIn('slow');
            self.open(image, current, target , generaNotify);
            self.update_paddles(target);
          })

        .on('click.fndtn.gsclearing', '.gsclearing-main-next',
          function (e) { 
             // $loader.css('display','none');
              //$loader.fadeIn('slow');
              //console.log('loader in');
              self.nav(e, 'next') 
          })
        .on('click.fndtn.gsclearing', '.gsclearing-main-prev',
          function (e) { 
              //$loader.css('display','none');
              //$loader.fadeIn('slow');
              //console.log('loader in');
              self.nav(e, 'prev') 
          })
        .on('click.fndtn.gsclearing', this.settings.close_selectors,
          function (e) { 
              //$loader.css('display', 'none');
              //console.log('loader out');
              Foundation.libs.gsclearing.close(e, this) 
          });

      $(document).on('keydown.fndtn.gsclearing',
        function (e) { 
          //$loader.fadeIn('slow');
            //console.log('loader in');
            self.keydown(e) });

      S(window).off('.gsclearing').on('resize.fndtn.gsclearing',
        function () { self.resize() });

      this.swipe_events(scope);
    },

    swipe_events : function (scope) {
      var self = this,
      S = self.S/*,
      $loader = $('.gsclearing-loading')*/;

      S(this.scope)
        .on('touchstart.fndtn.gsclearing', '.visible-img', function(e) {
          if (!e.touches) { e = e.originalEvent; }
          var data = {
                start_page_x: e.touches[0].pageX,
                start_page_y: e.touches[0].pageY,
                start_time: (new Date()).getTime(),
                delta_x: 0,
                is_scrolling: undefined
              };

          S(this).data('swipe-transition', data);
          e.stopPropagation();
        })
        .on('touchmove.fndtn.gsclearing', '.visible-img', function(e) {
          if (!e.touches) { e = e.originalEvent; }
          // Ignore pinch/zoom events
          if(e.touches.length > 1 || e.scale && e.scale !== 1) return;

          var data = S(this).data('swipe-transition');

          if (typeof data === 'undefined') {
            data = {};
          }

          data.delta_x = e.touches[0].pageX - data.start_page_x;

          if (Foundation.rtl) {
            data.delta_x = -data.delta_x;
          }

          if (typeof data.is_scrolling === 'undefined') {
            data.is_scrolling = !!( data.is_scrolling || Math.abs(data.delta_x) < Math.abs(e.touches[0].pageY - data.start_page_y) );
          }

          if (!data.is_scrolling && !data.active) {
            e.preventDefault();
            var direction = (data.delta_x < 0) ? 'next' : 'prev';
            data.active = true;
            //$loader.css('display','none');
            //$loader.fadeIn('slow');
            //console.log('loader in');
            self.nav(e, direction);
          }
        })
        .on('touchend.fndtn.gsclearing', '.visible-img', function(e) {
          S(this).data('swipe-transition', {});
          e.stopPropagation();
        });
    },

    assemble : function ($li) {
      var $el = $li.parent();

      if ($el.parent().hasClass('carousel')) {
        return;
      }
      
      $el.after('<div id="foundationClearingHolder"></div>');

      var grid = $el.detach(),
          grid_outerHTML = '';

      if (grid[0] == null) {
        return;
      } else {
        grid_outerHTML = grid[0].outerHTML;
      }
      
      var holder = this.S('#foundationClearingHolder'),
          settings = $el.data(this.attr_name(true) + '-init'),
          data = {
            grid: '<div class="carousel">' + grid_outerHTML + '</div>',
            viewing: settings.templates.viewing,
            loading_div: '<div class="gsclearing-loading"><img src="/images/719.GIF" alt=""></div>'
          },
          wrapper = '<div class="gsclearing-assembled"><div>' + data.viewing +
            data.grid + data.loading_div + '</div></div>',
          touch_label = this.settings.touch_label;

      if (Modernizr.touchevents) {
        wrapper = $(wrapper).find('.gsclearing-touch-label').html(touch_label).end();
      }

      holder.after(wrapper).remove();
    },

    open : function ($image, current, target, generaNotify) {
      var self = this,
          body = $(document.body),
          root = target.closest('.gsclearing-assembled'),
          container = self.S('div', root).first(),
          visible_image = self.S('.visible-img', container),
          image = self.S('img', visible_image).not($image),
          label = self.S('.gsclearing-touch-label', container),
          loader = self.S('.gsclearing-loading',container),
          error = false;  
      this.settings.closed = false;
      // Event to disable scrolling on touch devices when Clearing is activated
      $('body').on('touchmove',function(e){
        e.preventDefault();
      });

      image.error(function () {
        error = true;
      });
        
        if( generaNotify === true) {
            loader.stop( true, true ).fadeIn('slow');
        }

        function startLoad() {  
          if (!this.settings.closed) {  
              $image.css('visibility', 'visible');
              // toggle the gallery
              body.css('overflow', 'hidden');
              root.addClass('gsclearing-blackout');
              container.addClass('gsclearing-container');
              //visible_image.fadeIn('slow');//show()
              this.fix_height(target)
                .caption(self.S('.gsclearing-caption', visible_image), self.S('img', target))
                .center_and_label(image, label)
                .shift(current, target, function () {
                    target.closest('li').siblings().removeClass('visible');
                    if( generaNotify === true) {
                        target.closest('li').css('visibility','hidden');
                    }
                    else {
                        target.closest('li').addClass('visible');
                    }
              
                    if(target.closest('li').hasClass('last')) {
                        container.addClass('last');
                    }
                    else {
                        container.removeClass('last');
                    }
                });
              // console.log('end'); 
          }
            setTimeout(function () {
                this.image_loaded(image, function () {
                    if (image.outerWidth() === 1 && !error) {
                        startLoad.call(this);
                    } else {
                        if( generaNotify === true) { 
                            target.closest('li').css('visibility','visible');
                        }
                        cb.call(this, image);
                    }
                }.bind(this));
            }.bind(this), 200);
        }

      function cb (image) { 
          var $image = $(image);  
          loader.css('display', 'none'); 
          
          if (!this.settings.closed) {  
              $image.css('visibility', 'visible');
              // toggle the gallery
              body.css('overflow', 'hidden');
              root.addClass('gsclearing-blackout');
              container.addClass('gsclearing-container');
              visible_image.fadeIn('slow',function(){
                  //fix mio x non mostrare subito carosel ( deve prima caricarsi bene)
                  target.closest('.carousel ul').css('visibility', 'visible');
              });//show()
              
              
              this.fix_height(target)
                .caption(self.S('.gsclearing-caption', visible_image), self.S('img', target))
                .center_and_label(image, label)
                .shift(current, target, function () {
                    target.closest('li').siblings().removeClass('visible');
                    target.closest('li').addClass('visible');
                    if(target.closest('li').hasClass('last')) {
                        container.addClass('last');
                    }
                    else {
                        container.removeClass('last');
                    }
                });
              // console.log('end');
              if( generaNotify === true) {
                  visible_image.trigger('opened_gsclearing'); 
              }
              this.social(self.S('.gsclearing-social'), visible_image);
          }
          //else {console.warn('non posso');}
      }

      if (!this.locked()) {
          if( generaNotify === true) {
            visible_image.trigger('open_gsclearing');
          }
          //fix lazy : se non cè src guardo dataattr e aggiorno anche thumb
          if((!$image.attr('src') )) { 
                $image.attr('src', $image.parents('a.th').attr('href'));
                $image.addClass('animated');//x il lazy (cosy lo ignora) 
          }
          //fix copertina gioco
          else if(( $image.attr('src') && $image.hasClass('cover'))) { 
                $image.attr('src', $image.parents('a').attr('href')); 
          }
          // set the image to the selected thumbnail 
        image
          .attr('src', this.load($image))
          .css('visibility', 'hidden');
 
        startLoad.call(this);
      }
    },

    close : function (e, el) {
      e.preventDefault();

      var root = (function (target) {
            if (/blackout/.test(target.selector)) {
              return target;
            } else {
              return target.closest('.gsclearing-blackout');
            }
          }($(el))),
          body = $(document.body), container, visible_image , loader;

      if (el === e.target && root) {
        this.settings.closed = true;
          
        body.css('overflow', '');
        container = $('div', root).first();
        loader = $('.gsclearing-loading',container);
        visible_image = $('.visible-img', container);
        visible_image.trigger('close_gsclearing');
        this.settings.prev_index = 0;
        $('ul[' + this.attr_name() + ']', root)
          .attr('style', '').closest('.gsclearing-blackout')
          .removeClass('gsclearing-blackout');
        container.removeClass('gsclearing-container');
        visible_image.hide();
        loader.css('display', 'none'); 
            //  console.log('loader out');
        visible_image.trigger('closed_gsclearing');        
      }

      // Event to re-enable scrolling on touch devices
      $('body').off('touchmove');

      return false;
    },

    is_open : function (current) {
      return current.parent().prop('style').length > 0;
    },

    keydown : function (e) {
      var gsclearing = $('.gsclearing-blackout ul[' + this.attr_name() + ']'),
          NEXT_KEY = this.rtl ? 37 : 39,
          PREV_KEY = this.rtl ? 39 : 37,
          ESC_KEY = 27;

      if (e.which === NEXT_KEY) {
          this.go(gsclearing, 'next');
      }
      if (e.which === PREV_KEY) {
          this.go(gsclearing, 'prev');
      }
      if (e.which === ESC_KEY) this.S('a.gsclearing-close').trigger('click').trigger('click.fndtn.gsclearing');
    },

    nav : function (e, direction) {
      var gsclearing = $('ul[' + this.attr_name() + ']', '.gsclearing-blackout');

      e.preventDefault();
      this.go(gsclearing, direction);
    },

    resize : function () {
      var image = $('img', '.gsclearing-blackout .visible-img'),
          label = $('.gsclearing-touch-label', '.gsclearing-blackout');

      if (image.length) {
          var self = this;
        this.center_and_label(image, label);
        this.social(self.S('.gsclearing-social'),image);
        image.trigger('resized.fndtn.gsclearing')
      }
    },

    // visual adjustments
    fix_height : function (target) {
      var lis = target.parent().children(),
          self = this;

      lis.each(function () {
        var li = self.S(this),
            image = li.find('img');

        if (li.height() > image.outerHeight()) {
          li.addClass('fix-height');
        }
      })
      .closest('ul')
      .width(lis.length * 100 + '%');

      return this;
    },

    update_paddles : function (target) {
      target = target.closest('li');
      var visible_image = target
        .closest('.carousel')
        .siblings('.visible-img');

      if (target.next().length > 0) {
        this.S('.gsclearing-main-next', visible_image).removeClass('disabled');
      } else {
        this.S('.gsclearing-main-next', visible_image).addClass('disabled');
      }

      if (target.prev().length > 0) {
        this.S('.gsclearing-main-prev', visible_image).removeClass('disabled');
      } else {
        this.S('.gsclearing-main-prev', visible_image).addClass('disabled');
      }
    },

    center_and_label : function (target, label) {
      if (!this.rtl) {
        target.css({
          marginLeft : -(target.outerWidth() / 2),
          marginTop : -(target.outerHeight() / 2)
        });

        if (label.length > 0) {
          label.css({
            marginLeft : -(label.outerWidth() / 2),
            marginTop : -(target.outerHeight() / 2)-label.outerHeight()-10
          });
        }
      } else {
        target.css({
          marginRight : -(target.outerWidth() / 2),
          marginTop : -(target.outerHeight() / 2),
          left: 'auto',
          right: '50%'
        });

        if (label.length > 0) {
          label.css({
            marginRight : -(label.outerWidth() / 2),
            marginTop : -(target.outerHeight() / 2)-label.outerHeight()-10,
            left: 'auto',
            right: '50%'
          });
        }
      }
      return this;
    },

    // image loading and preloading

    load : function ($image) {
      var href;

      if ($image[0].nodeName === "A") {
        href = $image.attr('href');
      }
      else if ($image.parent().nodeName === "A") {
        var href = $image.parent().attr('href');
      }
      else {
        var href = $image.parent().parent().attr('href');
      }
        
        

      this.preload($image);

      if (href) return href;
      return $image.attr('src');
    },

    preload : function ($image) {
      this
        .img($image.closest('li').next())
        .img($image.closest('li').prev());
    },

    img : function (img) {
      if (img.length) {
        var new_img = new Image(),
            new_a = this.S('a', img);

        if (new_a.length) {
          new_img.src = new_a.attr('href');
        } else {
          new_img.src = this.S('img', img).attr('src');
        }
      }
      return this;
    },

    // social share box

    social : function (container ,image) {
        container.removeAttr('style');
        if(!container.parent().siblings('.carousel').length || container.parent().siblings('.carousel').children('ul').data('share-url') === undefined  ) {
            container.hide();
        }
        else { 
            if (matchMedia(Foundation.media_queries['medium']).matches !== true) {
                container.css({
                    width : '100%',
                    height:'100%'
                });
                container.find('span').css({ 
                    right: '10px',
                    top: 10 - ( container.parent('.visible-img').offset().top - $(window).scrollTop() ) +'px'
                });
            }
            else {
                container.css({
                    width : container.siblings('img').width() + 'px',
                    height: container.siblings('img').height() + 'px'
                });
                container.find('span').css({ 
                    right: '0px',
                    top: ( container.siblings('img').height() - 32 ) + 'px'
                });
            }
            container.fadeIn();
            //fix link in caption
            container.find('span').off('click').on('click',function(e){
                e.preventDefault(); 
                e.stopPropagation(); 
                
                if($(this).children('i').hasClass('fa-google-plus')) {
                    /*var btnId ='shareImage' + parseInt(Math.random() * 100000);
                    $(this).prepend('<div id="'+btnId+'"></div>');
                    //$(this).attr('id' , btnId);
                    setTimeout(function(){
                    var options = {
                         contenturl: container.parent().siblings('.carousel').children('ul').data('share-url'),
                         clientid: clientid_,
                         cookiepolicy: 'single_host_origin',
                         prefilltext: 'Immagini del gioco '+container.parent().siblings('.carousel').children('ul').data('share-name')+' per '+container.parent().siblings('.carousel').children('ul').data('share-piattaforma'), 
                         calltoactionlabel: 'VIEW',
                         calltoactionurl: container.parent().siblings('.carousel').children('ul').data('share-url'),
                         callback: function(result) {
                             setTimeout(function(){
                                 $('#'+btnId).trigger("click");
                             },2500);
                         }
                     };
                    // Call the render method when appropriate within your app to display
                    // the button.
                    gapi.interactivepost.render(btnId, options); },25);*/
                }
                else {
                    FB.ui({
                        method: 'feed',
                        link: container.parent().siblings('.carousel').children('ul').data('share-url'),
                        description: 'Immagini del gioco '+container.parent().siblings('.carousel').children('ul').data('share-name')+' per '+container.parent().siblings('.carousel').children('ul').data('share-piattaforma'),
                        caption: container.parent().siblings('.carousel').children('ul').data('share-name'),
                        picture: container.siblings('img').attr('src'),
                    }, function(response){}); 
                }
            });   
        }
        return this;
    }, 

    // image caption - fix html link href

    caption : function (container, $image) {
      var caption = $image.attr('data-caption');

      if (caption) {
        container
          .html(caption)
          .show();
          //fix link in caption
          container.find('a').off('click').on('click',function(e){
                e.preventDefault(); 
                if($(this).attr('target') && $(this).attr('target') == "_blank") {
                    window.open($(this).attr('href'),'_blank');
                }
                else {
                    window.location = $(this).attr('href'); 
                }
          });
          
      } else {
        container
          .text('')
          .hide();
      }
      return this;
    }, 

    // directional methods

    go : function ($ul, direction) {
      var current = this.S('.visible', $ul),
          target = current[direction](), 
          root = target.closest('.gsclearing-assembled'),
          container = this.S('div', root).first(),
          $loader = this.S('.gsclearing-loading',container); 
      // Check for skip selector.
      if (this.settings.skip_selector && target.find(this.settings.skip_selector).length != 0) {
        target = target[direction]();
      }
        //se nn trova img vuol dire che devo andare in loop
        if (!target.length) {
            if (direction == 'next') {
                target = $ul.find('> li:first-of-type');
            }
            else {
                target = $ul.find('> li:last-of-type');
            }
        }
      if (target.length) {
        
        //$loader.css('display','none');
        this.S('.gsclearing-social').hide();
        $loader.stop( true, true ).fadeIn('slow');
        console.log('loader in');
        this.S('img', target)
          .trigger('click', [current, target]).trigger('click.fndtn.gsclearing', [current, target])
          .trigger('change.fndtn.gsclearing');
      }
    },

    shift : function (current, target, callback) {
      var gsclearing = target.parent(),
          old_index = this.settings.prev_index || target.index(),
          direction = this.direction(gsclearing, current, target),
          dir = this.rtl ? 'right' : 'left',
          left = parseInt(gsclearing.css('left'), 10),
          width = target.outerWidth(),
          skip_shift;

      var dir_obj = {};

      // we use jQuery animate instead of CSS transitions because we
      // need a callback to unlock the next animation
      // needs support for RTL **
      if (target.index() !== old_index && !/skip/.test(direction)){
        if (/left/.test(direction)) {
          this.lock();
          dir_obj[dir] = left + width;
          gsclearing.animate(dir_obj, 300, this.unlock());
        } else if (/right/.test(direction)) {
          this.lock();
          dir_obj[dir] = left - width;
          gsclearing.animate(dir_obj, 300, this.unlock());
        }
      } 
      else if (/skip/.test(direction)) {
        // the target image is not adjacent to the current image, so
        // do we scroll right or not
        skip_shift = target.index() - this.settings.up_count;
        this.lock();

        if (skip_shift > 0) {
          dir_obj[dir] = -(skip_shift * width);
          gsclearing.animate(dir_obj, 300, this.unlock());
        } else {
          dir_obj[dir] = 0;
          gsclearing.animate(dir_obj, 300, this.unlock());
        }
      }

      callback();
    },

    direction : function ($el, current, target) {
      var lis = this.S('li', $el),
          li_width = lis.outerWidth() + (lis.outerWidth() / 4),
          up_count = Math.floor(this.S('.gsclearing-container').outerWidth() / li_width) - 1,
          target_index = lis.index(target),
          response;

      this.settings.up_count = up_count;

      if (this.adjacent(this.settings.prev_index, target_index)) {
        if ((target_index > up_count) && target_index > this.settings.prev_index) {
          response = 'right';
        } else if ((target_index > up_count - 1) && target_index <= this.settings.prev_index) {
          response = 'left';
        } else {
          response = false;
        }
      } else {
        response = 'skip';
      }

      this.settings.prev_index = target_index;

      return response;
    },

    adjacent : function (current_index, target_index) {
      for (var i = target_index + 1; i >= target_index - 1; i--) {
        if (i === current_index) return true;
      }
      return false;
    },

    // lock management

    lock : function () {
      this.settings.locked = true;
    },

    unlock : function () {
      this.settings.locked = false;
    },

    locked : function () {
      return this.settings.locked;
    },

    off : function () {
      this.S(this.scope).off('.fndtn.gsclearing');
      this.S(window).off('.fndtn.gsclearing');
    },

    reflow : function () {
      this.init();
    }
  };

}(jQuery, window, window.document));
