;(function ($, window, document, undefined) {
  'use strict';

  Foundation.libs.gstopbar = {
    name : 'gstopbar',

    version: '5.2.2',

    settings : {
      index : 0,
      sticky_class : 'sticky',
      custom_back_text: true,
      back_text: 'Back',
      is_hover: true,
      mobile_show_parent_link: false,
      scrolltop : false, // jump to top when sticky nav menu toggle is clicked
      sticky_on : 'all'
    },

    init : function (section, method, options) {
      Foundation.inherit(this, 'add_custom_rule register_media throttle');
      var self = this;

      self.register_media('gstopbar', 'foundation-mq-gstopbar');

      this.bindings(method, options);

      self.S('[' + this.attr_name() + ']', this.scope).each(function () {
        var topbar = $(this),
            settings = topbar.data(self.attr_name(true) + '-init'),
            section = self.S('section', this),
            titlebar = topbar.children().filter('ul').first();
        topbar.data('index', 0);
        var topbarContainer = topbar.parent();
        if(topbarContainer.hasClass('fixed') || self.is_sticky(topbar, topbarContainer, settings) ) {
          self.settings.sticky_class = settings.sticky_class;
          self.settings.sticky_topbar = topbar;
          topbar.data('height', topbarContainer.outerHeight());
          topbar.data('stickyoffset', topbarContainer.offset().top);
        } else {
          topbar.data('height', topbar.outerHeight());
        }

        if (!settings.assembled) self.assemble(topbar);

        if (settings.is_hover) {
          self.S('.has-dropdown', topbar).addClass('not-click');
        } else {
          self.S('.has-dropdown', topbar).removeClass('not-click');
        }

        // Pad body when sticky (scrolled) or fixed.
        self.add_custom_rule('.f-gstopbar-fixed { padding-top: ' + topbar.data('height') + 'px }');

        if (topbarContainer.hasClass('fixed')) {
          self.S('body').addClass('f-gstopbar-fixed');
        }
      });

    },

    is_sticky: function (topbar, topbarContainer, settings) {
      var sticky = topbarContainer.hasClass(settings.sticky_class);

      if (sticky && settings.sticky_on === 'all') {
        return true;
      } else if (sticky && this.small() && settings.sticky_on === 'small') {
        return true;
      } else if (sticky && this.medium() && settings.sticky_on === 'medium') {
        return true;
      } else if (sticky && this.large() && settings.sticky_on === 'large') {
        return true;
      }

      return false;
    },

    toggle: function (toggleEl , gsType) {
      var self = this;

      if (toggleEl) {
        var topbar = self.S(toggleEl).closest('[' + this.attr_name() + ']');
          //arrivo da topbar
          //if (self.breakpoint()  && self.medium()) {
            //medium up --- ho visualizazine standard e onsticly
          //}
          //else {
            //small -- solo sticky
            //click su lente
            if(gsType && gsType == 'search') {
              $('.gs-top-bar-section-banner').hide();
              $('.gs-top-bar-section-main').hide();
              $('.gs-top-bar-section-sub').hide();
              $('.gs-top-bar-section-login').hide();
              if(self.medium()) {
                  if ( $('.gs-top-bar-section-search').is(':visible') ){
                    
                      $('.gs-top-bar-section-search').hide();
                  }
                  else {
                      $('.gs-top-bar-section-search').show();
                  }
              }
              else { $('.gs-top-bar-section-search').show(); }
            }
            //click su omino
            else if(gsType && gsType == 'login') {
              $('.gs-top-bar-section-banner').hide();
              $('.gs-top-bar-section-main').hide();
              $('.gs-top-bar-section-sub').hide();
              $('.gs-top-bar-section-search').hide();
              if(self.medium()) {
                  if ( $('.gs-top-bar-section-login').is(':visible') ){
                    
                      $('.gs-top-bar-section-login').hide();
                  }
                  else {
                      $('.gs-top-bar-section-login').show();
                  }
              }
              else { $('.gs-top-bar-section-login').show(); }
            }
            //click su icon menu
            else { 
              $('.gs-top-bar-section-banner').hide();
              $('.gs-top-bar-section-login').hide();
              $('.gs-top-bar-section-search').hide();
              if(self.medium()) {
                  if ( $('.gs-top-bar-section-main').is(':visible') ){
                    
                      $('.gs-top-bar-section-main').hide();
                      $('.gs-top-bar-section-sub').hide();
                  }
                  else {
                      $('.gs-top-bar-section-main').show();
                      $('.gs-top-bar-section-sub').show();
                  }
              }
              else {
                  $('.gs-top-bar-section-main').show();
                  $('.gs-top-bar-section-sub').show();
              }
            }
            
         // }   
      } else {
        var topbar = self.S('[' + this.attr_name() + ']');
          //arrivo da elemento dentro topbar (link)
      }

      var settings = topbar.data(this.attr_name(true) + '-init');
      //var section = self.S('section, .section', topbar);
      if(gsType && gsType == 'login') {
          var section = self.S('.gs-top-bar-section-login', topbar);
      }
      else if(gsType && gsType == 'search') {
          var section = self.S('.gs-top-bar-section-search', topbar);
      }
      else {
          var section = self.S('.gs-top-bar-section-main , .gs-top-bar-section-sub', topbar);
      }
      if(self.medium()) {
          if (!self.rtl) {
          section.css({left: '0%'});
          $('>.name', section).css({left: '100%'});
        } else {
          section.css({right: '0%'});
          $('>.name', section).css({right: '100%'});
        }

        self.S('li.moved', section).removeClass('moved');
        topbar.data('index', 0);
          if(gsType && gsType == 'login') {
            topbar
              .removeClass('expanded')
              .removeClass('expanded-search')
              .toggleClass('expanded-login')
              .css('height', '');
            self.S('body').removeClass('gs-topbar-search-open');
          }
          else if(gsType && gsType == 'search') {
            topbar
              .removeClass('expanded')
              .removeClass('expanded-login')
              .toggleClass('expanded-search')
              .css('height', '');
          }
          else {
            topbar
              .removeClass('expanded-login')
              .removeClass('expanded-search')
              .toggleClass('expanded')
              .css('height', '');
            self.S('body').removeClass('gs-topbar-search-open');
          }
      }
      else if (self.breakpoint() ) { //medium senza check on sticky è come non fare il controllo
          //qui tgestisco sections 
        if (!self.rtl) {
          section.css({left: '0%'});
          $('>.name', section).css({left: '100%'});
        } else {
          section.css({right: '0%'});
          $('>.name', section).css({right: '100%'});
        }

        self.S('li.moved', section).removeClass('moved');
        topbar.data('index', 0);
          if(gsType && gsType == 'login') {
            topbar
              .removeClass('expanded')
              .removeClass('expanded-search')
              .toggleClass('expanded-login')
              .css('height', '');
            self.S('body').removeClass('gs-topbar-search-open');
          }
          else if(gsType && gsType == 'search') {
            topbar
              .removeClass('expanded')
              .removeClass('expanded-login')
              .toggleClass('expanded-search')
              .css('height', '');
          }
          else {
            topbar
              .removeClass('expanded-login')
              .removeClass('expanded-search')
              .toggleClass('expanded')
              .css('height', '');
            self.S('body').removeClass('gs-topbar-search-open');
          }
      }

      if (settings.scrolltop) {
        if (!topbar.hasClass('expanded') && !topbar.hasClass('expanded-login') && !topbar.hasClass('expanded-search')) {
          if (topbar.hasClass('fixed')) {
            topbar.parent().addClass('fixed');
            topbar.removeClass('fixed');
            self.S('body').addClass('f-gstopbar-fixed');
          }
        } else if (topbar.parent().hasClass('fixed')) {
          if (settings.scrolltop) {
            topbar.parent().removeClass('fixed');
            topbar.addClass('fixed');
            self.S('body').removeClass('f-gstopbar-fixed');

            window.scrollTo(0,0);
          } else {
              //if(gsType && gsType == 'login') {
                topbar.parent().removeClass('expanded-login');
              //}
              //else if(gsType && gsType == 'search') {
                topbar.parent().removeClass('expanded-search');
            self.S('body').removeClass('gs-topbar-search-open');
             // }
            //  else {
                topbar.parent().removeClass('expanded');
            //  }
          }
        }
      } else {
        if(self.is_sticky(topbar, topbar.parent(), settings)) {
          topbar.parent().addClass('fixed');
        }

        if(topbar.parent().hasClass('fixed')) {
          if (!topbar.hasClass('expanded') && !topbar.hasClass('expanded-login')&& !topbar.hasClass('expanded-search')) {
            topbar.removeClass('fixed');
            topbar.parent().removeClass('expanded');
            topbar.parent().removeClass('expanded-search');
            topbar.parent().removeClass('expanded-login');
            self.S('body').removeClass('gs-topbar-search-open');
            self.update_sticky_positioning();
          } else {
            topbar.addClass('fixed');
            if(gsType && gsType == 'login') {
                topbar.parent().addClass('expanded-login');
            }
            else if(gsType && gsType == 'search') {
                topbar.parent().addClass('expanded-search');
                self.S('body').addClass('gs-topbar-search-open');
            }
            else {
                topbar.parent().addClass('expanded');
            }
            self.S('body').addClass('f-gstopbar-fixed');
          }
        }
      }
    },

    timer : null,

    events : function (bar) {
      var self = this,
          S = this.S;

      S(this.scope)
        .off('.gstopbar')
        .on('click.fndtn.gstopbar', '[' + this.attr_name() + '] .toggle-gstopbar', function (e) {
          e.preventDefault();
          self.toggle(this);
        })
        .on('click.fndtn.gstopbar', '[' + this.attr_name() + '] .toggle-gstopbar-search', function (e) {
          e.preventDefault();
          self.toggle(this , 'search');
        })
        .on('click.fndtn.gstopbar', '[' + this.attr_name() + '] .toggle-gstopbar-login', function (e) {
          e.preventDefault();
          self.toggle(this , 'login');
        })
        /*.on('click.fndtn.gstopbar','.gs-top-bar .gs-top-bar-section li a[href^="#"],[' + this.attr_name() + '] .gs-top-bar-section li a[href^="#"]',function (e) {
            var li = $(this).closest('li');
            if(self.breakpoint() && !li.hasClass('back') && !li.hasClass('has-dropdown'))
            {
            self.toggle();
            }
        })*/
        .on('click.fndtn.gstopbar', '[' + this.attr_name() + '] li.has-dropdown', function (e) {
          var li = S(this),
              target = S(e.target),
              topbar = li.closest('[' + self.attr_name() + ']'),
              settings = topbar.data(self.attr_name(true) + '-init');

          if(target.data('revealId')) {
            self.toggle();
            return;
          }

          if (self.breakpoint()) return;
          if (settings.is_hover && !Modernizr.touchevents) return;

          e.stopImmediatePropagation();

          if (li.hasClass('hover')) {
            li
              .removeClass('hover')
              .find('li')
              .removeClass('hover');

            li.parents('li.hover')
              .removeClass('hover');
          } else {
            li.addClass('hover');

            $(li).siblings().removeClass('hover');

            if (target[0].nodeName === 'A' && target.parent().hasClass('has-dropdown')) {
              e.preventDefault();
            }
          }
        })
        .on('click.fndtn.gstopbar', '[' + this.attr_name() + '] .has-dropdown>a', function (e) {
          if (self.breakpoint()) {

            e.preventDefault();

            var $this = S(this),
                topbar = $this.closest('[' + self.attr_name() + ']'),
                section = topbar.find('section, .section'),
                dropdownHeight = $this.next('.dropdown').outerHeight(),
                $selectedLi = $this.closest('li');

            topbar.data('index', topbar.data('index') + 1);
            $selectedLi.addClass('moved');

            if (!self.rtl) {
              section.css({left: -(100 * topbar.data('index')) + '%'});
              section.find('>.name').css({left: 100 * topbar.data('index') + '%'});
            } else {
              section.css({right: -(100 * topbar.data('index')) + '%'});
              section.find('>.name').css({right: 100 * topbar.data('index') + '%'});
            }

            topbar.css('height', $this.siblings('ul').outerHeight(true) + topbar.data('height'));
          }
        });
      
      S(window).off('.gstopbar').on('resize.fndtn.gstopbar', self.throttle(function () {
        self.resize.call(self);
      }, 50)).trigger('resize');

      S('body').off('.gstopbar').on('click.fndtn.gstopbar touchstart.fndtn.gstopbar', function (e) {
        //chiudo se clicco fuori area topbar aperta cioè fixed
        if($('.gs-top-bar').hasClass('fixed') && $('.gs-top-bar').has(e.target).length === 0) {
            //chiudo caso login
            if($('.gs-top-bar').hasClass('expanded-login')) {
                self.toggle('li.toggle-gstopbar-login' , 'login');
                return;
            }
            //chiudo caso search
            else if($('.gs-top-bar').hasClass('expanded-search')) {
                self.toggle('li.toggle-gstopbar-search' , 'search');
                return;
            }
            //chiudo caso menu
            else if($('.gs-top-bar').hasClass('expanded')) {
                self.toggle('li.toggle-gstopbar');
                return;
            }
        }
          
        var parent = S(e.target).closest('li').closest('li.hover');

        if (parent.length > 0) {
          return;
        }

        S('[' + self.attr_name() + '] li.hover').removeClass('hover');
      });

      // Go up a level on Click
      S(this.scope).on('click.fndtn.gstopbar', '[' + this.attr_name() + '] .has-dropdown .back', function (e) {
        e.preventDefault();

        var $this = S(this),
            topbar = $this.closest('[' + self.attr_name() + ']'),
            section = topbar.find('section, .section'),
            settings = topbar.data(self.attr_name(true) + '-init'),
            $movedLi = $this.closest('li.moved'),
            $previousLevelUl = $movedLi.parent();

        topbar.data('index', topbar.data('index') - 1);

        if (!self.rtl) {
          section.css({left: -(100 * topbar.data('index')) + '%'});
          section.find('>.name').css({left: 100 * topbar.data('index') + '%'});
        } else {
          section.css({right: -(100 * topbar.data('index')) + '%'});
          section.find('>.name').css({right: 100 * topbar.data('index') + '%'});
        }

        if (topbar.data('index') === 0) {
          topbar.css('height', '');
        } else {
          topbar.css('height', $previousLevelUl.outerHeight(true) + topbar.data('height'));
        }

        setTimeout(function () {
          $movedLi.removeClass('moved');
        }, 300);
      });
    },

    resize : function () {
      var self = this;
      self.S('[' + this.attr_name() + ']').each(function () {
        var topbar = self.S(this),
            settings = topbar.data(self.attr_name(true) + '-init');

        var stickyContainer = topbar.parent('.' + self.settings.sticky_class);
        var stickyOffset;

        //se topbar e' medium up mostro voci login ecc... altrimenti no ( ci sono icone )
        if (/*!self.breakpoint()  && */ self.medium()) {
         //sono medium up 
            //$('.gs-top-bar-section').show(); guardo sticky
            //$('.toggle-gstopbar').show(); guardo sticky
            //$('.toggle-gstopbar-search').show(); guardo sticky
            //$('.toggle-gstopbar-login').show(); guardo sticky
            //$('.gs-top-bar-section-main').show(); guardo sticky
            //$('.gs-top-bar-section-sub').show(); guardo sticky
            var doToggle = topbar.hasClass('expanded');
            var doToggleLogin = topbar.hasClass('expanded-login');
            var doToggleSearch = topbar.hasClass('expanded-search');
            topbar
            .removeClass('expanded')
            .removeClass('expanded-login')
            .removeClass('expanded-search')
            .find('li')
            .removeClass('hover'); 
            self.S('body').removeClass('gs-topbar-search-open');
            //toggle --> se sono in sticky
        }
        else if (self.breakpoint()) {
          //setto margine topbar per prevenire il "vuoto" di 135px
          $('.gs-top-bar-row').css({ 'margin-bottom': '0px' });
          //small
          $('.gs-top-bar-section').hide();
          $('.gs-top-bar-section-main').hide();
          $('.gs-top-bar-section-sub').hide();
          $('.gs-top-bar-section-login').hide();
          $('.gs-top-bar-section-search').hide();
          $('.toggle-gstopbar').show();
          $('.toggle-gstopbar-search').show();
          $('.toggle-gstopbar-login').show(); 
          var doToggle = topbar.hasClass('expanded');
          var doToggleLogin = topbar.hasClass('expanded-login');
          var doToggleSearch = topbar.hasClass('expanded-search');
          topbar
            .css('height', '')
            .removeClass('expanded')
            .removeClass('expanded-login')
            .removeClass('expanded-search')
            .find('li')
            .removeClass('hover');
            self.S('body').removeClass('gs-topbar-search-open');

            if(doToggle) {
              self.toggle(topbar);
            }
            if(doToggleLogin) {
              self.toggle(topbar,'login');
            }
            if(doToggleSearch) {
              self.toggle(topbar,'search');
            }
        }

        if(self.is_sticky(topbar, stickyContainer, settings)) {
          if(stickyContainer.hasClass('fixed')) {
            // Remove the fixed to allow for correct calculation of the offset.
            stickyContainer.removeClass('fixed');
             

            stickyOffset = stickyContainer.offset().top;
            if(self.S(document.body).hasClass('f-gstopbar-fixed')) {
              stickyOffset -= topbar.data('height');
            }
            topbar.data('stickyoffset', stickyOffset);
            stickyContainer.addClass('fixed');
            if (/*!self.breakpoint() &&  */self.medium()) { 
                
                //setto margine topbar per prevenire il "taglio" di 135px
                $('.gs-top-bar-row').css({ 'margin-bottom': '135px' });
                //medium on sticky scroll
                self.S('.gs-top-bar').addClass('on-sticky');
                self.S('.gs-top-bar-section-banner').addClass('on-sticky');
                self.S('.gs-top-bar-section').addClass('on-sticky');
                self.S('.gs-top-bar-section-sub').addClass('on-sticky');
                self.S('.gs-top-bar-section-main').addClass('on-sticky');
                self.S('.gs-top-bar-section-login').addClass('on-sticky');
                self.S('.gs-top-bar-section-search').addClass('on-sticky');
                $('.gs-top-bar-section').hide(); //guardo sticky
                $('.toggle-gstopbar').show(); //guardo sticky
                $('.toggle-gstopbar-search').show();// guardo sticky
                $('.toggle-gstopbar-login').show(); //guardo sticky
                $('.gs-top-bar-section-main').hide(); //guardo sticky
                $('.gs-top-bar-section-sub').hide(); //guardo sticky
                $('.gs-top-bar-section-banner').hide();
                $('.gs-top-bar-section-login').hide();
                $('.gs-top-bar-section-search').hide();
                
                  topbar
                    .css('height', '');
                if(doToggle) {
                  self.toggle(topbar);
                }
                if(doToggleLogin) {
                  self.toggle(topbar,'login');
                }
                if(doToggleSearch) {
                  self.toggle(topbar,'search');
                }
            }
            else if (self.breakpoint()) {
                self.S('.gs-top-bar').removeClass('on-sticky');
                self.S('.gs-top-bar-section').removeClass('on-sticky');
                self.S('.gs-top-bar-section-sub').removeClass('on-sticky');
                self.S('.gs-top-bar-section-banner').removeClass('on-sticky');
                self.S('.gs-top-bar-section-main').removeClass('on-sticky');
                self.S('.gs-top-bar-section-login').removeClass('on-sticky');
                self.S('.gs-top-bar-section-search').removeClass('on-sticky'); }

          } else {
            stickyOffset = stickyContainer.offset().top;
            topbar.data('stickyoffset', stickyOffset);
                self.S('.gs-top-bar').removeClass('on-sticky');
                self.S('.gs-top-bar-section').removeClass('on-sticky');
                self.S('.gs-top-bar-section-sub').removeClass('on-sticky');
                self.S('.gs-top-bar-section-main').removeClass('on-sticky');
                self.S('.gs-top-bar-section-banner').removeClass('on-sticky');
                self.S('.gs-top-bar-section-login').removeClass('on-sticky');
                self.S('.gs-top-bar-section-search').removeClass('on-sticky');
            if ( self.medium()) { 
                //medium sticky top
                
                $('.gs-top-bar-section').show(); //guardo sticky
                $('.toggle-gstopbar').hide(); //guardo sticky
                $('.toggle-gstopbar-search').hide();// guardo sticky
                $('.toggle-gstopbar-login').hide(); //guardo sticky
                $('.gs-top-bar-section-main').show(); //guardo sticky
                $('.gs-top-bar-section-sub').show(); //guardo sticky
                $('.gs-top-bar-section-banner').show();
                $('.gs-top-bar-section-login').hide();
                $('.gs-top-bar-section-search').hide();
                //aggiusto larghezza drowdown presente in gs-top-bar-section usato x login e box utente e box ricerca
                var widthDropdown = $('.gs-top-bar-section > ul').width(); 
                //var widthDropdownTemp = widthDropdown;
                $('.gs-top-bar-section > ul > li > ul.dropdown').css('min-width',widthDropdown); 
                /*var dropdownSections=$('.gs-top-bar-section > ul > li > ul.dropdown');
                $.each(dropdownSections,function(){
                    widthDropdownTemp = widthDropdown  ;
                    widthDropdown = widthDropdown - $(this).parent().width() ;
                    $(this).css('min-width',widthDropdownTemp+'px'); 
                });*/
                 /* topbar
                    .removeClass('expanded')
                    .removeClass('expanded-login')
                    .removeClass('expanded-search')
                    .find('li')
                    .removeClass('hover');*/
                //TODO toggle search e login da capire...main nn cè 
                /*if(doToggleLogin) {
                  self.toggle(topbar,'login');
                }
                if(doToggleSearch) {
                  self.toggle(topbar,'search');
                }*/
            }
          }
        }

      });
    },

    breakpoint : function () {
      return !matchMedia(Foundation.media_queries['gstopbar']).matches;
    },

    small : function () {
      return matchMedia(Foundation.media_queries['small']).matches;
    },

    medium : function () {
      return matchMedia(Foundation.media_queries['medium']).matches;
    },

    large : function () {
      return matchMedia(Foundation.media_queries['large']).matches;
    },

    assemble : function (topbar) {
      var self = this,
          settings = topbar.data(this.attr_name(true) + '-init'),
          section = self.S('section', topbar),
          titlebar = $(this).children().filter('ul').first();

      // Pull element out of the DOM for manipulation
      section.detach();

      self.S('.has-dropdown>a', section).each(function () {
        var $link = self.S(this),
            $dropdown = $link.siblings('.dropdown'),
            url = $link.attr('href');

        if (!$dropdown.find('.title.back').length) {
          if (settings.mobile_show_parent_link && url && url.length > 1) {
            var $titleLi = $('<li class="title back js-generated"><h5><a href="javascript:void(0)"></a></h5></li><li><a class="parent-link js-generated" href="' + url + '">' + $link.text() +'</a></li>');
          } else {
            var $titleLi = $('<li class="title back js-generated"><h5><a href="javascript:void(0)"></a></h5></li>');
          }
  
          // Copy link to subnav
          if (settings.custom_back_text == true) {
            $('h5>a', $titleLi).html(settings.back_text);
          } else {
            $('h5>a', $titleLi).html('&laquo; ' + $link.html());
          }
          $dropdown.prepend($titleLi);
        }
      });

      // Put element back in the DOM
      section.appendTo(topbar);

      // check for sticky
      this.sticky();

      this.assembled(topbar);
    },

    assembled : function (topbar) {
      topbar.data(this.attr_name(true), $.extend({}, topbar.data(this.attr_name(true)), {assembled: true}));
    },

    height : function (ul) {
      var total = 0,
          self = this;

      $('> li', ul).each(function () { total += self.S(this).outerHeight(true); });

      return total;
    },

    sticky : function () {
      var $window = this.S(window),
          self = this;

      this.S(window).on('scroll', function() {
        self.update_sticky_positioning();
      });
    },

    update_sticky_positioning: function() {
      var klass = '.' + this.settings.sticky_class,
          $window = this.S(window), 
          self = this;

      if (self.settings.sticky_topbar && self.is_sticky(this.settings.sticky_topbar,this.settings.sticky_topbar.parent(), this.settings)) {
        var distance = this.settings.sticky_topbar.data('stickyoffset');
        if (!self.S(klass).hasClass('expanded') && !self.S(klass).hasClass('expanded-login') && !self.S(klass).hasClass('expanded-search')) {
          if ((self.medium() && $window.scrollTop() > (distance +135)) || (!self.medium() && $window.scrollTop() > distance)) {
            if (!self.S(klass).hasClass('fixed')) {
              self.S(klass).addClass('fixed');
              self.S('body').addClass('f-gstopbar-fixed');
                
                if(self.medium()) { 
                    self.S('.gs-top-bar').addClass('on-sticky');
                    self.S('.gs-top-bar-section').addClass('on-sticky');
                    self.S('.gs-top-bar-section-sub').addClass('on-sticky');
                    self.S('.gs-top-bar-section-main').addClass('on-sticky');
                    self.S('.gs-top-bar-section-banner').addClass('on-sticky');
                    self.S('.gs-top-bar-section-login').addClass('on-sticky');
                    self.S('.gs-top-bar-section-search').addClass('on-sticky');
                    $('.gs-top-bar-section').hide();
                    $('.gs-top-bar-section-main').hide();
                    $('.gs-top-bar-section-sub').hide();
                    $('.gs-top-bar-section-banner').hide();
                    $('.toggle-gstopbar').show();
                    $('.toggle-gstopbar-search').show();
                    $('.toggle-gstopbar-login').show(); 
                    //sposto next elemnt giu di 135 x mostrarlo bene
                    $('.gs-top-bar-row').css({ 'margin-bottom': '135px' });
                }
                else {
                    //todo: small onsticky (toggle ok .. da capire section )
                    self.S('.gs-top-bar').removeClass('on-sticky');
                    self.S('.gs-top-bar-section').removeClass('on-sticky');
                    self.S('.gs-top-bar-section-sub').removeClass('on-sticky');
                    self.S('.gs-top-bar-section-main').removeClass('on-sticky');
                    self.S('.gs-top-bar-section-banner').removeClass('on-sticky');
                    self.S('.gs-top-bar-section-login').removeClass('on-sticky');
                    self.S('.gs-top-bar-section-search').removeClass('on-sticky');
                    $('.toggle-gstopbar').show();
                    $('.toggle-gstopbar-search').show();
                    $('.toggle-gstopbar-login').show();  
                    //sposto next elemnt giu di 0 x mostrarlo bene
                    $('.gs-top-bar-row').css({ 'margin-bottom': '0px' });
                }
            }
          } else if (
              (self.medium() && $window.scrollTop() <= (distance +135)) || (!self.medium() && $window.scrollTop() <= distance)
            ) {
            if (self.S(klass).hasClass('fixed')) {
              self.S(klass).removeClass('fixed');
              self.S('body').removeClass('f-gstopbar-fixed');
                //levo css versione scroll
                self.S('.gs-top-bar').removeClass('on-sticky');
                self.S('.gs-top-bar-section').removeClass('on-sticky');
                self.S('.gs-top-bar-section-sub').removeClass('on-sticky');
                self.S('.gs-top-bar-section-main').removeClass('on-sticky');
                self.S('.gs-top-bar-section-banner').removeClass('on-sticky');
                self.S('.gs-top-bar-section-login').removeClass('on-sticky');
                self.S('.gs-top-bar-section-search').removeClass('on-sticky');
                if(self.medium()) {
                    $('.gs-top-bar-section').show();
                    $('.gs-top-bar-section-main').show();
                    $('.gs-top-bar-section-banner').show();
                    $('.gs-top-bar-section-sub').show();
                    $('.gs-top-bar-section-login').hide();//se apro sezione in modalita onsticky quando torno a top devo nasconderla
                    $('.gs-top-bar-section-search').hide();//se apro sezione in modalita onsticky quando torno a top devo nasconderla
                    $('.toggle-gstopbar').hide();
                    $('.toggle-gstopbar-search').hide();
                    $('.toggle-gstopbar-login').hide(); 
                }
                else {
                    //todo: small onsticky (toggle ok .. da capire section )
                    $('.toggle-gstopbar').show();
                    $('.toggle-gstopbar-search').show();
                    $('.toggle-gstopbar-login').show(); 
                }
            }
          }
        }
      }
    },

    off : function () {
      this.S(this.scope).off('.fndtn.gstopbar');
      this.S(window).off('.fndtn.gstopbar');
    },

    reflow : function () {}
  };
}(jQuery, this, this.document));
