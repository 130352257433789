;(function ($) {
	
var getEventData = function( type , $this ) {
	//eventCategory = click to elemento/pagina
	//eventAction = click from elemento/pagina
	//label = testo del link clicckato
	
	switch(type) {
		//x con un click 2 eventi fare return di array di oggetti [{}, ... {}]
		case "elencoNews-news": 
			return {
				eventCategory: ( $this.parents('.gs-box-filter').length) ? 'archivio news' : 'scheda news',
				eventAction: 'click archivio news',
				label: ( $this.parents('.gs-box-filter').length) ? $this.text() : $this.find('h4').text()
			}
		case "hp-news" : 
			return {
				eventCategory: ( $this.parents().hasClass('gs-box-link')) ? 'archivio news' : 'scheda news',
				eventAction: 'click hp',
				label: ( $this.parents().hasClass('gs-box-link')) ? $this.text() : $this.find('h3').text()
			}
		case "hp-slider" : 
			var cat = null;
			if($this.parents().hasClass('gs-slider-carousel')) cat = 'slide' 
			else if($this.find('> h3').length) cat = 'scheda gioco - recensione' 
			else if($this.text() === 'Immagini') cat = 'scheda gioco - immagini'
			else if($this.text() === 'Filmati') cat = 'scheda gioco - video' 
			else if($this.text() === 'Anteprima') cat = 'scheda gioco - anteprima' 
			else if($this.text() === 'Recensione') cat = 'scheda gioco - recensione' 
			else if($this.text() === 'News') cat = 'scheda gioco - news' 
			
			return {
				eventCategory: cat,
				eventAction: 'click slider',
				label: ($this.parents().hasClass('gs-slider-carousel'))  ? $this.text() : $this.parents('[data-orbit-slide]').find('h3').text()
			}
		default: return {
			eventCategory: null,
			eventAction: null,
			label: null
		}
	}
};
	
$(document).on('click', '[data-gs-box-type] a', function() {
	
	var cat = $(this).parents('[data-gs-box-type]').first().attr('data-gs-box-type');
	var dataEvent= getEventData( cat , $(this) );
	var isArray = $.isArray(dataEvent);
	if( isArray || dataEvent.eventCategory !== null) {
		if(isArray) {
			$.each(dataEvent, function() {
				var dataEvt= this;
				ga('send', {
					hitType: 'event',
					eventCategory: dataEvt.eventCategory,
					eventAction: dataEvt.eventAction,
					eventLabel: dataEvt.label
				});
			})
		}
		else {
			ga('send', {
				hitType: 'event',
				eventCategory: dataEvent.eventCategory,
				eventAction: dataEvent.eventAction,
				eventLabel: dataEvent.label
			})
		}
	}
});

}(jQuery));