$.userAjaxCallRequest= {};
$.extend({
    setGsFormCancellaUtente: function() {
        $('#cancellaUtente').on('click',function(){ 
            $('#cancellaUtente').hide();
            $('#aggiornaUtente').hide();
            $('#aggiornaUtente').removeClass('hide');
            $('#datiUtente').hide(); 
            $('#aggiornaUtente').fadeIn();
            $('#divCancellaUtente').fadeIn();
        });
        $('#aggiornaUtente').on('click',function( ){ 
            $('form#gsFormCancellaUtente .annulla').trigger('click');
        });
        $('form#gsFormCancellaUtente').foundation({
            abide : {
                live_validate : true,
                focus_on_invalid : false,
                error_labels: true, // labels with a for="inputId" will recieve an `error` class
                timeout : 1000,
                patterns : {
                    password : /^[a-zA-Z0-9ÀÈÌÒÙàèìòùÁÉÍÓÚÝáéíóúýÂÊÎÔÛâêîôûÃÑÕãñõÄËÏÖÜŸäëïöüŸ¡¿çÇŒœßØøÅåÆæÞþÐð.!#$%&*+=?^_-]+$/  
                },
                validators: {
                    checkLogin: function(el, required, parent) {
                        //return true se il controllo è superato con successo
                        var str = el.value;
                        var result = str.match(Foundation.libs.abide.settings.patterns.password);
                        if(!typeof($(el).attr('ajax-check'))) {
                            $(el).attr('ajax-check','invalid');
                        }
                        if(!str || str.length < 1 ) { 
                            $(el).next('small').html('Password non inserita');
                            return false; 
                        } 
                        else if(result === null || result === undefined || result === false ) {
                            $(el).next('small').html('Password non valida');
                            return false; 
                        }
                        else if($(el).attr('ajax-check') == 'valid'){
                            return true;
                        }
                        else {
                            var obj = {};
                            obj.password = str;
                            var url=URL_API+'jsonRegistrazione.php?fn=checkLogin';
                            $.ajax({
                                dataType: "json",
                                url: url,
                                type: "POST",
                                data: obj,
                                success: function(data) { 
                                    if(data.data === false ) {
                                        $(el).attr('ajax-check','invalid');
                                        $(el).next('small').html('Password errata');
                                        return false; 
                                    } 
                                    else {
                                        //email valida
                                        $(el).attr('ajax-check','valid');
                                        $(parent).removeClass('error');
                                        $("label[for='"+el.id+"']").removeClass('error');
                                        $(el).attr('aria-invalid',false);
                                        $(el).removeAttr('aria-describedby');
                                        $(el).removeAttr('data-invalid');
                                        $(el).next('small').html('');
                                        return true; 
                                    }
                                }
                            }); 
                        }
                    } 
                }
            }
        }); 
        $('form#gsFormCancellaUtente').on('invalid.fndtn.abide', function() {
            // se ho errori faccio scrolltop sul primo errore trovato - è smoothlink
            var target = $("form#gsFormCancellaUtente label.error:first");
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top  -50
                }, 1000);
                return false;
            }
        });
        $('form#gsFormCancellaUtente').on('valid.fndtn.abide', Foundation.utils.debounce(function() {
            //Faccio submit della form di registrazione - con debounce prevengo click ripetuti
            $('#divCancellaUtente').hide('fast');
            $('div.loading').hide(); 
            $('div.loading').removeClass('hide'); 
            $('div.loading').fadeIn(); 
            var url=URL_API+'jsonRegistrazione.php?fn=cancellaUtente' ;
            $.ajax({
                dataType: "json",
                url: url,
                data: $('form#gsFormCancellaUtente').serialize(),
                type: "POST",
                success: function(data) { 
                    $('div.loading').hide();
                    if(data.data === true ) {
                        $('div.success').html('<i class="fa fa-check"></i><br>Password aggiornata correttamente.<br>I nuovi dati di accesso sono stati inviati all\'indirizzo '+ $('#mail-label').val()); 
                        $('div.success').fadeIn(); 
                        $('.smoothLink').trigger('click');
                        //ritorno a form di compilazione ..... e nascondo tasto cancella
                        setTimeout(function(){
                            $('form#gsFormCancellaUtente')[0].reset(); 
                            $('div.success').hide(); 
                            $('#aggiornaUtente').hide();
                            $('#datiUtente').fadeIn();
                            //$('#cancellaUtente').fadeIn();
                        }, 10000); //concedo secondi ..cosi legge meglio il mex.
                    }
                    else {
                        $('#divCancellaUtente').show('fast');
                    }
                    return false;
                }
            });
        }, 1000, true));
        $('form#gsFormCancellaUtente .annulla').on('click',function(e){
            e.preventDefault();
            $('form#gsFormCancellaUtente')[0].reset(); 
            $('#divCancellaUtente').hide('fast');
            $('#aggiornaUtente').hide();
            $('#datiUtente').fadeIn();
            $('#cancellaUtente').fadeIn();
            $('.smoothLink').trigger('click');
        });
    },
    setGsFormDatiAccesso: function() {
        $('#aggiornaPassword').on('click',function(){ 
            $('#aggiornaMail').removeClass('active');
            $('#aggiornaPassword').addClass('active');
            $('#formDatiMail').hide();  
            $('#formDatiAccesso').fadeIn();  
        });
        $('form#gsFormDatiAccesso').foundation({
            abide : {
                live_validate : true,
                focus_on_invalid : false,
                error_labels: true, // labels with a for="inputId" will recieve an `error` class
                timeout : 1000,
                patterns : {
                    password : /^[a-zA-Z0-9ÀÈÌÒÙàèìòùÁÉÍÓÚÝáéíóúýÂÊÎÔÛâêîôûÃÑÕãñõÄËÏÖÜŸäëïöüŸ¡¿çÇŒœßØøÅåÆæÞþÐð.!#$%&*+=?^_-]+$/ ,
                    password_new : /^[a-zA-Z0-9ÀÈÌÒÙàèìòùÁÉÍÓÚÝáéíóúýÂÊÎÔÛâêîôûÃÑÕãñõÄËÏÖÜŸäëïöüŸ¡¿çÇŒœßØøÅåÆæÞþÐð.$%&^_-]+$/
                },
                validators: {
                    checkLogin: function(el, required, parent) {
                        //return true se il controllo è superato con successo
                        var str = el.value;
                        var result = str.match(Foundation.libs.abide.settings.patterns.password);
                        if(!typeof($(el).attr('ajax-check'))) {
                            $(el).attr('ajax-check','invalid');
                        }
                        if(!str || str.length < 1 ) { 
                            $(el).next('small').html('Password non inserita');
                            return false; 
                        } 
                        else if(result === null || result === undefined || result === false ) {
                            $(el).next('small').html('Password non valida');
                            return false; 
                        }
                        else if($(el).attr('ajax-check') == 'valid'){
                            return true;
                        }
                        else {
                            var obj = {};
                            obj.password = str;
                            var url=URL_API+'jsonRegistrazione.php?fn=checkLogin';
                            $.ajax({
                                dataType: "json",
                                url: url,
                                type: "POST",
                                data: obj,
                                success: function(data) { 
                                    if(data.data === false ) {
                                        $(el).attr('ajax-check','invalid');
                                        $(el).next('small').html('Password errata');
                                        return false; 
                                    } 
                                    else {
                                        //email valida
                                        $(el).attr('ajax-check','valid');
                                        $(parent).removeClass('error');
                                        $("label[for='"+el.id+"']").removeClass('error');
                                        $(el).attr('aria-invalid',false);
                                        $(el).removeAttr('aria-describedby');
                                        $(el).removeAttr('data-invalid');
                                        $(el).next('small').html('');
                                        return true; 
                                    }
                                }
                            }); 
                        }
                    },
                    checkPassword: function(el, required, parent) {
                        //min 8 max 15
                        var str = el.value.trim();
                        var result = str.match(Foundation.libs.abide.settings.patterns.password_new);
                        
                        if(!str || str.length < 8 ) { 
                            $(el).next('small').html('Password non inserita o troppo corta (minimo 8 caratteri)');
                            return false; 
                        } 
                        else if(  str.length > 15 ) { 
                            $(el).next('small').html('Password troppo lunga (massimo 8 caratteri)');
                            return false; 
                        } 
                        else if(result === null || result === undefined || result === false ) {
                            $(el).next('small').html('Password non valida, sono presenti dei caratteri non accettati');
                            return false; 
                        }
                        else {
                            return true; 
                        }
                    },
                    equalTo: function(el, required, parent) {
                        var from  = document.getElementById(el.getAttribute(this.add_namespace('data-equalto'))).value,
                            to    = el.value,
                            valid = (from === to);
                        return valid;
                    }
                }
            }
        }); 
        $('form#gsFormDatiAccesso').on('invalid.fndtn.abide', function() {
            // se ho errori faccio scrolltop sul primo errore trovato - è smoothlink
            var target = $("form#gsFormDatiAccesso label.error:first");
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top  -50
                }, 1000);
                return false;
            }
        });
        $('form#gsFormDatiAccesso').on('valid.fndtn.abide', Foundation.utils.debounce(function() {
            //Faccio submit della form di registrazione - con debounce prevengo click ripetuti
            $('#formDatiAccesso').hide('fast');
            $('div.loading').hide(); 
            $('div.loading').removeClass('hide'); 
            $('div.loading').fadeIn(); 
            var url=URL_API+'jsonRegistrazione.php?fn=cambioPassword' ;
            $.ajax({
                dataType: "json",
                url: url,
                data: $('form#gsFormDatiAccesso').serialize(),
                type: "POST",
                success: function(data) { 
                    $('div.loading').hide();
                    if(data.data === true ) {
                        $('div.success').html('<i class="fa fa-check"></i><br>Password aggiornata correttamente.<br>I nuovi dati di accesso sono stati inviati all\'indirizzo '+ $('#mail-label').val()); 
                        $('div.success').fadeIn(); 
                        $('.smoothLink').trigger('click');
                        //ritorno a form di compilazione ..... e nascondo tasto cancella
                        setTimeout(function(){
                            $('form#gsFormDatiAccesso')[0].reset(); 
                            $('div.success').hide(); 
                            $('#formDatiAccesso').fadeIn();
                        }, 3000); //concedo 1 secondo in piu ( 2 secondi di solito )...cosi legge meglio il mex.
                    }
                    else {
                        $('#formDatiAccesso').show('fast');
                    }
                    return false;
                }
            });
        }, 1000, true));
        $('form#gsFormDatiAccesso .annulla').on('click',function(e){
            e.preventDefault();
            $('form#gsFormDatiAccesso')[0].reset(); 
        });
        //passwd strengh
        $("form#gsFormDatiAccesso #psw-new-label").gsPasswordStrength(); 
    },
    setGsFormDatiMail: function() {
        $('#aggiornaMail').on('click',function(){ 
            $('#aggiornaPassword').removeClass('active');
            $('#aggiornaMail').addClass('active');
            $('#formDatiAccesso').hide();  
            $('#formDatiMail').fadeIn();  
        });
        $('form#gsFormDatiMail').foundation({
            abide : {
                live_validate : true,
                focus_on_invalid : false,
                error_labels: true, // labels with a for="inputId" will recieve an `error` class
                timeout : 1000,
                patterns : {
                    email : /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
                },
                validators: { 
                    checkEmail: function(el, required, parent) {
                        //return true se il controllo è superato con successo
                        var str = el.value;
                        var result = str.match(Foundation.libs.abide.settings.patterns.email);
                        if(!typeof($(el).attr('ajax-check'))) {
                            $(el).attr('ajax-check','invalid');
                        }
                        if(!str || str.length < 1 ) { 
                            $(el).next('small').html('E-mail non inserita');
                            return false; 
                        } 
                        else if(result === null || result === undefined || result === false ) {
                            $(el).next('small').html('E-mail non valida');
                            return false; 
                        }
                        else if($(el).attr('ajax-check') == 'valid'){
                            return true;
                        }
                        else {
                            var url=URL_API+'jsonRegistrazione.php?fn=checkEmail&EMAIL='+ str;
                            $.ajax({
                                dataType: "json",
                                url: url,
                                data: true,
                                success: function(data) { 
                                    if(data.data === false ) {
                                        $(el).attr('ajax-check','invalid');
                                        $(el).next('small').html('E-mail già in uso');
                                        return false; 
                                    } 
                                    else {
                                        //email valida
                                        $(el).attr('ajax-check','valid');
                                        $(parent).removeClass('error');
                                        $("label[for='"+el.id+"']").removeClass('error');
                                        $(el).attr('aria-invalid',false);
                                        $(el).removeAttr('aria-describedby');
                                        $(el).removeAttr('data-invalid');
                                        $(el).next('small').html('');
                                        return true; 
                                    }
                                }
                            }); 
                        }
                    },
                    equalTo: function(el, required, parent) {
                      var from  = document.getElementById(el.getAttribute(this.add_namespace('data-equalto'))).value,
                          to    = el.value,
                          valid = (from === to);

                      return valid;
                    }
                }
            }
        }); 
        $('form#gsFormDatiMail').on('invalid.fndtn.abide', function() {
            // se ho errori faccio scrolltop sul primo errore trovato - è smoothlink
            var target = $("form#gsFormDatiMail label.error:first");
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top  -50
                }, 1000);
                return false;
            }
        });
        $('form#gsFormDatiMail').on('valid.fndtn.abide', Foundation.utils.debounce(function() {
            //Faccio submit della form - con debounce prevengo click ripetuti
            //mostro loading e faccio chiamata  POST!
            $('#formDatiMail').hide('fast');
            $('div.loading').hide(); 
            $('div.loading').removeClass('hide'); 
            $('div.loading').fadeIn(); 
            var url=URL_API+'jsonRegistrazione.php?fn=cambioMail' ;
            var newMail = $('form#gsFormDatiMail #mail-new-label').val();
            $.ajax({
                dataType: "json",
                url: url,
                data: $('form#gsFormDatiMail').serialize(),
                type: "POST",
                success: function(data) { 
                    $('div.loading').hide();
                    if(data.data === true ) {
                        $('div.success').html('<i class="fa fa-check"></i><br>Ti è stato inviato un messaggio a '+newMail+'.<br>Segui la procedura riportata nella email per concludere la modifica del tuo indirizzo!'); 
                        $('div.success').fadeIn(); 
                        $('.smoothLink').trigger('click'); 
                        setTimeout(function(){
                            $('form#gsFormDatiMail')[0].reset(); 
                            $('form#gsFormDatiMail #mail-label').val(newMail); 
                            $('div.success').hide();
                            $('#formDatiMail').fadeIn();
                        }, 8000); 
                    }
                    else {
                        $('#formDatiMail').show('fast');
                    }
                    return false;
                }
            });
        }, 1000, true));
        $('form#gsFormDatiMail .annulla').on('click',function(e){
            e.preventDefault();
            $('form#gsFormDatiMail')[0].reset(); 
        });
    },
    setGsFormMessaggio: function() {
        $('form#gsFormMessaggio').foundation({
            abide: {
                live_validate : true,
                focus_on_invalid : false,
                error_labels: true, // labels with a for="inputId" will recieve an `error` class
                timeout : 1000, 
                validators : {
                    checkOggetto: function (el, required, parent) {
                        var str = el.value.trim();
                        if(!str || str.length < 3 ) { 
                            $(el).next('small').html('Oggetto non inserito o troppo corto');
                            return false; 
                        } 
                        else if( str.length > 50  ) { 
                            $(el).next('small').html('Oggetto troppo lungo');
                            return false; 
                        }
                        else {
                            return true;
                        }
                    },
                    checkMessaggio: function (el, required, parent) {
                        var str = el.value.trim();
                        if(!str || str.length < 3 ) { 
                            $(el).next('small').html('Messaggio non inserito o troppo corto');
                            return false; 
                        }  
                        else {
                            return true;
                        }
                    },
                    checkUtente: function(el, required, parent) {
                        //return true se il controllo è superato con successo
                        var str = el.value;
                        if(!typeof($(el).attr('ajax-check'))) {
                            $(el).attr('ajax-check','invalid');
                        }
                        if(!str || str.length < 4 ) { 
                            $(el).next('small').html('Utente non trovato');
                            return false; 
                        } 
                        else if($(el).attr('ajax-check') == 'valid'){
                            return true;
                        }
                        else {
                            var url=URL_API+'jsonRegistrazione.php?fn=checkUsernameExist&USERNAME='+ str;
                            $.ajax({
                                dataType: "json",
                                url: url,
                                data: true,
                                success: function(data) { 
                                    if(data.data === false ) {
                                        //username valida - utente trovato
                                        $(el).attr('ajax-check','valid');
                                        $(parent).removeClass('error');
                                        $("label[for='"+el.id+"']").removeClass('error');
                                        $(el).attr('aria-invalid',false);
                                        $(el).removeAttr('data-invalid');
                                        $(el).removeAttr('aria-describedby');
                                        $(el).next('small').html('');
                                        return true; 
                                    } 
                                    else {
                                        $(el).attr('ajax-check','invalid');
                                        $(el).next('small').html('Utente non trovato');
                                        return false; 
                                    }
                                }
                            }); 
                        }
                    }
                }
            }
        });
        $('form#gsFormMessaggio').on('valid.fndtn.abide', Foundation.utils.debounce(function() {
            //Faccio submit della form di registrazione - con debounce prevengo click ripetuti
            $('#formMessaggio').hide('fast');
            $('div.loading').fadeIn(); 
            //mostro loading e faccio chiamata x invio msg IN POST!
            var url=URL_API+'jsonGetUserData.php?fn=inviaMessaggio' ;
            $.ajax({
                dataType: "json",
                url: url,
                data: $('form#gsFormMessaggio').serialize(),
                type: "POST",
                success: function(data) { 
                    $('div.loading').hide();
                    if(data.data === true ) {
                        $('div.success').html('<i class="fa fa-check"></i><br>Messaggio inviato correttamente'); 
                        $('div.success').fadeIn(); 
                        $('.smoothLink').trigger('click');
                        //ritorno a form di compilazione ..... e nascondo tasto cancella
                        setTimeout(function(){
                            $('div.success').hide(); 
                            $('#messaggiRicevuti').trigger('click');
                        }, 2000); 
                    }
                    else {
                        $('#formMessaggio').show('fast');
                    }
                    return false;
                }
            }); 
        }, 1000, true));
        $('form#gsFormMessaggio').on('invalid.fndtn.abide', function() {
            // se ho errori faccio scrolltop sul primo errore trovato - è smoothlink
            var target = $("form#gsFormMessaggio label.error:first");
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top  -50
                }, 1000);
                return false;
            }
        });
        $('form#gsFormMessaggio .annulla').on('click',function(e){
            e.preventDefault();
            $('#messaggiRicevuti').trigger('click'); 
        });
    },
    setGsFormGuestbook: function() {
        $('form#gsFormGuestbook').foundation({
            abide: {
                live_validate : true,
                focus_on_invalid : false,
                error_labels: true, // labels with a for="inputId" will recieve an `error` class
                timeout : 1000, 
                validators : {
                    checkOggetto: function (el, required, parent) {
                        var str = el.value.trim();
                        if(!str || str.length < 3 ) { 
                            $(el).next('small').html('Oggetto non inserito o troppo corto');
                            return false; 
                        } 
                        else if( str.length > 50  ) { 
                            $(el).next('small').html('Oggetto troppo lungo');
                            return false; 
                        }
                        else {
                            return true;
                        }
                    },
                    checkMessaggio: function (el, required, parent) {
                        var str = el.value.trim();
                        if(!str || str.length < 3 ) { 
                            $(el).next('small').html('Messaggio non inserito o troppo corto');
                            return false; 
                        }  
                        else {
                            return true;
                        }
                    } 
                }
            }
        });
        $('form#gsFormGuestbook').on('valid.fndtn.abide', Foundation.utils.debounce(function() {
            //Faccio submit della form di registrazione - con debounce prevengo click ripetuti
            $('#formGuestbook').hide('fast');
            $('div.loading').fadeIn(); 
            //mostro loading e faccio chiamata x invio msg IN POST!
            var utente = $('form#gsFormGuestbook').data('cod-utente');
            var url=URL_API+'jsonGetUserData.php?fn=inviaMessaggioGuestbook&id_utente_request='+utente ;
            $.ajax({
                dataType: "json",
                url: url,
                data: $('form#gsFormGuestbook').serialize(),
                type: "POST",
                success: function(data) { 
                    $('div.loading').hide();
                    if(data.data === true ) {
                        $('div.success').html('<i class="fa fa-check"></i><br>Messaggio inviato correttamente'); 
                        $('div.success').fadeIn(); 
                        $('.smoothLink').trigger('click');
                        setTimeout(function(){
                            //ritorno a elenco messaggi aggiornato
                            //window.location = URL_UTENTE_GUESTBOOK; 
                            $('div.success').hide(); 
                            $('#messaggiRicevuti').trigger('click');
                        }, 2000); 
                    }
                    else {
                        $('#formGuestbook').show('fast');
                    }
                    return false;
                }
            }); 
        }, 1000, true));
        $('form#gsFormGuestbook').on('invalid.fndtn.abide', function() {
            // se ho errori faccio scrolltop sul primo errore trovato - è smoothlink
            var target = $("form#gsFormGuestbook label.error:first");
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top  -50
                }, 1000);
                return false;
            }
        });
        $('form#gsFormGuestbook .annulla').on('click',function(e){
            e.preventDefault(); 
            $('#messaggiRicevuti').trigger('click');
            $('.smoothLink').trigger('click');
        });
    },
    setGsFormProfilo: function() {
        $('form#gsFormProfilo').foundation({
            abide: {
                live_validate : true,
                focus_on_invalid : false,
                error_labels: true, // labels with a for="inputId" will recieve an `error` class
                timeout : 1000, 
                patterns : {
                    username: /^[a-zA-Z0-9ÀÈÌÒÙàèìòùÁÉÍÓÚÝáéíóúýÂÊÎÔÛâêîôûÃÑÕãñõÄËÏÖÜŸäëïöüŸ¡¿çÇŒœßØøÅåÆæÞþÐð.!#$%&*+=?^_-]{4,20}$/,
                    alpha: /^[a-zA-ZÀÈÌÒÙàèìòùÁÉÍÓÚÝáéíóúýÂÊÎÔÛâêîôûÃÑÕãñõÄËÏÖÜŸäëïöüŸ¡¿çÇŒœßØøÅåÆæÞþÐð.!#$%&*+=?^_-]+$/,
                    alpha_numeric : /^[a-zA-Z0-9ÀÈÌÒÙàèìòùÁÉÍÓÚÝáéíóúýÂÊÎÔÛâêîôûÃÑÕãñõÄËÏÖÜŸäëïöüŸ¡¿çÇŒœßØøÅåÆæÞþÐð.!#$%&*+=?^_-]+$/,
                    integer: /^[-+]?\d+$/,
                    number: /^[-+]?[1-9]\d*$/, 
                    email : /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                    url: /(https?|ftp|file|ssh):\/\/(((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?/,
                    // abc.de
                    domain: /^([a-zA-Z0-9]([a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}$/,
                    // YYYY-MM-DD
                    date: /(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:30))|(?:(?:0[13578]|1[02])-31))/,
                    // MM/DD/YYYY
                    month_day_year : /(0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])[- \/.](19|20)\d\d/,
                    // DD/MM/YYYY
                    day_month_year : /(0[1-9]|[12][0-9]|3[01])[- \/.](0[1-9]|1[012])[- \/.](19|20)\d\d/ 
                },
                validators : { 
                    checkName: function (el, required, parent) {
                        var str = el.value.trim();
                        if(!str || str.length < 2 ) { 
                            $(el).next('small').html('Nome non inserito o troppo corto');
                            return false; 
                        } 
                        else if( str.length > 50  ) { 
                            $(el).next('small').html('Nome troppo lungo');
                            return false; 
                        }
                        else {
                            return true;
                        }
                    },
                    checkSurname: function (el, required, parent) {
                        var str = el.value.trim();
                        if(!str || str.length < 2 ) { 
                            $(el).next('small').html('Cognome non inserito o troppo corto');
                            return false; 
                        } 
                        else if( str.length > 50  ) { 
                            $(el).next('small').html('Cognome troppo lungo');
                            return false; 
                        }
                        else {
                            return true;
                        }
                    },
                    checkText: function (el, required, parent) {
                        /*var str = el.value.trim();
                        if(!str || str.length < 3 ) { 
                            $(el).next('small').html('Messaggio non inserito o troppo corto');
                            return false; 
                        }  
                        else {*/
                        return true;
                        //}
                    }
                }
            }
        });
        $('form#gsFormProfilo').on('valid.fndtn.abide', Foundation.utils.debounce(function() {
            //Faccio submit della form di registrazione - con debounce prevengo click ripetuti
            $('#formProfilo').hide('fast');
            $('div.loading').removeClass('hide'); 
            $('div.loading').fadeIn(); 
            $('.smoothLink').trigger('click');
            //mostro loading e faccio chiamata x invio msg IN POST!
            var utente = $('form#gsFormProfilo').data('cod-utente');
            var url=URL_API+'jsonGetUserData.php?fn=aggiornaProfilo&id_utente_request='+utente ;
            $.ajax({
                dataType: "json",
                url: url,
                data: $('form#gsFormProfilo').serialize(),
                type: "POST",
                success: function(data) { 
                    $('div.loading').hide();
                    if(data.data === true ) {
                        //aggiorno scheda profilo con i nuovi dati
                        $('#nome-label-scheda').text($('#nome-label').val() + ' ' + $('#cognome-label').val());
                        $('#sesso-label-scheda').text($('input[name=sesso]:checked').val());
                        $('#localita-label-scheda').text($('#localita-label').val() + ' - ' + $('#provincia-label').val());
                        $('#data-nascita-label-scheda').text($('#data-nascita-label').val());
                        $('#descrizione-label-scheda').text($('#descrizione-label').val());
                        $('#motto-label-scheda').text($('#motto-label').val());
                        $('#hobby-label-scheda').text($('#hobby-label').val());
                        $('#professione-label-scheda').text($('#professione-label').val());
                        $('#studi-label-scheda').text($('#studi-label').val());
                        
                        $('#psn-label-scheda').text($('#psn-label').val());
                        if($('#psn-label-scheda').text().length) {
                            $('#psn-container').show();
                        } 
                        else {
                            $('#psn-container').hide();
                        }
                        $('#xboxlive-label-scheda').text($('#xboxlive-label').val());
                        if($('#xboxlive-label-scheda').text().length) {
                            $('#xboxlive-container').show();
                        } 
                        else {
                            $('#xboxlive-container').hide();
                        }
                        $('#wiicode-label-scheda').text($('#wiicode-label').val());
                        if($('#wiicode-label-scheda').text().length) {
                            $('#wiicode-container').show();
                        } 
                        else {
                            $('#wiicode-container').hide();
                        }
                        $('#nintendo_network_id-label-scheda').text($('#nintendo_network_id-label').val());
                        if($('#nintendo_network_id-label-scheda').text().length) {
                            $('#nintendo_network_id-container').show();
                        } 
                        else {
                            $('#nintendo_network_id-container').hide();
                        }
                        $('#folding_home-label-scheda').text($('#folding_home-label').val());
                        if($('#folding_home-label-scheda').text().length) {
                            $('#folding_home-container').show();
                        } 
                        else {
                            $('#folding_home-container').hide();
                        }
                        //mostro messaggio di conferma modifica
                        $('div.success').html('<i class="fa fa-check"></i><br>Profilo aggiornato correttamente'); 
                        $('div.success').fadeIn(); 
                        //tolgo classe hide (se presente) al pulsante cancella in modo da considerare i vari fadeIn/Out 
                        $('#cancellaProfilo').removeClass('hide');
                        setTimeout(function(){
                            //ritorno a scheda profilo
                            $('div.success').hide(); 
                            $('form#gsFormProfilo .annulla').trigger('click');
                        }, 2000); 
                    }
                    else {
                        $('#formProfilo').show('fast');
                    }
                    return false;
                }
            });
        }, 1000, true));
        $('form#gsFormProfilo .annulla').on('click',function(e){
            e.preventDefault();
            $('#formProfilo').hide();
            $('#cancellaProfilo').hide(); 
            $('#aggiornaProfilo').fadeIn();
            $('#schedaProfilo').fadeIn();
            $('.smoothLink').trigger('click');
        });
        $('form#gsFormProfilo').on('invalid.fndtn.abide', function() {
            // se ho errori faccio scrolltop sul primo errore trovato - è smoothlink
            var target = $("form#gsFormProfilo label.error:first");
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top  -50
                }, 1000);
                return false;
            }
        });
    },
    setGsFormHomepage: function() {
        $('form#gsFormHomepage .salva').on('click',Foundation.utils.debounce(function(e){
            e.preventDefault(); 
            //Faccio submit della form di registrazione - con debounce prevengo click ripetuti
            $('#formHomepage').hide('fast');
            $('div.loading').removeClass('hide'); 
            $('div.loading').fadeIn(); 
            $('.smoothLink').trigger('click');
            //mostro loading e faccio chiamata x invio msg IN POST!
            var utente = $('form#gsFormHomepage').data('cod-utente');
            var url=URL_API+'jsonGetUserData.php?fn=aggiornaHomepage' ;
            var obj = {};
            obj.descrizione = CKEDITOR.instances.descrizione.getData();
            obj.cod_utente = utente;
            $.ajax({
                dataType: "json",
                url: url,
                data: obj,
                type: "POST",
                success: function(data) { 
                    $('div.loading').hide();
                    if(data.data === true ) {
                        //aggiorno scheda profilo con i nuovi dati 
                        $('#homepageUtente').html(obj.descrizione); 
                        //mostro messaggio di conferma modifica
                        $('div.success').html('<i class="fa fa-check"></i><br>Home page aggiornata correttamente'); 
                        $('div.success').fadeIn(); 
                        //tolgo classe hide (se presente) al pulsante cancella in modo da considerare i vari fadeIn/Out 
                        $('#cancellaHomepage').removeClass('hide');
                        setTimeout(function(){
                            //ritorno a scheda profilo
                            $('div.success').hide(); 
                            $('form#gsFormHomepage .annulla').trigger('click');
                        }, 2000); 
                    }
                    else {
                        $('#formHomepage').show('fast');
                    }
                    return false;
                }
            });
        }, 1000, true));
        $('form#gsFormHomepage .annulla').on('click',function(e){
            e.preventDefault();
            $('#formHomepage').hide();
            $('#cancellaHomepage').hide(); 
            $('#aggiornaHomepage').fadeIn();
            $('#homepageUtente').fadeIn();
            $('.smoothLink').trigger('click');
        });
    },
    setGsFormAvatar: function() {
        //caso click da box info utente 
        if($('#infoUtente').length>0){
            var pulisciFormAvatar = function () {
                $('form#uploadimage')[0].reset();
                $('#gsModalUpload .error').text('');
                $('#gsModalUpload .error').hide();
            };
            var validationFail = true ;
            var tempImg="";
            $('#aggiornaAvatar').on('click',function(){ 
                //ho cliccato dal box infoUtente
                validationFail = true ;
                var ut = $('#infoUtente').data('cod-utente');
                if($('#avatarUtente').length>0) {
                    $('#previewAvatar').attr('src', $('#avatarUtente').attr('src'));
                }
                pulisciFormAvatar();
                $('#gsModalUpload').foundation('reveal','open'); 
                $('#gsModalUpload').data('cod-utente',ut);  
            });
            $('#gsModalUpload .annulla').on('click', function() {
                $('#gsModalUpload').foundation('reveal', 'close');
            });
            $('#gsModalUpload #file-label').on('change', function() {
                //antemprima e validazione dell'immagine da caricare
                var file = this.files[0];
                validationFail = true ;
                if( typeof(file)!= "undefined") {
                    var imagefile = file.type;   
                    var match= ["image/jpeg","image/png","image/jpg","image/gif"];
                    if(!((imagefile==match[0]) || (imagefile==match[1]) || (imagefile==match[2]) || (imagefile==match[3]))) {
                        //fail validatione 
                        $('#gsModalUpload .error').text('Formato immagine non valido. Formati accettati: JPG, PNG, GIF');
                        $('#gsModalUpload .error').show();
                        return false;
                    } 
                    else if( file.size > 640000) {
                        //fail validatione 
                        var iSize = (file.size / 1024); 
                        iSize = (Math.round(iSize * 100) / 100); 
                        $('#gsModalUpload .error').text('Immagine troppo pesante: '+iSize+' kB ( max 600 kB )');
                        $('#gsModalUpload .error').show();
                        return false;
                    }
                    else {
                        validationFail = false ;
                        $('#previewAvatar').hide();
                        var reader = new FileReader();
                        reader.onload = function(e) { 
                            var image = new Image();
                            image.src = e.target.result; 
                            tempImg =  e.target.result;
                            image.onload = function() { 
                                $('#previewAvatar').attr('src', this.src); 
                                $('#previewAvatar').fadeIn();
                                if(this.width > 1280 || $('#previewAvatar').height > 1024) {
                                    $('#gsModalUpload .error').text('Dimensioni massime accettate 1280x1024 px');
                                    $('#gsModalUpload .error').show();
                                    validationFail = true ;
                                }
                            };
                        };
                        reader.readAsDataURL(file);
                        $('#gsModalUpload .error').text('');
                        $('#gsModalUpload .error').hide();
                    }
                }
                else  {
                    $('#gsModalUpload .error').text('Nessuna immagine selezionata');
                    $('#gsModalUpload .error').show();
                    if($('#avatarUtente').length>0) {
                        $('#previewAvatar').attr('src', $('#avatarUtente').attr('src'));
                    }
                    return false;
                }
            }); 
            $('#gsModalUpload .procedi').on('click', function() {
                var utente = $('#gsModalUpload').data('cod-utente'); 
                var url=URL_API+'jsonGetUserData.php' ;
                url += '?fn=aggiornaAvatar&id_utente_request='+utente;
                var obj = {}; 
                obj.cod_utente = utente;
                if(validationFail === false ) {
                    $('#gsModalUpload #formAvatar').hide('fast');
                    $('#gsModalUpload .error').hide();
                    $('#gsModalUpload .gs-button').hide('fast');
                    $('#gsModalUpload div.loading').removeClass('hide'); 
                    $('#gsModalUpload div.loading').fadeIn(); 
                    //eseguo upload
                    $.ajax({
                        dataType: "json",
                        url: url,
                        //data: obj,
                        data: new FormData($("#gsModalUpload #uploadimage").get(0)),
                        type: "POST",
                        contentType: false,       // The content type used when sending data to the server.
                        cache: false,             // To unable request pages to be cached
                        processData:false,        // To send DOMDocument or non processed data file it is set to false
                        error: function(){},
                        success: function(data) {   
                            $('#gsModalUpload div.loading').hide();
                            if(data.avatar) {  
                                //mostro messaggio di conferma modifica con tasto di chiusura 
                                $('#gsModalUpload div.success').html('<i class="fa fa-check"></i><br>Avatar modificato correttamente'); 
                                $('#gsModalUpload div.success').fadeIn();  
                                //aggiorno avatar box e topbar menu
                                
                                // data.avatar+'?'+new Date().getTime()); mi da problemi di cache immagine!
                                $('#avatarUtente').remove();
                                $('#infoUtente .content-avatar').prepend('<img id="avatarUtente" src="">');
                                //$('#avatarUtente').data('bttrlazyloading-xs-src', tempImg); 
                                $('#avatarUtente').prop('src', tempImg); 
                                $('#avatarUtenteTop').prop('src', tempImg); 
                                $('#avatarUtenteTopMobile').prop('src', tempImg); 
                                // svuoto form  
                                setTimeout(function(){
                                    $('#gsModalUpload div.success').hide('slow'); 
                                    $('#gsModalUpload #formAvatar').show('slow');
                                    $('#gsModalUpload .gs-button').show('slow');  
                                    $('#gsModalUpload').foundation('reveal', 'close');
                                }, 2000); 
                            }
                            else {
                                $('#gsModalUpload #formAvatar').show('fast');
                                $('#gsModalUpload .gs-button').show('fast');
                            }
                        }
                    });
                    //faccio scroll top x migliorare usabilita
                    var target = $('#gsModalUpload');
                    if (target.length) {
                        $('html,body').animate({
                            scrollTop: target.offset().top  -50
                        }, 1000); 
                    }
                }
                else if($('#gsModalUpload .error').text() === '') {
                    $('#gsModalUpload .error').text('Nessuna immagine selezionata');
                    $('#gsModalUpload .error').show();
                }
            }); 
        }
    },
    setGsFormTop10: function() {
        var listaProdotti = {}; 
        var loadGiochiSelected = function(piattaforma){ 
            var pos = 1;
            $.each($('div[data-options="consolle:'+piattaforma+'"]'),function(){
                
                $('form#gsFormTop10 select#posizione'+pos+'-label').val($(this).data('cod-prodotto'));
                pos++;
            });
        };
        $('form#gsFormTop10').foundation({
            abide: {
                live_validate : true,
                focus_on_invalid : false,
                error_labels: true, // labels with a for="inputId" will recieve an `error` class
                timeout : 1000, 
                validators : { 
                    checkSelect: function (el, required, parent) {
                        var str = el.value ; 
                        if(!str) { 
                            $(el).next('small').html('Nessun gioco selezionato');
                            return false; 
                        } 
                        else { 
                            var totSel=0;
                            $.each($('form#gsFormTop10 select'),function(){ 
                                if(this.value == str) totSel++;
                            });
                            if(totSel > 1 ){
                                $(el).next('small').html('Gioco selezionato più volte');
                                return false;
                            }
                            else {
                                return true;
                            }
                        }
                    }
                }
            }
        });
        $('#aggiornaTop10 , .aggiornaTop10').on('click',function(){
            //step 1 verifico obj temp giochi x consolle..
            //se assente o diverso dalla piattaforma che ho faccio chiamata ajax
            //poi mostro form con select popolate e nascondo loading
            var piattaforma = $(this).data('piattaforma');
            var type ="elencoGiochiCompleto";
            $('.gs-box-filter').hide(); 
            $('.gs-box-content-intro').hide();
            $('#top10ErrorLabel').hide();
            $('.gs-box-content-chart-expanded.expanded').hide();
            $('#formTop10').hide();
            $('form#gsFormTop10').data('piattaforma',piattaforma);
            $("#formTop10Intro h3").html("Aggiorna la classifica personale per " +piattaforma);
            //devo fare chiamata per ottenere elenco ? se si mostro loading e attendo
            if(listaProdotti.piattaforma === undefined || listaProdotti.piattaforma != piattaforma) {
                $('div.loading').removeClass('hide'); 
                $('div.loading').fadeIn(); 
                var url= URL_API+'local_json.php?fn='+type +'&tipo=attuali&npiattaforma='+piattaforma ;
                $.ajax({
                    dataType: "json",
                    url: url,
                    data: null,
                    type: "GET",
                    success: function(data) { 
                        listaProdotti.piattaforma = piattaforma;
                        listaProdotti.lista = data.games;
                        $.when( 
                            $('form#gsFormTop10 select').empty(),
                            $('form#gsFormTop10 select').append($("<option />").val("").text("- Selezionare -")),
                            $.each(listaProdotti.lista, function() {
                                //Ho 10 select le popolo in contemporanea
                                $('form#gsFormTop10 select').append($("<option />").val(this.id).text(this.nome));
                            })
                        ).done(function(){
                            loadGiochiSelected(piattaforma);
                            $('.loading').hide();
                            $('#formTop10Intro .content-text').hide();
                            $('#formTop10Intro .content-text').removeClass('hide'); 
                            $('#formTop10Intro .content-text').fadeIn(); 
                            $('#formTop10Intro').fadeIn(); 
                            $('#formTop10').fadeIn(); 
                        });
                    }
                });   
            }
            else { 
                loadGiochiSelected(piattaforma);
                $('#formTop10Intro .content-text').hide();
                $('#formTop10Intro .content-text').removeClass('hide'); 
                $('#formTop10Intro .content-text').fadeIn(); 
                $('#formTop10Intro').fadeIn(); 
                $('#formTop10').fadeIn(); 
            }
            $('.smoothLink').trigger('click');
        });
        $('form#gsFormTop10 .annulla').on('click', function(e) {
            e.preventDefault();
            $('#formTop10').hide(); 
            $('.gs-box-filter').fadeIn(); 
            $('.gs-box-content-intro').fadeIn();
            $('#top10ErrorLabel').fadeIn();
            $('form#gsFormTop10')[0].reset();
            $('#formTop10Intro').hide(); //form top 10 intro lo nascondo dopo gs-box-content-intro
            $('.gs-box-content-chart-expanded.expanded').fadeIn();
            $('.smoothLink').trigger('click');
        });
        $('form#gsFormTop10').on('valid.fndtn.abide', Foundation.utils.debounce(function() {
            //Faccio submit della form di registrazione - con debounce prevengo click ripetuti
            $('#formTop10').hide('fast');
            $('#formTop10Intro').hide('fast'); 
            $('div.loading').fadeIn(); 
            $('.smoothLink').trigger('click');
            //mostro loading e faccio chiamata x invio msg IN POST! 
            var piattaforma = $('form#gsFormTop10').data('piattaforma');
            var utente = $('form#gsFormTop10').data('cod-utente');
            var url=URL_API+'jsonGetUserData.php?fn=aggiornaTop10&id_utente_request='+utente+'&npiattaforma='+piattaforma ;
            $.ajax({
                dataType: "json",
                url: url,
                data: $('form#gsFormTop10').serialize(),
                type: "POST",
                success: function(data) { 
                    $('div.loading').hide();
                    if(data.data === true ) {
                        //TODO AGGIORNARE o INSERIRE TOP 10 .. COME ? CASO SEMPLICE è REFRESH PAGINA
                        $('div.success').html('<i class="fa fa-check"></i><br>Classifica top 10 aggiornata correttamente'); 
                        $('div.success').fadeIn(); 
                        setTimeout(function(){
                            //ritorno a top 10
                            //$('div.success').hide(); 
                            //$('form#gsFormTop10 .annulla').trigger('click');
                            window.location = window.location.href.split('#')[0];    
                        }, 2000); 
                    }
                    else {
                        $('#formTop10').show('fast');
                        $('#formTop10Intro').show('fast');
                    }
                    return false;
                }
            });
        }, 1000, true));
        $('form#gsFormTop10').on('invalid.fndtn.abide', function() {
            // se ho errori faccio scrolltop sul primo errore trovato - è smoothlink
            var target = $("form#gsFormTop10 label.error:first");
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top  -50
                }, 1000);
                return false;
            }
        });
    }, 
    setGsFormConsole:function() {
        $('form#gsFormConsole').on('submit', Foundation.utils.debounce(function() {
            //Faccio submit della form  con debounce prevengo click ripetuti
            //mostro loading e faccio chiamata x registrarmi IN POST!
            //Faccio submit della form di registrazione - con debounce prevengo click ripetuti
            $('#formConsole').hide('fast');
            $('div.loading').removeClass('hide'); 
            $('div.loading').fadeIn(); 
            $('.smoothLink').trigger('click');
            //mostro loading e faccio chiamata x invio msg IN POST!
            var utente = $('form#gsFormConsole').data('cod-utente');
            var url=URL_API+'jsonGetUserData.php?fn=aggiornaConsole';
            $.ajax({
                dataType: "json",
                url: url,
                data: $('form#gsFormConsole').serialize() + '&id_utente_request='+utente ,
                type: "POST",
                success: function(data) { 
                    $('div.loading').hide();
                    if(data.data === true ) {
                        //aggiorno scheda   
                         
                        //mostro messaggio di conferma modifica
                        $('div.success').html('<i class="fa fa-check"></i><br>Elenco console aggiornato correttamente'); 
                        $('div.success').fadeIn(); 
                        //tolgo classe hide (se presente) al pulsante cancella in modo da considerare i vari fadeIn/Out 
                        setTimeout(function(){
                            //ritorno a scheda ì
                            window.location = window.location.href.split('#')[0];    
                            //$('div.success').hide(); 
                            //$('form#gsFormConsole .annulla').trigger('click');
                        }, 2000); 
                    }
                    else {
                        $('#formConsole').show('fast');
                    }
                    return false;
                }
            }); 
            return false;
        }, 1000, true)); 
        $('form#gsFormConsole .annulla').on('click',function(e){
            e.preventDefault();
            $('#formConsole').hide(); 
            $('#introConsole').hide(); 
            $('#elencoConsole').hide();
            $('form#gsFormConsole')[0].reset(); 
            $('#aggiornaConsole').fadeIn();
            $('#schedaConsole').fadeIn();
            $('.smoothLink').trigger('click');
        });
    },
    setGsFormInvioTrucchi:function() {
        $('#mostraTrucchi').on('click',function(){
            $('form#gsFormInvioTrucchi .annulla').trigger('click');
        });
        $('form#gsFormInvioTrucchi').foundation({
            abide: {
                live_validate : true,
                focus_on_invalid : false,
                error_labels: true, // labels with a for="inputId" will recieve an `error` class
                timeout : 1000, 
                validators : {
                    checkTitolo: function (el, required, parent) {
                        var str = el.value.trim();
                        if(!str || str.length < 3 ) { 
                            $(el).next('small').html('Titolo non inserito o troppo corto');
                            return false; 
                        } 
                        else if( str.length > 80  ) { 
                            $(el).next('small').html('Titolo troppo lungo');
                            return false; 
                        }
                        else {
                            return true;
                        }
                    },
                    checkMessaggio: function (el, required, parent) {
                        var str = el.value.trim();
                        if(!str || str.length < 3 ) { 
                            $(el).next('small').html('Descrizione non inserita o troppo corta');
                            return false; 
                        }
                        else if( str.length > 10000 ) { 
                            $(el).next('small').html('Descrizione troppo lunga');
                            return false; 
                        }
                        else {
                            return true;
                        }
                    } 
                }
            }
        });
        $('form#gsFormInvioTrucchi').on('valid.fndtn.abide', Foundation.utils.debounce(function() {
            //Faccio submit della form di registrazione - con debounce prevengo click ripetuti
            $('#formInvioTrucchi').hide();
            $('div.loading').fadeIn(); 
            //mostro loading e faccio chiamata x invio msg IN POST!
            var prodotto = $('form#gsFormInvioTrucchi').data('cod-prodotto');
            var piattaforma = $('form#gsFormInvioTrucchi').data('cod-piattaforma');
            var url=URL_API+'jsonGetUserData.php?fn=inviaTrucco';
            $.ajax({
                dataType: "json",
                url: url,
                data: $('form#gsFormInvioTrucchi').serialize() + '&cod_prodotto='+prodotto+ '&id_piattaforma='+piattaforma,
                type: "POST",
                success: function(data) { 
                    $('div.loading').hide();
                    if(data.data === true ) {
                        $('div.success').html('<i class="fa fa-check"></i><br>Grazie per averci inviato questo trucco.<br>Se ritenuto valido il trucco verrà pubblicato al più presto. Non inviare nuovamente questo trucco se non lo vedi aggiunto! Riceviamo moltissimi trucchi ogni giorno, ci vuole tempo per valutare ed approvare quelli inviati!'); 
                        $('div.success').fadeIn(); 
                        $('.smoothLink').trigger('click');
                        setTimeout(function(){ 
                            $('div.success').hide(); 
                            $('form#gsFormInvioTrucchi .annulla').trigger('click');
                        }, 8000); 
                    }
                    else {
                        $('#formInvioTrucchi').show();
                    }
                    return false;
                }
            }); 
        }, 1000, true));
        $('form#gsFormInvioTrucchi').on('invalid.fndtn.abide', function() {
            // se ho errori faccio scrolltop sul primo errore trovato - è smoothlink
            var target = $("form#gsFormInvioTrucchi label.error:first");
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top  -50
                }, 1000);
                return false;
            }
        });
        $('form#gsFormInvioTrucchi .annulla').on('click',function(e){
            e.preventDefault(); 
            $('#formInvioTrucchi').hide(); 
            $('#introInvioTrucchi').hide(); 
            $('#mostraTrucchi').hide();   
            $('form#gsFormInvioTrucchi')[0].reset();
            $('#introTrucchi').fadeIn();
            $('#elencoTrucchi').fadeIn(); 
            $('.gs-box-content.js-generated').fadeIn();
            $('.endOnScreen').fadeIn();
            
            $('.smoothLink').trigger('click');
        });
        $('form#gsFormInvioTrucchi .tasti-button input[type="checkbox"]').on('click',function(e){
            e.preventDefault(); 
            var value = $(this).data('value');
            $('form#gsFormInvioTrucchi #descrizione-label').val($('form#gsFormInvioTrucchi #descrizione-label').val()+value+' ');  
        });
    },
    setGsFormCommenti:function() {
        $('form#gsFormCommenti').foundation({
            abide: {
                live_validate : true,
                focus_on_invalid : false,
                error_labels: true, // labels with a for="inputId" will recieve an `error` class
                timeout : 1000, 
                validators : {
                    checkMessaggio: function (el, required, parent) {
                        var str = el.value.trim();
                        if(!str || str.length < 3 ) { 
                            $(el).next('small').html('Commento non inserito o troppo corto');
                            return false; 
                        }  
                        else {
                            return true;
                        }
                    } 
                }
            }
        });
        $('form#gsFormCommenti .emoticonContainer .emoticon').on('click',function(){
            val = $(this).data('text').replace(" ","")+" ";
            $('form#gsFormCommenti #descrizione-label').val( $('form#gsFormCommenti #descrizione-label').val() + " "+ val );
        });
        $('form#gsFormCommenti').on('valid.fndtn.abide', Foundation.utils.debounce(function() {
            //Faccio submit della form di registrazione - con debounce prevengo click ripetuti
            $('#formCommenti').hide();
            $('div.loading').fadeIn(); 
            //mostro loading e faccio chiamata x invio msg IN POST!
            var prodotto = $('form#gsFormCommenti').data('cod-prodotto');
            var news = $('form#gsFormCommenti').data('cod-news');
            var piattaforma = $('form#gsFormCommenti').data('cod-piattaforma');
            var recensione = $('form#gsFormCommenti').data('cod-recensione');
            var anteprima = $('form#gsFormCommenti').data('cod-anteprima');
            var url=URL_API+'jsonGetUserData.php?fn=inviaCommento';
            $.ajax({
                dataType: "json",
                url: url,
                data: $('form#gsFormCommenti').serialize() + '&cod_prodotto='+prodotto
                + '&cod_news='+news+ '&cod_anteprima='+anteprima+ '&cod_recensione='+recensione+ '&id_piattaforma='+piattaforma,
                type: "POST",
                success: function(data) { 
                    $('div.loading').hide();
                    if(data.data === true ) {
                        $('div.success').html('<i class="fa fa-check"></i><br>Grazie per averci inviato un tuo commento su questo gioco.'); 
                        $('div.success').fadeIn(); 
            
                        var target = $('#elencoCommenti').parent() ;
                        if (target.length) {
                            $('html,body').animate({
                                scrollTop: target.offset().top
                            }, 1000); 
                        }
                        setTimeout(function(){ 
                            $('div.success').hide(); 
                            window.location = window.location.href.split('#')[0];    
                            //$('form#gsFormCommenti .annulla').trigger('click');
                        }, 2500); 
                    }
                    else {
                        $('#formCommenti').show();
                    }
                    return false;
                }
            }); 
        }, 1000, true));
        $('form#gsFormCommenti').on('invalid.fndtn.abide', function() {
            // se ho errori faccio scrolltop sul primo errore trovato - è smoothlink
            var target = $("form#gsFormCommenti label.error:first");
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top  -50
                }, 1000);
                return false;
            }
        });
        $('form#gsFormCommenti .annulla').on('click',function(e){
            e.preventDefault(); 
            $('#elencoCommentiBtn').trigger('click'); 
            
            var target = $('#elencoCommenti').parent();
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top
                }, 1000); 
            }
        });
    },
    setGsFormInviaNews: function() {
        var validationFail = true ; //flag x upload immagini
        $('form#inviaNews').foundation({
            abide: {
                live_validate : true,
                focus_on_invalid : false,
                error_labels: true, // labels with a for="inputId" will recieve an `error` class
                timeout : 1000, 
                patterns : {
                    codici: /^[0-9]+$|^[0-9]+;([0-9]+;|[0-9]+)*$/, /*controlla codici gioco inseriti in form*/
                    url: /(((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?//* /(https?|ftp|file|ssh):\/\ */ 
                },
                validators : {
                    checkTitolo: function (el, required, parent) {
                        var str = el.value.trim();
                        if(!str || str.length < 3 ) { 
                            $(el).next('small').html('Titolo non inserito o troppo corto');
                            return false; 
                        } 
                        else if( str.length > 300  ) { 
                            $(el).next('small').html('Titolo troppo lungo');
                            return false; 
                        }
                        else {
                            return true;
                        }
                    },
                    checkDescrizione: function (el, required, parent) {
                        var str = el.value.trim();
                        if(!str || str.length < 3 ) { 
                            //$(el).next('small').html('Descrizione non inserita o troppo corta');
                            return false; 
                        }  
                        else {
                            return true;
                        }
                    } 
                }
            }
        }); 
        $(document).on('click','form#inviaNews #uploadList img',function(){
            var value = $(this).data('value') ;
            var txt = CKEDITOR.instances['descrizione-label'].getData();
            CKEDITOR.instances['descrizione-label'].setData(txt + '<img src="'+value+'" >' ); 
        });
        $(document).on('click','form#inviaNews #elencoGiochi .associaGioco',function(){
            var value = $(this).data('value')+';';
            $('form#inviaNews #giochi-label').val($('form#inviaNews #giochi-label').val() + value);
        });
        $('form#inviaNews input[type="checkbox"]').on('change',function(){
            if($('form#inviaNews input[type="checkbox"]').serialize()) {
                $('form#inviaNews #elencoGiochi').html('<div class="loading "><i class="fa fa-refresh fa-spin"></i></div>');
                var objRequest={};
                var callbackAction = function(data){  
                    $('form#inviaNews #elencoGiochi .loading').hide(); 
                    var html ="";
                    $('form#inviaNews #elencoGiochi').append("<div  class=\"giochi-news-container\"></div>"); 
                    $.each(data.games,function(){
                        html = "<div class='associaGioco' data-value='"+this.id+"'>"+
                            this.piattaforma.toUpperCase()+ " - "+this.nome+ "</div>";  
                        $('form#inviaNews #elencoGiochi .giochi-news-container').append(html); 
                    });  
                };
                objRequest["type"]="elencoGiochiCompleto";
                objRequest["clearQueue"]=true; 
                objRequest["serialized"]=$('form#inviaNews input[type="checkbox"]').serialize(); 
                objRequest["tipo"]="tutti"; 

                $.userAjaxCall(objRequest , callbackAction );
            }
            else {
                $('form#inviaNews #elencoGiochi').html('');
                if($.userAjaxCallRequest["elencoGiochiCompleto"]) {
                    $.userAjaxCallRequest["elencoGiochiCompleto"].abort();
                }
            }
        }); 
        $('form#inviaNews #file-label').on('change', function() {
            //antemprima e validazione dell'immagine da caricare
            var file = this.files[0];
            validationFail = true ;
            if( typeof(file)!= "undefined") {
                var imagefile = file.type;   
                var match= ["image/jpeg","image/png","image/jpg","image/gif"];
                if(!((imagefile==match[0]) || (imagefile==match[1]) || (imagefile==match[2]) || (imagefile==match[3]))) {
                    //fail validatione 
                    $('#uploadError').text('Formato immagine non valido. Formati accettati: JPG, PNG, GIF');
                    $('#uploadError').show();
                    $('#uploadPreview').attr('src', ""); 
                    $('#uploadPreview').hide();
                    $('button#uploadFile').hide();
                    return false;
                } 
                else if( file.size > 640000) {
                    //fail validatione 
                    var iSize = (file.size / 1024); 
                    iSize = (Math.round(iSize * 100) / 100); 
                    $('#uploadError').text('Immagine troppo pesante: '+iSize+' kB ( max 600 kB )');
                    $('#uploadError').show();
                    $('#uploadPreview').attr('src', "");
                    $('#uploadPreview').hide(); 
                    $('button#uploadFile').hide();
                    return false;
                }
                else {
                    validationFail = false ;
                    $('#uploadPreview').hide();
                    $('button#uploadFile').hide();
                    var reader = new FileReader();
                    reader.onload = function(e) { 
                        var image = new Image();
                        image.src = e.target.result; 
                        tempImg =  e.target.result;
                        image.onload = function() { 
                            if(this.width > 1280 || $('#uploadPreview').height > 1024) {
                                $('#uploadError').text('Dimensioni massime accettate 1280x1024 px');
                                $('#uploadError').show();
                                validationFail = true ;
                            }
                            else {
                                $('#uploadPreview').attr('src', this.src); 
                                $('#uploadPreview').fadeIn(); 
                                $('button#uploadFile').fadeIn(); 
                            }
                        };
                    };
                    reader.readAsDataURL(file);
                    $('#uploadError').text('');
                    $('#uploadError').hide();
                    $('button#uploadFile').hide(); 
                }
            }
            else  {
                $('#uploadError').text('Nessuna immagine selezionata');
                $('#uploadError').show();
                $('#uploadPreview').attr('src', ""); 
                $('#uploadPreview').hide();
                /*if($('#avatarUtente').length>0) {
                    $('#previewAvatar').attr('src', $('#avatarUtente').attr('src'));
                }*/
                return false;
            }
        }); 
        $('form#inviaNews #uploadFile').on('click', Foundation.utils.debounce(function() {
            //eseguo upload
            $('#uploadLoading span').css({width:"0%"});
            if(validationFail!==true) {
                $('#file-label').hide();
                $('#uploadPreview').hide();
                $('button#uploadFile').hide();
                $('#uploadLoading').fadeIn();
                var url=URL_API+'jsonGetUserData.php' ;
                url += '?fn=uploadImmagineNews'; 
                $.ajax({
                    xhr: function() {
                        var xhr = new window.XMLHttpRequest();
                        //Upload progress
                        xhr.upload.addEventListener("progress", function(evt){
                            if (evt.lengthComputable) {
                                var percentComplete = evt.loaded / evt.total;
                                percentComplete = parseInt(percentComplete * 100);
                                //Do something with upload progress
                                //console.log(percentComplete);
                                $('#uploadLoading span').animate({width:percentComplete+"%"});
                            }
                        }, false);
                        //Download progress
                        xhr.addEventListener("progress", function(evt){
                            if (evt.lengthComputable) {
                                var percentComplete = evt.loaded / evt.total;
                                percentComplete = parseInt(percentComplete * 100);
                                //Do something with download progress
                                $('#uploadLoading span').animate({width:percentComplete+"%"});
                            }
                        }, false);
                        return xhr;
                    },
                    dataType: "json",
                    url: url,
                    //data: obj,
                    data: new FormData($("form#inviaNews").get(0)),
                    type: "POST",
                    contentType: false,       // The content type used when sending data to the server.
                    cache: false,             // To unable request pages to be cached
                    processData:false,        // To send DOMDocument or non processed data file it is set to false
                    error: function(){},
                    success: function(data) {  
                        validationFail=true;
                        setTimeout(function(){
                            $("#file-label").replaceWith($("#file-label").clone(true));     //reset input file
                            $('#file-label').fadeIn();
                            $('button#uploadFile').fadeIn();
                            $('#uploadPreview').hide();
                            $('#uploadLoading').hide();
                            $('form#inviaNews #uploadList').append('<img data-value="'+data.uploadImageUrl+'" class="bttrlazyloading" data-bttrlazyloading-xs-src="'+data.uploadImageUrl+'" ><input type="hidden" name="image" value="'+data.uploadImageUrl+'" >');  
                            $.setLazy('update'); // Add animations to appended elements 
                        },200);
                    }
                });
            }
        }, 1000, true));
        $('form#inviaNews').on('valid.fndtn.abide', Foundation.utils.debounce(function() {
            //Faccio submit della form di registrazione - con debounce prevengo click ripetuti
            $('#inviaNews').hide();
            $('#loadingForm').fadeIn(); 
            //mostro loading e faccio chiamata x invio msg IN POST! 
            var url=URL_API+'jsonGetUserData.php?fn=inviaNews'; 
            
            var objRequest = {};
            objRequest.type="inviaNews";
            objRequest.url=URL_API+'jsonGetUserData.php';
            //creo oggetto da passare come post
            objRequest.serializedPostData= {}; 
            $.each($('form#inviaNews').serializeArray(), function(_, kv) {
              if (objRequest.serializedPostData.hasOwnProperty(kv.name)) {
                objRequest.serializedPostData[kv.name] = $.makeArray(objRequest.serializedPostData[kv.name]);
                objRequest.serializedPostData[kv.name].push(kv.value);
              }
              else {
                objRequest.serializedPostData[kv.name] = kv.value;
              }
            });
            objRequest.serializedPostData['descrizione-true'] = CKEDITOR.instances['descrizione-label'].getData();
            $.userAjaxCall(objRequest , function(data){
                $('#loadingForm').hide();
                if(data.data === true ) {
                    $('div.success').html('<i class="fa fa-check"></i><br>Grazie per averci inviato una news.'); 
                    $('div.success').fadeIn(); 
            
                    var target = $('#gs-main-row')  ;
                    if (target.length) {
                        $('html,body').animate({
                            scrollTop: target.offset().top
                        }, 1000); 
                    }
                    setTimeout(function(){ 
                        $('div.success').hide(); 
                        window.location =URL_INDEX;     
                    }, 3000); 
                }
                else {
                    $('form#inviaNews').show();
                }
            }); 
            /**/ 
        }, 1000, true));
        $('form#inviaNews .annulla').on('click', function() { 
            $('form#inviaNews').hide();
            $('#loadingForm').show();
            window.location =URL_INDEX; 
        });
        $('form#inviaNews').on('invalid.fndtn.abide', function() {
            // se ho errori faccio scrolltop sul primo errore trovato - è smoothlink
            var target = $("form#inviaNews label.error:first");
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top  -50
                }, 1000);
                return false;
            }
        });
        //load immagini utente 
        var mostraImmagini = function (data) { 
            $('form#inviaNews #uploadList .loading').hide();
            if(data.immagini) {
                $.each(data.immagini,function( index,img ){
                    $('form#inviaNews #uploadList').append('<img data-value="'+img+'" class="bttrlazyloading" data-bttrlazyloading-xs-src="'+img+'" ><input type="hidden" name="image" value="'+img+'" >'); 
                });
                $.setLazy('update'); // Add animations to appended elements 
            }
        };
        var objRequest = {};
        objRequest.type="getImmaginiNews";
        objRequest.url=URL_API+'jsonGetUserData.php';
        $('form#inviaNews #uploadList').html('<div class="loading "><i class="fa fa-refresh fa-spin"></i></div>');
        $.userAjaxCall(objRequest , mostraImmagini );
    },
    getUserInfo: function( type ) {
        var scope = $('body').data('scope');
        if($.userLogin) {
            //aggiorno numero messaggi da leggere e gsp 
            var aggiornaContatori = function (data) {
                if(data.data) { 
                    $('.contatoreNuoviMessaggi').text(data.data.nuovi_messaggi);
                    $('.contatoreGsp').text(data.data.gsp); 
                }  
                else {
                    //fail
                    $('.contatoreNuoviMessaggi').text('');
                    $('.contatoreGsp').text(''); 
                }
                //aggiorno width user panel layer
                setTimeout(function() {
                    var widthDropdown = 1+$('.gs-top-bar-section > ul').width();
                    $('.gs-top-bar-section > ul > li > ul.dropdown').css('min-width',widthDropdown);  
                }, 250 );
            };
            var objRequest = {};
            objRequest.type="contatoriUtente"; 
            $('.contatoreNuoviMessaggi').html('<i class="fa fa-refresh fa-spin"></i>');
            $('.contatoreGsp').html('<i class="fa fa-refresh fa-spin"></i>'); 
            $.userAjaxCall(objRequest , aggiornaContatori );
            
            if ( scope && scope == "schedaGioco" ) {
                //ricavo valutazione personale del gioco e se presente in mygames
                $.userAjaxCall("infoUtenteSchedaGioco");
            } 
        }
    },
    trackUserNavigation: function() {
        var scope = $('body').data('scope');
        if ( scope && scope == "schedaGioco" && $.userLogin ) {
            //aggiorno l'ultima scheda gioco vista dall'utente
            $.userAjaxCall("setUltimoGiocoVisto");
        }
    },
    userAjaxCall:function(type , callbackFunction , fallbackFunction ) { 
        //Todo trasformare type in params e passare tutte le vars li dentro => come fatto x votaTrucco/votaCommenti/invioNews/imgNews..
        if (typeof type == "object")  {
            var settings = $.extend({
                // These are the defaults.
                clearQueue: false,//se devo bloccare le eccessive chiamate ajax metto true
                type: "", //qui va la fn (la ACTION) da passare alla url
                method:"POST", 
                trucco: "", //qui va cod_trucco ( se serve ) da passare alla url
                prodotto: "", //qui va cod_prodotto ( se serve ) da passare alla url
                recensione: "", //qui va cod_recensione ( se serve ) da passare alla url
                anteprima: "", //qui va cod_anteprima ( se serve ) da passare alla url
                news: "", //qui va cod_news ( se serve ) da passare alla url
                commento: "", //qui va cod_commento ( se serve ) da passare alla url
                mittente: "", //qui va mittente ( se serve ) da passare alla url
                tipo: "", //qui va tipo ( tipo ricerca - se serve - x elenco giochi ) da passare alla url
                serialized: "", //usato x passare stringa di inputs ( es. vars get di una form )
                serializedPostData: "", //usato x passare stringa post ( es. vars post di una form )
                url: URL_API+"local_json.php", //url di default .. che si puo sostituire all'occorrenza
                contentType: 'application/x-www-form-urlencoded; charset=UTF-8',//false x upload
                cache: true ,//false x upload
                processData: true//false x upload
            }, type ); 
            
            if(settings.serialized) settings.serialized = "&"+settings.serialized;
            
            var url= settings.url +'?fn='+settings.type+'&cod_trucco='+settings.trucco
            +'&cod_commento='+settings.commento+'&cod_prodotto='+settings.prodotto+'&cod_recensione='+settings.recensione
            +'&cod_anteprima='+settings.anteprima+'&cod_news='+settings.news+'&mittente='+settings.mittente
            +'&tipo='+settings.tipo+settings.serialized ;
            if($.userAjaxCallRequest[settings.type] && (settings.clearQueue == "true" || settings.clearQueue ===  true )) {
                $.userAjaxCallRequest[settings.type].abort();
            }
            $.userAjaxCallRequest[settings.type]= $.ajax({
                dataType: "json",
                url: url,
                data: settings.serializedPostData,
                type: settings.method,
                contentType: settings.contentType,
                cache: settings.cache ,
                processData: settings.processData,
                success: function(data) { 
                    if (typeof callbackFunction == "function") callbackFunction(data);
                }
            }); 
        }
        else if(type=="setUltimoGiocoVisto"){
            var gioco = $('body').data('cod-prodotto');
            var url= URL_API+'local_json.php?fn='+type+'&cod_prodotto='+gioco ;
            $.ajax({
                dataType: "json",
                url: url,
                data: null,
                type: "POST",
                success: function(data) { }
            });
        }
        else if(type=="deleteMyGame"){
            var gioco = $('body').data('cod-prodotto');
            var url= URL_API+'local_json.php?fn='+type+'&cod_prodotto='+gioco ;
            $.ajax({
                dataType: "json",
                url: url,
                data: null,
                type: "POST",
                success: function(data) { }
            });
        }
        else if(type=="addMyGame"){
            var gioco = $('body').data('cod-prodotto');
            var url= URL_API+'local_json.php?fn='+type+'&cod_prodotto='+gioco ;
            $.ajax({
                dataType: "json",
                url: url,
                data: null,
                type: "POST",
                success: function(data) { }
            });
        }
        else if(type=="votaGioco"){
            var gioco = $('body').data('cod-prodotto');
            var voto = $("#valutazioneStarRating").text();
            var url= URL_API+'local_json.php?fn='+type+'&cod_prodotto='+gioco+'&voto='+voto ;
            $.ajax({
                dataType: "json",
                url: url,
                data: null,
                type: "POST",
                success: function(data) { 
                    if($.facebookLogin) {
                        FB.ui({
                            method: 'feed',
                            link: $("#starRating").data("share-url"),
                            caption: $("#starRating").data("share-name"),
                             picture: $("#starRating").data("share-img"), 
                             description: 'Ho appena espresso una valutazione di questo gioco sul sito GameStorm.it, ho votato '+
                            voto+' su 10. Tu quale voto gli daresti ? vieni a votare sul sito !'
                        }, function(response){}); 
                    }
                    else if($.googlePlusLogin){
                        var options = {
                            contenturl: $("#starRating").data("share-url"),
                            clientid: clientid_,
                            cookiepolicy: 'single_host_origin',
                            prefilltext: 'Ho appena espresso una valutazione di questo gioco sul sito GameStorm.it, ho votato '+
                            voto+' su 10. Tu quale voto gli daresti ? vieni a votare sul sito !', 
                            calltoactionlabel: 'VIEW',
                            calltoactionurl: $("#starRating").data("share-url"),
                            callback: function(result) {
                                setTimeout(function(){
                                    $('#valutazioneStarRating').trigger("click");
                                },2500);
                            }
                        };
                        // Call the render method when appropriate within your app to display
                        // the button.
                        gapi.interactivepost.render('valutazioneStarRating', options); 
                    }
                    /*
                    var options = {
                contenturl: 'http://www.gamestorm.it', 
                clientid: clientid_,
                cookiepolicy: 'single_host_origin',
                prefilltext: 'Hai happy friday', 
                calltoactionlabel: 'INVITE',
                calltoactionurl: 'http://www.gamestorm.it'
              };
              // Call the render method when appropriate within your app to display
              // the button.
              gapi.interactivepost.render('starRating', options);
                    */
                }
            });
        }
        else if(type=="infoUtenteSchedaGioco"){
            //ricavo voto gioco e se presente in mygames
            //$('.contatoreNuoviMessaggi').html('<i class="fa fa-refresh fa-spin"></i>');
            //$('.contatoreGsp').html('<i class="fa fa-refresh fa-spin"></i>'); 
            var gioco = $('body').data('cod-prodotto');
            var url= URL_API+'local_json.php?fn='+type +'&cod_prodotto='+gioco ;
            $.ajax({
                dataType: "json",
                url: url,
                data: null,
                type: "POST",
                success: function(data) { 
                    //"mygames": visualizzo aggiungiGioco in questo caso mostrando il div contenitore 
                    if(data.data.mygames && data.data.mygames === true ) {  
                        $('#aggiungiGioco').html('<i class="fa fa-minus"></i> Rimuovi dai miei giochi</a>'); 
                        $('#aggiungiGioco').data('action','rimuovi');
                    }
                    else {
                        $('#aggiungiGioco').html('<i class="fa fa-plus"></i> Aggiungi ai miei giochi</a>');
                        $('#aggiungiGioco').data('action','aggiungi');
                    }
                    $('#aggiungiGioco').parent().hide(); 
                    $('#aggiungiGioco').parent().removeClass('hide'); 
                    $('#aggiungiGioco').parent().fadeIn(); 
                    //valutazione...
                    if(data.data.valutazione && parseInt(data.data.valutazione)>0) { 
                        $('#valutazioneStarRating').hide(); 
                        $('#valutazioneStarRating').html(parseInt(data.data.valutazione));
                        $('#valutazioneStarRating').fadeIn(); 
                        //star rating 
                        if($('#starRating').length>0) { $('#starRating').starRating({ refreshVoto: parseInt(data.data.valutazione)}); }
                    }
                    else { 
                        //nulla..lascio ND stampato da php
                    }
                }
            });
        }
        else if(type=="rankUtente"){
            var utente = $('#rankUtente').data('cod-utente');
            var url= URL_API+'local_json.php?fn='+type +'&id_utente_request='+utente ;
            $.ajax({
                dataType: "json",
                url: url,
                data: null,
                type: "GET",
                success: function(data) { 
                    if(data) {
                        $('#schedaRank').html(data);
                        $('#schedaRank').hide();
                        $('#rankUtente .loading').hide();
                        $('#schedaRank').removeClass('hide');
                        $('#schedaRank').fadeIn();
                        if($('#reclutaAmici').length > 0 ) { 
                            $('#reclutaAmici').hide(); 
                            $('#reclutaAmici').removeClass('hide'); 
                            $('#reclutaAmici').fadeIn(); 
                        }
                        //applico effetto counter su rank
                        $('#counterRank').gsCountTo({
                            from: 0, 
                            to: parseFloat($('#counterRank').data('value')),
                            onComplete: function() { $(this).append(' %');}
                        });
                        //ricarico script x tooltip su eventuali icone del rank
                        $(document).foundation('tooltip', 'reflow');
                    }
                    else {
                    
                    }
                }
            });
        }
        else if(type=="login"){
            var url=URL_API+'jsonRegistrazione.php?fn=login' ;
            $('form#gsLogin .gs-box-login').addClass('loading');
            $.ajax({
                    dataType: "json",
                    url: url,
                    data: $('form#gsLogin').serialize(),
                    type: "POST",
                    success: function(data) { 
                        if(data.data === true  ) {
                            //login ok - reload pagina da fare
                            console.log(data.data);
                            $('form#gsLogin #loginError').parent().removeClass('error'); 
                            $('form#gsLogin #loginError').fadeOut();
                            //in origine era redirect to URL_INDEX;
                            //win.loc.href -> check su tutte le casistiche....da fare
                            window.location = window.location.href.split('#')[0];    
                        } 
                        else {  
                            $('form#gsLogin .gs-box-login').removeClass('loading');
                            $('form#gsLogin #loginError').parent().addClass('error');   
                            $('form#gsLogin #loginError').fadeIn();   
                        }
                        //$('div.loading').hide();
                        //$('div.success').fadeIn();
                        //load toolbar utente loggato ? 
                    }
                });
        }
        else if(type=="loginMobile"){
            $('form#gsLoginMobile .gs-box-login').addClass('loading');
            var url=URL_API+'jsonRegistrazione.php?fn=login' ;
            $.ajax({
                    dataType: "json",
                    url: url,
                    data: $('form#gsLoginMobile').serialize(),
                    type: "POST",
                    success: function(data) { 
                        if(data.data === true  ) {
                            //login ok - reload pagina da fare
                            console.log(data.data);
                            $('form#gsLoginMobile #loginError').parent().removeClass('error'); 
                            $('form#gsLoginMobile #loginError').fadeOut(); 
                            //in origine era redirect to URL_INDEX;
                            //win.loc.href -> check su tutte le casistiche....da fare
                            window.location = window.location.href.split('#')[0];    
                        } 
                        else {  
                            $('form#gsLoginMobile .gs-box-login').removeClass('loading');
                            $('form#gsLoginMobile #loginError').parent().addClass('error');   
                            $('form#gsLoginMobile #loginError').fadeIn();   
                        }
                        //$('div.loading').hide();
                        //$('div.success').fadeIn();
                        //load toolbar utente loggato ? 
                    }
                });
        }
        else if(type=="loginFb"){
            $('form#gsLogin .gs-box-login').addClass('loading');
            $('form#gsLoginMobile .gs-box-login').addClass('loading');
            FB.api('/me', {"fields":"id,name,email,first_name,last_name"}, function(response) {
                console.log('FB - Successful login for: ' + response.name); 
                var url=URL_API+'jsonRegistrazione.php?fn=loginFb' ;
                $.ajax({
                    dataType: "json",
                    url: url,
                    data: response,
                    type: "POST",
                    success: function(data) { 
                        if(data.data === true  ) {
                            //login ok - reload pagina da fare 
                            $('form#gsLogin #loginError').parent().removeClass('error'); 
                            $('form#gsLogin #loginError').fadeOut();  
                            $('form#gsLoginMobile #loginError').parent().removeClass('error'); 
                            $('form#gsLoginMobile #loginError').fadeOut();  
                            //in origine era redirect to URL_INDEX;
                            //win.loc.href -> check su tutte le casistiche....da fare
                            window.location = window.location.href.split('#')[0];    
                        } 
                        else {  
                            $('form#gsLogin .gs-box-login').removeClass('loading');
                            $('form#gsLoginMobile .gs-box-login').removeClass('loading');
                            $('form#gsLogin #loginError').parent().addClass('error');   
                            $('form#gsLogin #loginError').fadeIn(); 
                            $('form#gsLoginMobile #loginError').parent().addClass('error');   
                            $('form#gsLoginMobile #loginError').fadeIn();   
                        } 
                    }
                });
            });
        }
        else if(type=="loginGplus"){
            $('form#gsLogin .gs-box-login').addClass('loading');
            $('form#gsLoginMobile .gs-box-login').addClass('loading');
            //nuova chiamata api v2
            if (auth2.isSignedIn.get()) {
                var profile = auth2.currentUser.get().getBasicProfile();
                var response = {};
                var url=URL_API+'jsonRegistrazione.php?fn=loginGplus' ;
                response.email= profile.getEmail();
                response.id= profile.getId();
                response.name= profile.getName(); 
                console.log('G+ - Successful login for: ' + profile.getName() + ' - ' + profile.getEmail()); 
                $.ajax({
                    dataType: "json",
                    url: url,
                    data: response,
                    type: "POST",
                    success: function(data) { 
                        if(data.data === true  ) {
                            //login ok - reload pagina  
                            $('form#gsLogin #loginError').parent().removeClass('error'); 
                            $('form#gsLogin #loginError').fadeOut();  
                            $('form#gsLoginMobile #loginError').parent().removeClass('error'); 
                            $('form#gsLoginMobile #loginError').fadeOut(); 
                            //writeAddActivity();
                            //in origine era redirect to URL_INDEX;
                            //win.loc.href -> check su tutte le casistiche....da fare
                            window.location = window.location.href.split('#')[0];       
                        } 
                        else {  
                            //login a gamestorm fallito..todo riportare msg in div error  
                            $('form#gsLogin .gs-box-login').removeClass('loading');
                            $('form#gsLoginMobile .gs-box-login').removeClass('loading');
                            $('#signinButton').show();
                            $('form#gsLogin #loginError').parent().addClass('error');   
                            $('form#gsLogin #loginError').fadeIn();   
                            $('form#gsLoginMobile #loginError').parent().addClass('error');   
                            $('form#gsLoginMobile #loginError').fadeIn();   
                        } 
                    }
                });  
            }
            else {  
                //login a gamestorm fallito..todo riportare msg in div error  
                $('form#gsLogin .gs-box-login').removeClass('loading');
                $('form#gsLoginMobile .gs-box-login').removeClass('loading');
                $('#signinButton').show();
                $('form#gsLogin #loginError').parent().addClass('error');   
                $('form#gsLogin #loginError').fadeIn();   
                $('form#gsLoginMobile #loginError').parent().addClass('error');   
                $('form#gsLoginMobile #loginError').fadeIn(); 
            } 
            //versione old
            /*gapi.client.load('oauth2', 'v2', function() {
                var request = gapi.auth2.BasicProfile
                request.execute(function(response){ 
                    console.log('G+ - Successful login for: ' + response.name); 
                    var url=URL_API+'jsonRegistrazione.php?fn=loginGplus' ;
                    $.ajax({
                        dataType: "json",
                        url: url,
                        data: response,
                        type: "POST",
                        success: function(data) { 
                            if(data.data === true  ) {
                                //login ok - reload pagina  
                                $('form#gsLogin #loginError').parent().removeClass('error'); 
                                $('form#gsLogin #loginError').fadeOut();  
                                $('form#gsLoginMobile #loginError').parent().removeClass('error'); 
                                $('form#gsLoginMobile #loginError').fadeOut(); 
                                //writeAddActivity();
                                //in origine era redirect to URL_INDEX;
                                //win.loc.href -> check su tutte le casistiche....da fare
                                window.location = window.location.href.split('#')[0];       
                            } 
                            else {  
                                //login a gamestorm fallito..todo riportare msg in div error  
                                $('form#gsLogin .gs-box-login').removeClass('loading');
                                $('form#gsLoginMobile .gs-box-login').removeClass('loading');
                                $('#signinButton').show();
                                $('form#gsLogin #loginError').parent().addClass('error');   
                                $('form#gsLogin #loginError').fadeIn();   
                                $('form#gsLoginMobile #loginError').parent().addClass('error');   
                                $('form#gsLoginMobile #loginError').fadeIn();   
                            } 
                        }
                    });
                
                });
            });*/
        }
        else if(type=="logout"){
            //attivo div loading in box user
            $('.gs-box-user').addClass('loading');
            var logout = function() {
                var url=URL_API+'jsonRegistrazione.php?fn=logout' ;
                $.ajax({
                    dataType: "json",
                    url: url,
                    data: {},
                    type: "POST",
                    success: function(data) { 
                        if(data.data === true  ) {
                            //logout ok - redirect in hp
                            window.location = URL_INDEX;
                        } 
                        else {    
                            //logout fallito ?
                            $('.gs-box-user').removeClass('loading');
                        }
                    }
                });
            };
            //disconnetto gplus e facebook via api (e gli passo funzione x disconnettersi da gamestorm )
            if($.googlePlusLogin) { disconnectUserGplus(logout); }
            else if($.facebookLogin) { disconnectUserFB(logout); }
            else {
                //non sono connesso con i social..logout standard
                logout();
            }
        }
    }
});