function getUrlVars() {
    var vars = {};
    var elm =window.location.href;
    
    if (typeof GS_LINK_PARAMETERS != "undefined") {
        elm =GS_LINK_PARAMETERS.replace(/&amp;/g, '&');
    }
    
    var parts = elm.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) { vars[key] = value; });
    return vars;
}

var ACTIVE_ROUTING = GS_ACTIVE_ROUTING; //true o false settata in <head>
var offset= parseInt(getUrlVars()["pagina"]);
var offsetAjaxSearch= parseInt(getUrlVars()["pagina"]);
var npiattaforma= getUrlVars()["npiattaforma"]; 
var ncategoria= getUrlVars()["ncategoria"]; 
if(GS_ACTIVE_ROUTING == 1 && ncategoria) {
    ncategoria = ncategoria.toLowerCase();
}
var ncategoria_recensioni= getUrlVars()["ncategoria_recensioni"]; 
var autore_recensioni= getUrlVars()["COD_AUTORE"]; 
var lettera= getUrlVars()["LETTERA"] ;
var parola_chiave= getUrlVars()["PAROLA"]; //usata in ricerca trucchi e ricerca generale
var tipo= getUrlVars()["tipo"];//"ricevuti"; e ricerca generale
var popupCambioMail= getUrlVars()["changeMail"];//"ricevuti";
var popupCancellaUtente= getUrlVars()["deleteUser"];//"ricevuti"; 
var popupCambioPassword= getUrlVars()["changePassword"];//"ricevuti";
var destinatario= getUrlVars()["destinatario"];//x caricare form invio messaggio privato 
var mittente= "";//x elencoMessaggi;
var URL_WEB = "https://www.gamestorm.it/";
var URL_API = "https://www.gamestorm.it/gs_api/";
var URL_INDEX = "https://www.gamestorm.it/index.php";
var URL_CERCA = "https://www.gamestorm.it/cerca.php";
var URL_REGISTRAZIONE = "https://www.gamestorm.it/registrazione.php";
if(GS_ACTIVE_ROUTING == 1) {
    URL_REGISTRAZIONE = "https://www.gamestorm.it/registrazione";
}
var URL_UTENTE_SCHEDA = "https://www.gamestorm.it/utenti/scheda_utente.php";
var URL_UTENTE_GUESTBOOK = "https://www.gamestorm.it/utenti/guestbook.php";
var URL_UTENTE_MESSAGGI = "https://www.gamestorm.it/utenti/messaggi.php";
var URL_TRUCCHI_PDF = "https://www.gamestorm.it/sistema/PDF/trucchi.php";

var collapseMode = '';
var boxNavMode = '';
var animation= 'fadeIn';
if( typeof(userLogged)=="undefined") $.userLogin =0;
else $.userLogin = userLogged ; //variabile creata in head / function.php - scriptInclusion...
$.googlePlusLogin = 0 ;    
$.facebookLogin = 0 ;        
$.modeGs = '';     
$.grid = ''; 
$.myScrollHoriz = {};
$.smoothLinkLimit = 135 ;
$.extend({
    //funzione generale dove inizializzo tutto
    setGs: function() { 
        //applico collapse row principale x mobile ( onload e onresize tramite breakpoint ) - DEPRECATO
        //$.setGsMobileCollapse();
        //script per menu consolle della topbar mobile / desktop / desktop onsticky ( onload e onresize e onscroll tramite breakpoint  )
        $.setGsTopBarMainMenu();
        //se loggato faccio chiamata x aggiornare contatore gps e nuovi messaggi ( + voto gioco e info mygame in schedaGioco )
        $.getUserInfo();
        //se loggato e navigo faccio chiamata x salvare azioni utente ( es. ultimo gioco visto )
        $.trackUserNavigation();
        //funzione per upload avatar: la chiamo se loggato e se è presente il pulsante aggiornaAvatar in sezione utente o da topbar menu
        if($('#gsModalUpload').length>0 && $.userLogin) { $.setGsFormAvatar(); }
        //validazione ajax x form di invio messaggi privati
        if($('form#gsFormMessaggio').length>0) { $.setGsFormMessaggio(); }
        //validazione ajax x form di invio messaggi guestbook utente
        if($('form#gsFormGuestbook').length>0) { $.setGsFormGuestbook(); }
        //validazione e registrazione ajax x form di registrazione
        if($('form#gsSubscribe').length>0) { $.setGsSubscribeForm(); }
        //validazione e recupero password ajax 
        if($('form#gsRecuperoPassword').length>0) { $.setGsRecuperoPasswordForm(); }
        //validazione e ajax x form di riscatto codici
        if($('form#gsRiscattoCodici').length>0) { $.setGsFormRiscattoCodici(); }
        //validazione x form profilo utente + azioni pulsanti upd / delete
        if($('form#gsFormProfilo').length>0 && $.userLogin) { 
            $.setGsFormProfilo(); 
            $('#aggiornaProfilo').on('click',function(){ 
                $('#aggiornaProfilo').hide();
                $('#schedaProfilo').hide();
                $('#formProfilo').fadeIn();
                $('#cancellaProfilo').fadeIn();
            });
            $('#cancellaProfilo').on('click',function(){ 
                var ut = $('form#gsFormProfilo').data('cod-utente');
                $('#gsModalConfirm').foundation('reveal','open'); 
                $('#gsModalConfirm').data('cod-utente',ut);  
            });
            $('#gsModalConfirm .annulla').on('click', function() {
                $('#gsModalConfirm').foundation('reveal', 'close');
            });
            $('#gsModalConfirm .procedi').on('click', function() {
                $('#formProfilo').hide('fast');
                $('div.loading').removeClass('hide'); 
                $('div.loading').fadeIn(); 
                var utente = $('#gsModalConfirm').data('cod-utente'); 
                var url=URL_API+'jsonGetUserData.php' ;
                url += '?fn=cancellaProfilo&id_utente_request='+utente;
                $('#gsModalConfirm').foundation('reveal', 'close');  
                var obj = {}; 
                obj.cod_utente = utente;
                $.ajax({
                    dataType: "json",
                    url: url,
                    data: obj,
                    type: "POST",
                    error: function(){},
                    success: function(data) {  
                        $('div.loading').hide();
                        if(data.data === true ) { 
                            //mostro messaggio di conferma modifica + tasto aggiorna
                            $('div.success').html('<i class="fa fa-check"></i><br>Profilo cancellato correttamente'); 
                            $('div.success').fadeIn(); 
                            $('#cancellaProfilo').hide();
                            $('#aggiornaProfilo').fadeIn(); 
                            // svuoto form
                            //$('#gsFormProfilo')[0].reset(); 
                            $(':input','form#gsFormProfilo').not(':button, :submit, :reset, :hidden')
                            .val("").removeAttr('checked').removeAttr('selected');
                            //aggiorno scheda profilo con i nuovi dati 
                            $('#nome-label-scheda').text("");
                            $('#sesso-label-scheda').text("");
                            $('#localita-label-scheda').text("");
                            $('#data-nascita-label-scheda').text("");
                            $('#descrizione-label-scheda').text("");
                            $('#motto-label-scheda').text("");
                            $('#hobby-label-scheda').text("");
                            $('#professione-label-scheda').text("");
                            $('#studi-label-scheda').text(""); 
                            $('#psn-label-scheda').text(""); 
                            $('#psn-container').hide();     
                            $('#xboxlive-label-scheda').text(""); 
                            $('#xboxlive-container').hide(); 
                            $('#wiicode-label-scheda').text("");
                            $('#wiicode-container').hide();
                            $('#nintendo_network_id-label-scheda').text("");
                            $('#nintendo_network_id-container').hide();
                            $('#folding_home-label-scheda').text("");
                            $('#folding_home-container').hide();
                            
                            //ritorno a form di compilazione ..... e nascondo tasto cancella
                            setTimeout(function(){
                                $('div.success').hide(); 
                                //aggiungo classe hide al pulsante cancella in modo da ignorare i vari fadeIn/Out 
                                $('#cancellaProfilo').addClass('hide');
                                $('#aggiornaProfilo').trigger('click');
                            }, 2000); 
                        }
                        else {
                            $('#formProfilo').show('fast');
                        }
                    }
                }); 
            });
        } 
        //validazione x form top 10 utente + azioni pulsanti
        if($('form#gsFormTop10').length>0 && $.userLogin) { $.setGsFormTop10(); }
        //validazione x form homepage utente + azioni pulsanti upd / delete
        if($('form#gsFormHomepage').length>0 && $.userLogin) { 
            $.setGsFormHomepage(); 
            $('#aggiornaHomepage').on('click',function(){ 
                $('#aggiornaHomepage').hide();
                $('#homepageUtente').hide();
                $('#formHomepage').fadeIn();
                $('#cancellaHomepage').fadeIn();
            });
            $('#cancellaHomepage').on('click',function(){ 
                var ut = $('form#gsFormHomepage').data('cod-utente');
                $('#gsModalConfirm').foundation('reveal','open'); 
                $('#gsModalConfirm').data('cod-utente',ut);  
            });
            $('#gsModalConfirm .annulla').on('click', function() {
                $('#gsModalConfirm').foundation('reveal', 'close');
            });
            $('#gsModalConfirm .procedi').on('click', function() {
                $('#formHomepage').hide('fast');
                $('div.loading').removeClass('hide'); 
                $('div.loading').fadeIn(); 
                var utente = $('#gsModalConfirm').data('cod-utente'); 
                var url=URL_API+'jsonGetUserData.php' ;
                url += '?fn=cancellaHomepage';
                $('#gsModalConfirm').foundation('reveal', 'close');  
                var obj = {}; 
                obj.cod_utente = utente;
                $.ajax({
                    dataType: "json",
                    url: url,
                    data: obj,
                    type: "POST",
                    error: function(){},
                    success: function(data) {  
                        $('div.loading').hide();
                        if(data.data === true ) { 
                            //mostro messaggio di conferma modifica + tasto aggiorna
                            $('div.success').html('<i class="fa fa-check"></i><br>Home page cancellata correttamente'); 
                            $('div.success').fadeIn(); 
                            $('#cancellaHomepage').hide();
                            $('#aggiornaHomepage').fadeIn(); 
                            // svuoto form 
                            CKEDITOR.instances.descrizione.setData("");
                            //aggiorno scheda con i nuovi dati 
                            $('#homepageUtente').html(""); 
                            
                            //ritorno a form di compilazione ..... e nascondo tasto cancella
                            setTimeout(function(){
                                $('div.success').hide(); 
                                //aggiungo classe hide al pulsante cancella in modo da ignorare i vari fadeIn/Out 
                                $('#cancellaHomepage').addClass('hide');
                                $('#aggiornaHomepage').trigger('click');
                            }, 2000); 
                        }
                        else {
                            $('#formHomepage').show('fast');
                        }
                    }
                }); 
            });
        } 
        //validazione x form cambio dati di accesso
        if($('form#gsFormDatiAccesso').length>0 && $.userLogin) { $.setGsFormDatiAccesso(); }
        //validazione x form cambio email
        if($('form#gsFormDatiMail').length>0 && $.userLogin) { $.setGsFormDatiMail(); } 
        //validazione x form cancellazione utente
        if($('form#gsFormCancellaUtente').length>0 && $.userLogin) { $.setGsFormCancellaUtente(); } 
        //ajax load rank utente ed eventuale ricalcolo + recluta amici
        if($('#rankUtente').length>0) { 
            if($('#reclutaAmici').length > 0 ) { 
                $('#reclutaAmici').on('click',function(){
                    $('#divReclutaAmici').hide();
                    $('#divReclutaAmici').removeClass('hide');
                    $('#calcoloRank').hide();
                    $('#calcoloRank').removeClass('hide');
                    $('#schedaRank').hide();
                    $(this).hide();
                    $('#calcoloRank').fadeIn();
                    $('#divReclutaAmici').fadeIn();
                });
                $('#calcoloRank').on('click',function(){ 
                    $(this).hide();
                    $('#divReclutaAmici').hide();
                    $('#reclutaAmici').fadeIn();
                    $('#schedaRank').fadeIn();
                });
            }
            $.userAjaxCall("rankUtente"); 
        }  
        //form console utente
        if($('form#gsFormConsole').length>0 && $.userLogin) { 
            $.setGsFormConsole(); 
            $('#aggiornaConsole').on('click',function(){ 
                $('#aggiornaConsole').hide();
                $('#schedaConsole').hide();
                $('#elencoConsole').hide();
                $('#introConsole').hide();
                $('#elencoConsole').removeClass('hide');
                $('#introConsole').removeClass('hide');
                $('#elencoConsole').fadeIn();
                $('#introConsole').fadeIn();
                $('#formConsole').fadeIn(); 
            });
            $('#elencoConsole').on('click',function(){ 
                $('form#gsFormConsole .annulla').trigger('click');
            }); 
        } 
        //form ricerca sito
        if($('form#gsSearch').length>0) { $.setGsSearchForm(); }
        if($('form#gsSearchMobile').length>0) { $.setGsSearchFormMobile(); }
        if($('form#gsFormSearchFull').length>0) { $.setGsFormSearchFull(); }
        //comunity ricerca utenti
        if($('form#gsFormCercaUtenti').length>0) { $.setGsFormCercaUtenti(); }
        //validazione e login ajax
        if($('form#gsLogin').length>0) { $.setGsLoginForm(); }
        if($('form#gsLoginMobile').length>0) { $.setGsLoginFormMobile(); }
        //ricerca x parola chiave ajax 
        if($('form#gsFormCercaTrucchi').length>0) { $.setGsFormCercaTrucchi(); }
        //ricerca x parola chiave ajax 
        if($('form#gsFormInvioTrucchi').length>0) { $.setGsFormInvioTrucchi(); }
        //form invio commenti scheda gioco news ecc
        if($('form#gsFormCommenti').length>0) { $.setGsFormCommenti(); }
        //form invio news : solo moderatori e special user
        if($('form#inviaNews').length>0 && $.userLogin) { $.setGsFormInviaNews(); } 
        //funzione per fare logout
        if($('#gsLogout').length>0) { $.setGsLogout(); }
        //slider hp
        if($('#gs-slider').length>0) { $.setGsSliderDisplay(); }
        //elenco filtri di ricerca mobile / desktop ( onload e onresize tramite breakpoint )
        if($('#gs-filter-alphanum').length>0 || $('#gs-filter-categorie').length>0 || $('#gs-filter-consolle').length>0) {
            $.setGsHorizontalScroll(); 
        }
        //valutazione giochi 
        if($('#starRating').length>0) { $('#starRating').starRating(); } 
        //valutazione trucchi / commenti 
        if($('.textRating').length>0) { $('.textRating').textRating(); }
        //aggiungi gioco tra i preferiti / my games
        if($('#aggiungiGioco').length>0) { $('#aggiungiGioco').addGame(); } 
        //smoothlink ( tasto torna su )
        if($('.smoothLink').length>0) { $('.smoothLink').smoothLink(); } 
        //submenu della pagina ( usato x mostrare sezioni in scheda gioco , scheda recensione / articolo ) mobile / desktop ( onload e onresize tramite breakpoint )
        if($('.gs-nav.gs-box').length>0) { $('.gs-nav.gs-box').gsBoxNav(); } 
        //funzione x rendere box "sticky" in varie pagine ( es. dove ci sta infinite scrll x mostrare sempre banners )
        if($('.gsFixedOnScroll').length>0) { $('.gsFixedOnScroll').gsFixedOnScroll(); } 
        //NON USATA - mostra testo completo del box onmousehover ( top giochi ) 
        if($('.gs-box-content-chart-expanded').length>0) { $('.gs-box-content-chart-expanded').gsToggleContentBox('chart'); }
        //elenco contenuti vari ( infinite scroll ) 
        if($('.onScreen').length>0 && $('#elencoMessaggi').length>0) { 
            //imposto chiamate ajax al click inviati /ricevuti
            $('#messaggiInviati').on('click',function(){
                tipo = "inviati";
                offset =0;
                mittente = "";
                $(this).addClass('active');
                $('#elencoMessaggi').data('attivo',1);
                $('#elencoMessaggi').data('offset',0);
                $('#messaggiRicevuti, #nuovoMessaggio').removeClass('active');
                $('.gs-box-content-messaggi.js-generated').remove(); 
                $('.loadMore.js-generated').off('click').remove(); 
                $('#formMessaggio').hide(); 
                $('div.success').hide();
                $('.endOnScreen').show(); 
                $.appendOnScreen('elencoMessaggi',1); 
            });
            $('#messaggiRicevuti').on('click',function(){
                tipo = "ricevuti"; 
                offset =0;
                mittente = "";
                $(this).addClass('active');
                $('#elencoMessaggi').data('attivo',1);
                $('#elencoMessaggi').data('offset',0);
                $('#messaggiInviati, #nuovoMessaggio').removeClass('active');
                $('.gs-box-content-messaggi.js-generated').remove(); 
                $('.loadMore.js-generated').off('click').remove(); 
                $('#formMessaggio').hide(); 
                $('div.success').hide();
                $('.endOnScreen').show(); 
                $.appendOnScreen('elencoMessaggi',1); 
            });
            $('#nuovoMessaggio').on('click',function(){
                $(this).addClass('active');
                $('#messaggiInviati, #messaggiRicevuti').removeClass('active');
                $('#formMessaggio').fadeIn(); 
                $('#gsFormMessaggio')[0].reset();
                $('.gs-box-content-messaggi.js-generated').fadeOut(); 
                $('div.success').hide();
                $('.endOnScreen').hide();
                $('.loading').hide();
            });
            //imposto tasti messaggi leggi cancella ecc
            $(document).on('click','.content-details .filter',function(){
                tipo = "ricevuti"; 
                mittente = $(this).parent().parent().parent().data('cod-mittente');
                offset =0; 
                $('#elencoMessaggi').data('attivo',1);
                $('#elencoMessaggi').data('offset',0);
                $('.gs-box-content-messaggi.js-generated').remove(); 
                $('.loadMore.js-generated').off('click').remove(); 
                $.appendOnScreen('elencoMessaggi',1); 
            });
            $(document).on('click','.content-details .no-filter',function(){
                $('#messaggiRicevuti').trigger('click');
            });
            $(document).on('click','.content-action.messaggio.leggi , .content-text.messaggio.leggi',function(){
                $('.content-messaggio').hide();
                $(this).parent().find('.content-messaggio').slideDown();
                
                if($(this).parent().hasClass('nuovo')) {
                    $(this).parent().removeClass('nuovo'); 
                    var messaggio = $(this).parent().data('cod-messaggio');
                    var url=URL_API+'jsonGetUserData.php?fn=leggiMessaggio' ;
                    var obj = {}; 
                    obj.cod_messaggio = messaggio;
                    $.ajax({
                        dataType: "json",
                        url: url,
                        data: obj,
                        type: "POST",
                        error: function(){},
                        success: function(data) {  }
                    }); 
                }
            });
            $(document).on('click','.content-action.messaggio.cancella',function(){
                var messaggio = $(this).parent().data('cod-messaggio');
                $('#gsModalConfirm').foundation('reveal','open'); 
                $('#gsModalConfirm').data('cod-messaggio',messaggio); 
                $('#gsModalConfirm').data('messaggio-inviato',false); 
            }); 
            $(document).on('click','.content-action.messaggio.cancellaInviato',function(){
                var messaggio = $(this).parent().data('cod-messaggio');
                $('#gsModalConfirm').foundation('reveal','open'); 
                $('#gsModalConfirm').data('cod-messaggio',messaggio); 
                $('#gsModalConfirm').data('messaggio-inviato',true); 
            }); 
            $(document).on('click','.content-action.messaggio.reply',function(){
                $('#nuovoMessaggio').trigger('click');
                $('.smoothLink').trigger('click');
                $('#gsFormMessaggio #utente-label').val(
                    $(this).parent().find('.content-text.messaggio .content-details a').first().text()
                );
                $('#gsFormMessaggio #oggetto-label').val('Re: ' + $(this).parent().find('.content-text.messaggio h4').text());
                $('#gsFormMessaggio #descrizione-label').val(
                    'Re: ' + 
                    $(this).parent().find('.content-messaggio p').text().substr(0,40)+ 
                    '...\n'
                );
                $('#gsFormMessaggio #utente-label').trigger('change');//fixbug validazione ajax che non parte al primo submit
            });
            //popup di conferma
            $('#gsModalConfirm .annulla').on('click', function() {
                $('#gsModalConfirm').foundation('reveal', 'close');
            });
            $('#gsModalConfirm .procedi').on('click', function() {
                var messaggio = $('#gsModalConfirm').data('cod-messaggio');
                var inviato = $('#gsModalConfirm').data('messaggio-inviato');
                var url=URL_API+'jsonGetUserData.php' ;
                if(inviato===true) url += '?fn=cancellaMessaggioInviato';
                else url += '?fn=cancellaMessaggio';
                $('#gsModalConfirm').foundation('reveal', 'close');  
                var obj = {}; 
                obj.cod_messaggio = messaggio;
                $.ajax({
                    dataType: "json",
                    url: url,
                    data: obj,
                    type: "POST",
                    error: function(){},
                    success: function(data) {  
                        $(".gs-box-content-messaggi[data-cod-messaggio='"+messaggio+"']").fadeOut();
                        return false;
                    }
                }); 
            });
            //caricamento pagina con visualizzazione diretta della form di invio messaggi 
            // (es. arrivo da click su "scrivi a" presente in box messaggi e commenti giochi vari =
            if(destinatario ) {
                $('#nuovoMessaggio').trigger('click');
                //compilo form con destinatario con leggero delay ( il click pulisce la form ! )
                $('.smoothLink').trigger('click');
                $('#gsFormMessaggio #utente-label').val(decodeURI(destinatario));
                $('#gsFormMessaggio #utente-label').trigger('change');//fixbug validazione ajax che non parte al primo submit
            }
            else {
                //load messaggi
                $.appendOnScreen('elencoMessaggi',1); 
            }
        }
        if($('.onScreen').length>0 && $('#elencoMessaggiGuestbook').length>0) { 
            $('#messaggiRicevuti').on('click',function(){
                offset =0;
                $(this).addClass('active');
                $('#nuovoMessaggio').removeClass('active');
                $('.gs-box-content.js-generated').remove(); 
                $('#formGuestbook').hide(); 
                $('div.success').hide();
                $('.endOnScreen').show(); 
                $.appendOnScreen('elencoMessaggiGuestbook',1); 
            });
            $('#nuovoMessaggio').on('click',function(){
                if($.userLogin) {
                    $(this).addClass('active'); 
                    $('#messaggiRicevuti').removeClass('active');
                    $('#formGuestbook').fadeIn(); 
                    $('#formGuestbook').removeClass('hide'); 
                    $('#gsFormGuestbook')[0].reset();
                    $('.gs-box-content.js-generated').fadeOut(); 
                    $('div.success').hide();
                    $('.endOnScreen').hide();
                } 
                else {
                    //gs alert x login o registrazione.
                    $('#gsModalLoginAlert').foundation('reveal','open');  
                    var target = $(this).parents('.gs-box-white');
                    if (target.length) {
                        $('html,body').animate({
                            scrollTop: target.offset().top
                        }, 1000); 
                    }
                }
            });
            $('#disattivaGuestbook').on('click',function(){ 
                var utente = $('#elencoMessaggiGuestbook').data('cod-utente');
                $('#gsModalConfirm p').text('Disattivare il guestbook personale ?'); 
                $('#gsModalConfirm').foundation('reveal','open');  
                $('#gsModalConfirm').data('cod-messaggio','');
                $('#gsModalConfirm').data('cod-utente',utente); 
                $('#gsModalConfirm').data('azione','disattiva'); 
            });
            $('#attivaGuestbook').on('click',function(){
                var utente = $('#elencoMessaggiGuestbook').data('cod-utente');
                $('#gsModalConfirm p').text('Attivare il guestbook personale ?'); 
                $('#gsModalConfirm').foundation('reveal','open'); 
                $('#gsModalConfirm').data('cod-messaggio','');
                $('#gsModalConfirm').data('cod-utente',utente); 
                $('#gsModalConfirm').data('azione','attiva'); 
            });  
            $(document).on('click','.content-action.commenti.cancella',function(){
                var messaggio = $(this).parent().data('cod-messaggio');
                var utente = $('#elencoMessaggiGuestbook').data('cod-utente');
                $('#gsModalConfirm p').text('Cancellare il messaggio selezionato ?'); 
                $('#gsModalConfirm').foundation('reveal','open'); 
                $('#gsModalConfirm').data('cod-messaggio',messaggio);  
                $('#gsModalConfirm').data('cod-utente',utente); 
                $('#gsModalConfirm').data('azione','cancella'); 
            });
            $('#gsModalConfirm .annulla').on('click', function() {
                $('#gsModalConfirm').foundation('reveal', 'close');
            });
            $('#gsModalConfirm .procedi').on('click', function() {
                var utente = $('#gsModalConfirm').data('cod-utente');
                var azione = $('#gsModalConfirm').data('azione'); 
                var messaggio = $('#gsModalConfirm').data('cod-messaggio'); 
                var url=URL_API+'jsonGetUserData.php?fn=' ;
                if(azione=='attiva') url += 'attivaGuestbook';
                else if(azione=='disattiva')  url += 'disattivaGuestbook';
                else if(azione=='cancella')  url += 'cancellaMessaggioGuestbook';
                if(utente) url += '&id_utente_request='+utente; 
                
                $('#gsModalConfirm').foundation('reveal', 'close');  
                var obj = {}; 
                if(azione=='cancella') {
                    obj.cod_messaggio = messaggio;
                }
                $.ajax({
                    dataType: "json",
                    url: url,
                    data: obj,
                    type: "POST",
                    error: function(){},
                    success: function(data) {  
                        if(azione=='cancella') {
                            $(".gs-box-content[data-cod-messaggio='"+messaggio+"']").fadeOut();
                        }
                        else if(azione=='attiva') {
                            $('#elencoMessaggiGuestbook').data('attivo',1); 
                            $('#guestbookErrorLabel').hide();
                            $('#attivaGuestbook').hide();
                            $('#disattivaGuestbook').fadeIn();
                            $('#disattivaGuestbook').removeClass('hide'); 
                            $('#nuovoMessaggio').fadeIn();
                            $('#nuovoMessaggio').removeClass('hide'); 
                            $('#messaggiRicevuti').fadeIn();
                            $('#messaggiRicevuti').removeClass('hide'); 
                            $('#messaggiRicevuti').trigger('click');
                        }
                        else if(azione=='disattiva') {
                            $('#elencoMessaggiGuestbook').data('attivo','false'); 
                            $('#disattivaGuestbook').hide();
                            $('#nuovoMessaggio').hide();
                            $('#messaggiRicevuti').hide();
                            $('#attivaGuestbook').fadeIn();
                            $('#attivaGuestbook').removeClass('hide'); 
                            $('#guestbookErrorLabel').fadeIn();
                            $('#guestbookErrorLabel').removeClass('hide'); 
                            $('#messaggiRicevuti').trigger('click');
                        }
                        return false;
                    }
                }); 
            }); 
            $('div.loading').hide();
            $.appendOnScreen('elencoMessaggiGuestbook', null , offset); 
        }
        if($('.onScreen').length>0 && $('#elencoGiochiUtente').length>0) { $.appendOnScreen('elencoGiochiUtente', null , offset ); } 
        if($('.onScreen').length>0 && $('#elencoGiochi').length>0) { $.appendOnScreen('elencoGiochi', null , offset ); } 
        if($('.onScreen').length>0 && $('#elencoGiochiCategoria').length>0) { $.appendOnScreen('elencoGiochiCategoria', null , offset ); } 
        if($('.onScreen').length>0 && $('#elencoGiochiFuturi').length>0) { $.appendOnScreen('elencoGiochiFuturi', null , offset); } 
        if($('.onScreen').length>0 && $('#elencoGiochiAttuali').length>0) { $.appendOnScreen('elencoGiochiAttuali', null , offset); } 
        if($('.onScreen').length>0 && $('#elencoNews').length>0) { $.appendOnScreen('elencoNews', null , offset); } 
        if($('.onScreen').length>0 && $('#elencoRecensioni').length>0) { $.appendOnScreen('elencoRecensioni', null , offset); } 
        if($('.onScreen').length>0 && $('#elencoTrucchi').length>0) { 
            $('#scaricaTrucchi').on('click',function(){
                if($.userLogin) {
                    $(this).addClass('active'); 
                    $('#nuovoTrucco').removeClass('active');  
                    var prodotto = $('body').data('cod-prodotto');
                    //URL_TRUCCHI_PDF
                    var win = window.open(URL_TRUCCHI_PDF+'?COD_PRODOTTO='+prodotto, '_blank');
                    win.focus();
                } 
                else {
                    //gs alert x login o registrazione.
                    $('#gsModalLoginAlert').foundation('reveal','open');  
                    var target = $(this).parents('.gs-box-white');
                    if (target.length) {
                        $('html,body').animate({
                            scrollTop: target.offset().top
                        }, 1000); 
                    }
                }
            });
            $('#nuovoTrucco').on('click',function(){
                if($.userLogin) {  
                    $('#introTrucchi').hide();
                    $('#elencoTrucchi').hide();
                    $('.gs-box-content.js-generated').hide();
                    $('div.loading').hide();
                    $('.endOnScreen').hide();
                    
                    $('#formInvioTrucchi').hide(); 
                    $('#introInvioTrucchi').hide(); 
                    $('#mostraTrucchi').hide(); 
                    $('#formInvioTrucchi').removeClass('hide'); 
                    $('#introInvioTrucchi').removeClass('hide'); 
                    $('#mostraTrucchi').removeClass('hide'); 
                    $('#formInvioTrucchi').fadeIn(); 
                    $('#introInvioTrucchi').fadeIn(); 
                    $('#mostraTrucchi').fadeIn();   
                } 
                else {
                    //gs alert x login o registrazione.
                    $('#gsModalLoginAlert').foundation('reveal','open');  
                    var target = $(this).parents('.gs-box-white');
                    if (target.length) {
                        $('html,body').animate({
                            scrollTop: target.offset().top
                        }, 1000); 
                    }
                }
            });
            parola_chiave=""; //resetto non si sa mai
            $.appendOnScreen('elencoTrucchi', null , offset); 
        } 
        if($('.onScreen').length>0 && $('#elencoUtenti').length>0) { 
            //parola_chiave=""; //resetto non si sa mai -> cn active routing la uso
            $.appendOnScreen('elencoUtenti', null , offset); 
        } 
        if($('.onScreen').length>0 && $('#elencoArticoli').length>0) { $.appendOnScreen('elencoArticoli'); } 
        if($('.onScreen').length>0 && $('#elencoVideo').length>0) { $.appendOnScreen('elencoVideo'); } 
        //form ricerca giochi full 
        if($('.onScreen').length>0 && $('#gsSearchResultFull').length>0 ) {
            if(parola_chiave) {
                $.appendOnScreen('gsSearchResultFull',1); 
            }
            else {
                $('#gsSearchResultFull').siblings('.loading').hide(); 
            }
        }
        //azioni sui commenti - nuovo commento - elenco
        if($('#elencoCommenti').length>0) {  
            //tasti aggiungi commento / mostra elenco
            $('#elencoCommentiBtn').on('click',function(){
                $(this).addClass('active');
                $(this).hide();
                $('#introNuovoCommento').hide();  
                $('#nuovoCommento').removeClass('active');
                $('#formCommenti').hide();  
                $('#introCommenti').fadeIn();
                //$('#elencoCommenti').fadeIn();
                $('.gs-box-content.js-generated').fadeIn(); 
                $('.endOnScreen').fadeIn();        
                $('#nuovoCommento').fadeIn('active');
            });
            $('#nuovoCommento').on('click',function(){
                if($.userLogin) {   
                    $(this).addClass('active');
                    $(this).hide();
                    $('#introCommenti').hide();  
                    $('#elencoCommentiBtn').removeClass('active');
                    $('form#gsFormCommenti')[0].reset();
                    //$('#elencoCommenti').hide();
                    $('.gs-box-content.js-generated').hide();
                    $('div.loading').hide();
                    $('.endOnScreen').hide();
                    
                    $('#introNuovoCommento').hide();  
                    $('#formCommenti').hide();  
                    $('#elencoCommentiBtn').hide();
                    $('#introNuovoCommento').removeClass('hide');  
                    $('#formCommenti').removeClass('hide');   
                    $('#elencoCommentiBtn').removeClass('hide');
                    $('#formCommenti').fadeIn();  
                    $('#introNuovoCommento').fadeIn();
                    $('#elencoCommentiBtn').fadeIn('active');
                } 
                else {
                    //gs alert x login o registrazione.
                    setTimeout(function(){$('.gs-top-bar .toggle-gstopbar-login').trigger('click');},50);
                    /*$('#gsModalLoginAlert').foundation('reveal','open');  
                    var target = $(this).parents('.gs-box-white');
                    if (target.length) {
                        $('html,body').animate({
                            scrollTop: target.offset().top
                        }, 1000); 
                    }*/
                }
            });
            //confirm js x segnalazione abuso
            $('#gsModalConfirm .annulla').on('click', function() {
                $('#gsModalConfirm').foundation('reveal', 'close');
            });
            $('#gsModalConfirm .procedi').on('click', function() {  
                var utente = $('#gsModalConfirm').data('cod-utente'); 
                var url=URL_API+'jsonGetUserData.php' ;
                var obj = {}; 
                $('#gsModalConfirm').foundation('reveal', 'close');  
                if($('#gsModalConfirm').data('azione') == "segnala" ) {
                    url += '?fn=segnalaAbuso';
                    obj.cod_commento =$('#gsModalConfirm').data('cod-commento');
                    obj.cod_prodotto =$('#gsModalConfirm').data('cod-prodotto');
                    obj.cod_recensione =$('#gsModalConfirm').data('cod-recensione');
                    obj.cod_anteprima =$('#gsModalConfirm').data('cod-anteprima');
                    obj.cod_news =$('#gsModalConfirm').data('cod-news');
                }
                $.ajax({
                    dataType: "json",
                    url: url,
                    data: obj,
                    type: "POST",
                    error: function(){},
                    success: function(data) { 
                        //dopo segnalazione nascondo tasto
                        if($('#gsModalConfirm').data('azione') == "segnala" ) { 
                            $('.content-text.commenti').find('[data-cod-commento='+obj.cod_commento+'] .fa-exclamation-triangle').fadeOut();
                        }
                    }
                }); 
            });
            //elenco commenti 
            if($('#elencoCommenti').hasClass('onScreen')) {
                $.appendOnScreen('elencoCommenti'); 
            }
            else {
                $('#elencoCommenti .loading').hide();
            }
        } 
        if($('.onScreen').length>0 && $('#elencoImmagini').length>0) { 
            animation="bounceIn"; 
            $.appendOnScreen('elencoImmagini');
        } 
        if($('.onScreen').length>0 && $('#elencoCover').length>0) {  
            animation="fadeIn"; 
            $.appendOnScreen('elencoCover', null , offset);
            $.grid = $('.mosaic').gsMosaic(); 
        } 
        //applico effetto lazy su immagini della pagina
        $.setLazy();
        //eventi x gs clearing - galleria immagini (da mettere magari direttamente in gsclrear.gs )     
        $(document.body).on("open_gsclearing", function(event) {
            //console.log('open to do: show immediatly loading and "modal"');
            //console.info(event);
        }); 
        $(document.body).on("opened_gsclearing", function(event) {
            //todo : event loading on click thumbs ( when clearing is open ) 
            //console.info(event);
            var target = $(event.target).find('img').attr('src');
            var container= $("a[href='"+target+"'].th").parents('.gs-box-content-gallery');
            if(container.length > 0) {
                container.addClass('gsclearing-on');
                //se è ultima immagine faccio chiamata x caricarne altre e aggiorno elenco ( e mosaic x cover ) e lazy
                if(container.parents('.onScreen').length>0) {
                    var loadMore = $("a[href='"+target+"'].th").parent().is(':last-child'); 
                    var onScreenId= $("a[href='"+target+"'].th").parents('.onScreen').attr('id');
                    if(loadMore === true ) {
                        var objRequest = {};
                        objRequest.url=URL_API+'local_json.php';
                        if(onScreenId=="elencoCover"){
                            objRequest.type=onScreenId;
                            if(lettera === undefined ) lettera="";
                            if(npiattaforma === undefined ) npiattaforma="";
                            objRequest.serialized='npiattaforma='+npiattaforma+
                            '&LETTERA='+lettera+'&pagina=' +offset;
                        }
                        else {
                            var cod_prodotto = $('#'+onScreenId).data('cod-prodotto');
                            objRequest.type='schedaGioco';
                            objRequest.serialized='sezione=foto'+
                            '&COD_PRODOTTO='+ cod_prodotto+'&pagina=' +offset;
                        }

                        $.userAjaxCall(objRequest , function(data){
                            $(container).append(data); 
                            $(document).foundation('gsclearing', 'reflow');
                            $.setLazy('update'); 
                            if(onScreenId=="elencoCover"){
                                //trick x aggiornare mosaic alla eventuale chiusura della gallery
                                $.grid.refreshOnNextEvent(); 
                            }
                        });
                        offset++;
                    }
                    if(onScreenId=="elencoCover"){
                        //quando apro carico tutte le img ancora lazy x ottimizzare navigazione
                        var img_container =container.children('li.content-image');
                        if(img_container.length > 0 ) {
                            img_container.find('img.bttrlazyloading').not('.animated').trigger('bttrlazyloading.load');
                            img_container.addClass('mosaic-calculated');
                        }
                        /*var img_container =container.children('li.content-image:not(.mosaic-calculated)');
                        if(img_container.length > 0 ) {
                            img_container.find('img.bttrlazyloading').not('.animated').trigger('bttrlazyloading.load');
                            //forzo opacity visibile...con classe che dopo viene resettata da gsMosiac 
                            img_container.addClass('mosaic-calculated');
                        } */
                    }
                    else {
                        //quando apro carico tutte le img ancora lazy x ottimizzare navigazione
                        var img_container =container.children('li.content-image');
                        if(img_container.length > 0 ) {
                            img_container.find('img.bttrlazyloading').not('.animated').trigger('bttrlazyloading.load');
                        }
                    } 
                }
            }
        }); 
        $(document.body).on("closed_gsclearing", function(event) {
                //rimetto mia classe gs-content-box-gallery
                //console.log('closed ok loading hide');
                $('.gs-box-content-gallery').removeClass('gsclearing-on');
        }); 
        //eventi popup login / registrazione quando clicco su qualcosa dove devo essere loggato 
        if(!$.userLogin) {
            $('#gsModalLoginAlert .accedi').on('click',function(e){ 
                $('#gsModalLoginAlert').foundation('reveal','close');
                //destktop no sticky genera bug grafico... ok mobile .. ok desktop sticky
                setTimeout(function(){$('.gs-top-bar .toggle-gstopbar-login').trigger('click');},50);
            });
            $('#gsModalLoginAlert .registrati').on('click',function(e){ 
                window.location = URL_REGISTRAZIONE;
            });  
        }
        //mostro popup cambio mail / cancellazione utente..se c'è richiesta..
        if((popupCambioMail && ( popupCambioMail =="true" || popupCambioMail =="false")) || 
           (popupCancellaUtente && ( popupCancellaUtente =="true" || popupCancellaUtente =="false")) || 
           (popupCambioPassword && ( popupCambioPassword =="true" || popupCambioPassword =="false"))) {
            $('#gsModalLoginAlert').foundation('reveal','open'); 
        }
        //pagina utility - link x aggiungere gamestorm ai motori di ricerca - funziona solo su firefox e ie
        if($('body').data('scope') && $('body').data('scope')=='utility' ){
            $('#add').on('click',function(){
                window.external.AddSearchProvider('/plugin/opensearch.xml');
            });
        }
        //link footer utenti online - reveal box
        if($('#utentiOnline').length >0) { $.setGsRevealUtentiOnline(); }
        //link footer e pagina chat x utenti in chat - popup brutto
        if($('#chatPopup').length >0) {
            $('#chatPopup').on('click',function() {
                // the size of the popup window
                var width = 280;
                var height = 400;
                // the x,y position of the popup window
                // NOTE: this formula will auto-center the popup on the screen
                var y = 0;
                var x = 0;
                var url = 'https://www.gamestorm.it/CHAT/info.php?session_inst=1';
                var options = 'width=' + width + ',height=' + height + ',top=' + y + ',left=' + x + ',resizable=yes' + ',scrollbars=1';
                window.open( url, 'info', options );
            });
        }
    }, 
    /*setGsMobileCollapse: function() {
        if($.breakpointMain() && collapseMode != 'small' ) {
            //console.log('collapse version');
            collapseMode = 'small'; 
            $('div.gs-row:first-child').each(function(){
                $(this).addClass("collapse");
            });   
        }
        else if(!$.breakpointMain() && collapseMode == 'small') {
            //console.log('no collapse version');
            collapseMode ='';
            $('div.gs-row:first-child').each(function(){
                $(this).removeClass("collapse");
            });   
        }
        $( window ).on( "resize", function( event ) {
            $.setGsMobileCollapse();
            $( this ).off( event );
        });
    },*/
    setGsTopBarMainMenu: function() {  
        $('.gs-mainlist > li').on('click',function(event){
            var li = this , s2 ="";
            
            $('.gs-mainlist > li').removeClass('active'); 
            $(li).addClass('active');
            var settings = Foundation.utils.data_options($(li));
            
            if( ( $(li).attr('data-options') !== undefined && settings.consolle!='home' ) && ( $(li).attr('data-options') !== undefined  && ($.breakpointMain() || $('body').hasClass('f-gstopbar-fixed')))) { 
                //viewport mobile eo sticky no load url onclick -> mostro submenu
                event.preventDefault();
            }
            else {
                return;
            }
            if(settings.consolle) {
                if(!$.breakpointMain()) { 
                    $('.gs-top-bar-section-sub').fadeOut('fast');  
                }
                $('.gs-sublist').each(function() {
                    s2 = Foundation.utils.data_options($(this));
                    if(settings.consolle==s2.consolle) {
                        //console.log( 'no ' + s2.consolle );
                    }
                    else { 
                        $('#'+s2.consolle+'_sublist').css('display','none');  
                        $('#'+s2.consolle+'_sublist').addClass('hide');
                        //console.log( 'hide'+ s2.consolle ); 
                    }
           
                });
                //$('.gs-sublist').css('display','none');
                $('#'+settings.consolle+'_sublist').css('display','table'); 
                $('#'+settings.consolle+'_sublist').removeClass('hide');
                //console.log( 'show' + settings.consolle );
                if(!$.breakpointMain()) { 
                    $('.gs-top-bar-section-sub').fadeIn('fast');   
                }
            }
        });
    },
    breakpointMain : function () {
        //false..sono in modalita medium up.. true sono mobile
        return !matchMedia(Foundation.media_queries['medium']).matches;
    },
    breakpointSlider : function () {
        //false..sono in modalita medium up.. true sono mobile
        return !matchMedia(Foundation.media_queries['gsslider']).matches;
    },
    getViewportOrientation : function () {
        if(window.innerHeight > window.innerWidth) return "portrait";
        else return "landscape";
    },
    showSlide: function( ) {
        var settings = {};
        settings = Foundation.utils.data_options($('#gs-slider > .active'));
        $('#gs-slider').addClass('ready');
        //data-options="sequence:hdp;effect:fsf;speed:fns;delay:0"
        if(!$.breakpointSlider() && $(settings).length > 0 ) {//controllo non funziona.....
            var sequence = "all"; 
            var effect = "fade";
            var speed = "medium";  
            var orderSequence = ['all'];
            var effectSequence = ['none'];
            var speedSequence = ['medium']; 
            //h = titolo (H1 , H2 , H3 ...)
            //d = lista link ( DL , DD )
            //p = testo paragrafo ( P )
            //sequence = all => non controllo order list
            //se un elemento (hpd) non è presente nella slide o è "vuoto" non lo considero nella sequenza
            if(settings.sequence && settings.sequence!='all' && settings.sequence !== undefined && settings.sequence !== null) {
                sequence = settings.sequence.split('-');
                //console.log(sequence);
                for ( x = 0; x < sequence.length; x++){
                    if( sequence[x] == 'h3' || sequence[x] == 'dl' || sequence[x] == 'p'){
                        orderSequence[x]=sequence[x];
                    }
                }
            }
            if(settings.effect && settings.effect!='none' && settings.effect!='fade' 
               && settings.effect!='slide' && settings.effect!='show' && settings.effect !== undefined && settings.effect !== null) {
                effect = settings.effect.split('-');
                //console.log(sequence);
                for ( x = 0; x < effect.length; x++){
                    if( effect[x] == 'fade' || effect[x] == 'slide' || effect[x] == 'show'|| effect[x] == 'none'){
                        effectSequence[x]=effect[x];
                    }
                }
            }
            else if(settings.effect=='none' || settings.effect=='fade' || settings.effect=='slide' || settings.effect=='show'){
                effectSequence[0]=settings.effect; 
            }
            if(settings.speed && settings.speed!='slow' && settings.speed!='medium' && settings.speed!='fast' 
               && settings.speed!='none' && settings.speed !== undefined && settings.speed !== null) {
                speed = settings.speed.split('-');
                //console.log(sequence);
                for ( x = 0; x < speed.length; x++){
                    if( speed[x] == 'fast' || speed[x] == 'medium' || speed[x] == 'slow'|| speed[x] == 'none'){
                        speedSequence[x]=speed[x];
                    }
                }
            }
            else if(settings.speed=='none' || settings.speed=='slow' || settings.speed=='medium' || settings.speed!='fast'){
                speedSequence[0]=settings.speed; 
            }
            //console.info('slide ' + orbit.slide_number + ': 
            //sequenza '+orderSequence +' - effetto '+effectSequence + ' - velocità '+speedSequence);
            for ( x = 0; x < orderSequence.length; x++){
                if( orderSequence[x] == 'all'){
                    if( effectSequence[x] == 'fade'){
                        $('#gs-slider > .active > a > *').fadeIn(speedSequence[x]);
                        $('#gs-slider > .active > dl').fadeIn(speedSequence[x]);
                     //       console.log('all fade');
                    }
                    else if( effectSequence[x] == 'slide'){
                        $('#gs-slider > .active > a > *').slideDown(speedSequence[x]);
                        $('#gs-slider > .active > dl').slideDown(speedSequence[x]);
                       //     console.log('all slide');
                    }
                    else if( effectSequence[x] == 'show'){
                        $('#gs-slider > .active > a > *').show(speedSequence[x]);
                        $('#gs-slider > .active > dl').show(speedSequence[x]);
                         //   console.log('all show');
                    }
                    else if( effectSequence[x] == 'show'){
                        $('#gs-slider > .active > a > *').show(speedSequence[x]);
                        $('#gs-slider > .active > dl').show(speedSequence[x]);
                           // console.log('all show');
                    }
                    else {
                        $('#gs-slider > .active > a > *').fadeIn('fast');
                        $('#gs-slider > .active > dl').fadeIn('fast');
                //            console.log('all default');
                    }
                }
                else {
                    if( effectSequence[x] == 'fade'){
                        $('#gs-slider > .active > a > '+orderSequence[x]).delay(x*500).fadeIn(speedSequence[x]);
                        $('#gs-slider > .active > '+orderSequence[x]).delay(x*500).fadeIn(speedSequence[x]);
                        //      console.log(orderSequence[x]+'  fade');
                    }
                    else if( effectSequence[x] == 'slide'){
                        $('#gs-slider > .active > a > '+orderSequence[x]).delay(x*500).slideDown(speedSequence[x]);
                        $('#gs-slider > .active > '+orderSequence[x]).delay(x*500).slideDown(speedSequence[x]);
                        //    console.log(orderSequence[x]+'  slide');
                    }
                    else if( effectSequence[x] == 'show'){
                        $('#gs-slider > .active > a > '+orderSequence[x]).delay(x*500).show(speedSequence[x]);
                        $('#gs-slider > .active > '+orderSequence[x]).delay(x*500).show(speedSequence[x]);
                        // console.log(orderSequence[x]+'  show');
                    }
                    else {
                        $('#gs-slider > .active > a > '+orderSequence[x]).fadeIn('fast');
                        $('#gs-slider > .active > '+orderSequence[x]).fadeIn('fast');
                        //    console.log(orderSequence[x]+'  default');
                    }
                }
            }
        }
        else { 
            $('#gs-slider > .active > a > p').css({
                'bottom':$('#gs-slider > .active > dl').height()+'px',
                'opacity':'0',
                'display':'block'
            });
            $('#gs-slider > .active > a > p').animate({'opacity':1},1000);
            $('#gs-slider > .active > a > h3').fadeIn('fast');
            $('#gs-slider > .active > dl').fadeIn('medium');
        }
    },
    hideSlidesNotActive: function() {
        //nascondo testi delle slide nn active 
        if(!$.breakpointSlider()) {
            //medium up
            $('#gs-slider > li:not(.active) > a > *').fadeOut('fast');
            $('#gs-slider > li:not(.active) > dl').fadeOut('fast');
        }
        else { 
            $('#gs-slider > li:not(.active) > a > p').fadeOut('fast');
            $('#gs-slider > li:not(.active) > a > h3').fadeOut('fast');
            $('#gs-slider > li:not(.active) > dl').fadeOut('fast');
        }     
    },
    setGsSliderDisplay: function() {
        //registro mediaquery per controllare effetti js in base a width
        Foundation.utils.register_media('gsslider', 'foundation-mq-gsslider'); //console.log($.breakpointSlider());
        //creo vertical carousel 
        var myScroll = new IScroll('.gs-slider-wrapper', { mouseWheel: true });
        //resetto touch in area vertical carousel
        document.getElementById('gs-slider-wrapper').addEventListener('touchmove', function (e) { e.preventDefault(); }, false);
        //imposto bg deeplinks
        $('#gs-slider > li').each(function(){
            $(".gs-slider-wrapper #gs-slider-carousel-"+$(this).attr("data-orbit-slide")).css("background-image", $(this).css("background-image") ); 
            $(".gs-slider-wrapper #gs-slider-carousel-"+$(this).attr("data-orbit-slide")).css("background-size","cover" ); 
        });
        //mostro la prima slide
        $('.gs-slider-wrapper li:first').addClass("active");
        //nascondo testi delle slide nn active 
        $.hideSlidesNotActive();
        //calcolo altezza ottimale x mobile - default x desktop 
        //mostro la prima slide 
        $.setGsSlidesImagesResponsive();
        $.showSlide();
        $.setGsSliderHeight();
        
        //imposto eventi orbit 
        $("#gs-slider").on("before-slide-change.fndtn.orbit", function(event) {
            //console.info($('#gs-slider > .active').css("background-image"));//la mia slide la trovo con .active
            //nascondo elementi..
            $.hideSlidesNotActive();
            $.setGsSliderHeight();
        });
        $("#gs-slider").on("after-slide-change.fndtn.orbit", function(event, orbit) {
            //console.info("slide " + orbit.slide_number + " of " + orbit.total_slides);
            //console.info($('#gs-slider > .active').html());//la mia slide la trovo con .active
            if(!$.breakpointSlider()) {
                //medium up
                var target='gs-slider-carousel-'+ (1+parseInt(orbit.slide_number)); 
                myScroll.scrollToElement(
                    document.querySelector('.gs-slider-wrapper li:nth-child('+(1+orbit.slide_number)+')'),250,true,true,IScroll.utils.ease.circular
                );
            }
            $('.gs-slider-wrapper li').removeClass("active");
            $('.gs-slider-wrapper li:nth-child('+(1+orbit.slide_number)+')').addClass("active");
            $.showSlide();
            $.setGsSliderHeight();
        });
        //imposto evento su resize della slider x impostare altezza mobile 
        $(window).on("resize",function(){$.setGsSliderHeight('resize'); $.setGsSlidesImagesResponsive();});
    },
    setGsSlidesImagesResponsive: function() {
        if(!$.breakpointSlider()) { 
            //check immagini data-attr
            $('#gs-slider > li').each(function(){
                if($(this).data("image-landscape").length > 0) {
                    $(this).css("background-image","url("+$(this).data("image-landscape")+")");
                }
                else if($(this).data("image-portrait").length > 0) {
                    $(this).css("background-image","url("+$(this).data("image-portrait")+")");
                }
            });
        }
        else if($.breakpointSlider()){  
            //if lanscape... else 
            if(window.innerHeight < window.innerWidth){
                //check immagini data-attr
                $('#gs-slider > li').each(function(){
                    if($(this).data("image-landscape").length > 0) {
                        $(this).css("background-image","url("+$(this).data("image-landscape")+")");
                    }
                    else if($(this).data("image-portrait").length > 0) {
                        $(this).css("background-image","url("+$(this).data("image-portrait")+")");
                    }
                });
            }
            else {
                //check immagini data-attr
                $('#gs-slider > li').each(function(){
                    if($(this).data("image-portrait").length > 0) {
                        $(this).css("background-image","url("+$(this).data("image-portrait")+")");
                    }
                    else if($(this).data("image-landscape").length > 0) {
                        $(this).css("background-image","url("+$(this).data("image-landscape")+")");
                    }
                });
            }
        }
    },
    setGsSliderHeight: function( ){
        if(!$.breakpointSlider()) {
            //medium up - non faccio nulla xke ci pensa orbit on resize
            $('.orbit-container').removeAttr('style'); 
            $('#gs-slider > li.active > a > p').css('bottom','');
            $('#gs-slider > li:not(.active) > a > p').css('display','none');
        }
        else if($.breakpointSlider()){ 
            //mobile - calcolo altezza dopo micro timeout 
            var maxH = 450;
            var h = $('body').height() - $(".first-row").height();
            if(h>maxH) h = maxH;
            h = h - 6;
            $('.orbit-container').css('height',h+'px'); 
            $('#gs-slider').css('height',h+'px'); 
            //upd paragraph position considerando css transition 0.5s
            
            setTimeout(function(){
                $('#gs-slider > .active > a > p').css({
                    'bottom':$('#gs-slider > .active > dl').height()+'px'
                });
            },500);
        }
    },
    setGsHorizontalScroll: function() { 
        //setto width dello scroller -> calcolo widh di ogni child
				function isPassive() {
						var supportsPassiveOption = false;
						try {
								addEventListener("test", null, Object.defineProperty({}, 'passive', {
										get: function () {
												supportsPassiveOption = true;
										}
								}));
						} catch(e) {}
						return supportsPassiveOption;
				}
			
        function setScroll( type ) {
            var w = 1;//0; 
            var filterId = 'gs-filter-' + type;
            var filter = '.filter-' + type;
            var wrapper = '.filter-' + type + '-wrapper';
            var scroller = '.filter-' + type + '-scroller';
            
            $(scroller+' > ul > li').each(function() {
                w = w + parseInt($(this).css('width').replace(/[^-\d\.]/g, ''));
            });
            if(w <= parseInt($('.gs-box-filter').css('width').replace(/[^-\d\.]/g, ''))){
                //$(scroller).css('width','auto');
                if($(filter+'-arrow-right').length>0 && $(filter+'-arrow-left').length>0) {
                    $(filter+'-arrow-right').remove();
                    $(filter+'-arrow-left').remove();
                    $(filter).removeClass('scrollable');
                }
            }
            else { 
                //arrow saranno elementi fissi che mostro sempre senza nasconderli piu.. ideale fare on click event move sx /dx
                $(scroller).css('width',w);
                //imposto arrows
                if($(filter+'-arrow-right').length>0 && $(filter+'-arrow-left').length>0) {}
                else {
                    $(filter).addClass('scrollable');
                    $(filter).append("<div class='filter-"+type+"-arrow-left' >&nbsp;</div>");
                    $(filter).append("<div class='filter-"+type+"-arrow-right' >&nbsp;</div>");
                    $(filter+'-arrow-right').fadeIn();
                    $(filter+'-arrow-left').fadeIn();
                }
                //inizializzo scroll
                //probeType: 1 check onScroll leggero , 2 , 3 maggiore precisione
                ///*probeType: 3,  */
                ///snap true x mappare scroll in modo da poterlo muovere onclick
                $.myScrollHoriz[type] = new IScroll(wrapper,{ 
                    snap: true,eventPassthrough: true,scrollX: true, scrollY: false, mouseWheel: true , preventDefault: false
                });
								document.getElementById(filterId).addEventListener('touchmove', function (e) { e.preventDefault(); }, isPassive() ? { capture: false, passive: false } : false);
								$(filter).find('a').attr('draggable', false);
							
                $(filter+'-arrow-left').off();
                $(filter+'-arrow-left').on('click',function() {
                    $.myScrollHoriz[type].prev();
                });
                $(filter+'-arrow-right').off();
                $(filter+'-arrow-right').on('click',function() {
                    $.myScrollHoriz[type].next();
                });
                //$.myScrollHoriz[type].on('scroll', function () { });
                //$.myScrollHoriz[type].on('scrollStart', function () { });
                //$.myScrollHoriz[type].on('scrollEnd', function () { });
            }
            
            //breakpoint...small ?
            $('.gs-filterlist > li').off();
            //if($.breakpointMain()) {
                $('.gs-filterlist > li').on('click',function(){
                    var li = this ;
                    $('.gs-filterlist > li').removeClass('active');
                    //console.log('click');
                    $(li).addClass('active');

                });
            //}
            $('.gs-filterlist-alphanum > li').off();
            //if($.breakpointMain()) {
                $('.gs-filterlist-alphanum > li').on('click',function(){
                    var li = this ;
                    $('.gs-filterlist-alphanum > li').removeClass('active');
                    //console.log('click');
                    $(li).addClass('active');

                });
            //}
        }
        if(!$.isEmptyObject($.myScrollHoriz)) { 
            $.each($.myScrollHoriz,function(k,v){
                this.refresh();
                this.destroy(); 
            });
        }
        $.myScrollHoriz = {};
        if($('#gs-filter-alphanum').length>0) {
            setScroll( 'alphanum' );
        }
        if($('#gs-filter-consolle').length>0) {
            setScroll( 'consolle' );
        }
        if($('#gs-filter-categorie').length>0) {
            setScroll( 'categorie' );
        }
        $( window ).on( "resize", function( event ) {
            /*Foundation.utils.throttle(function(){*/$.setGsHorizontalScroll();//}, 301);
            $( this ).off( event );
        });
    },
    setLazy: function( update , costumContainer ) { 
        if(animation===null || animation ===undefined || animation ==="") animation='fadeIn';
        if (!costumContainer) costumContainer = window ; 
        $('.bttrlazyloading').bttrlazyloading({
            backgroundcolor: 'none',
            threshold: 150,//200 
            container: costumContainer,
            //placeholder: '',
            animation: animation//'slideInLeft' 
        });
        if(update !== null && update !== undefined) { return false; }
        $.bttrlazyloading.setRanges({
            xs: 640,
            sm: 641,
            md: 992,
            lg: 1200
        }); 
        //x box hp e "vecchi" da sostituire poi con bttrlazyloading
        /*$("img.lazy").show().lazyload({
            failure_limit : 10, //trovo le prime 10 img onwindow e le converto di default.. il resto è onscroll..
            effect : "fadeIn"
        });*/
    }, 
    appendOnScreen: function( type ,startOffset,landingOffset ) { 
        if(offset) offset=1+offset; else if(startOffset) offset= startOffset; else offset= 2;
        if(landingOffset && parseInt(landingOffset)>1) landingOffset=parseInt(landingOffset); else landingOffset = "";
        if(npiattaforma === undefined ) npiattaforma="";
        if(ncategoria === undefined ) ncategoria="";
        if(ncategoria_recensioni === undefined ) ncategoria_recensioni="";
        if(autore_recensioni === undefined ) autore_recensioni="";
        if(lettera === undefined ) lettera="";
        if(tipo === undefined ) tipo="ricevuti";
        if(mittente === undefined ) mittente="";
        if(parola_chiave === undefined ) parola_chiave="";
        
        var url="" , urlPrev = "" , cod_utente = "" , loadMoreButton = false ; 
        var onScreen = $('#'+type+'.onScreen');
        var endOnScreen = onScreen.siblings('div.endOnScreen');
        var loadingOnScreen = onScreen.siblings('div.loading');
         
        if(endOnScreen.length <= 0) endOnScreen =  onScreen.children('div.endOnScreen');
        if(loadingOnScreen.length <= 0) loadingOnScreen =  onScreen.children('div.loading');
        
        $(window).onScreen('remove');
        onScreen.data('offset','');
        
        if(type && type =="elencoCommenti" && offset > 2) {
            //verifico la pagina mostrata x capire se il contatore è ok
            var page = $('#elencoCommenti').data('page');
            if(page !== undefined && page == "0") {
                offset= 2;
            }
        }
        
        if(landingOffset) {
            //sono atterrato a pagina X e devo caricare risultati precedenti da pag 1 a X-1 ( casistica landing in pagina con js attivo )
            if(type && type =="elencoGiochi") {
                urlPrev= URL_API+'local_json.php?fn='+type+
                    '&dbg=&npiattaforma='+npiattaforma+
                    '&LETTERA='+lettera+'&tipo=tutti&pagina=1&paginaLanding=' + landingOffset;
            }  
            else if(type && type =="elencoGiochiUtente") {
                cod_utente = $('#elencoGiochiUtente').data('cod-utente');
                urlPrev= URL_API+'local_json.php?fn='+type+
                    '&dbg=&npiattaforma='+npiattaforma+
                    '&id_utente_request=' +cod_utente+
                    '&LETTERA='+lettera+'&pagina=1&paginaLanding=' + landingOffset;
            } 
            else if(type && type =="elencoTrucchi") {  
                if($('#elencoTrucchi').data('cod-prodotto') === "") {
                    //sezione trucchi generale
                    urlPrev=URL_API+'local_json.php?fn='+type+
                        '&dbg=&npiattaforma='+npiattaforma+
                        '&LETTERA='+lettera+'&tipo=attuali&pagina=1&paginaLanding=' + landingOffset;
                }
                else {
                    //scheda gioco sezione trucchi
                   // urlPrev=URL_API+'local_json.php?fn=schedaGioco'/*+type*/+
                     //   '&dbg=&sezione=trucchi'+
                      //  '&COD_PRODOTTO='+ $('#elencoTrucchi').data('cod-prodotto') +
                      //  '&keyword=' +parola_chiave+
                      //  '&pagina=1&paginaLanding=' + landingOffset;
                }
            }
            else if(type && type =="elencoGiochiCategoria") {
                urlPrev= URL_API+'local_json.php?fn='+type+
                    '&dbg=&npiattaforma='+npiattaforma+
                    '&ncategoria='+ncategoria+
                    '&LETTERA='+lettera+'&tipo=tutti&pagina=1&paginaLanding=' + landingOffset;
            }
            else if(type && type =="elencoGiochiFuturi") {
                urlPrev= URL_API+'local_json.php?fn='+type+
                    '&dbg=&npiattaforma='+npiattaforma+
                    '&LETTERA='+lettera+'&tipo=futuri&pagina=1&paginaLanding=' + landingOffset;
            }
            else if(type && type =="elencoGiochiAttuali") {
                urlPrev= URL_API+'local_json.php?fn='+type+
                    '&dbg=&npiattaforma='+npiattaforma+
                    '&LETTERA='+lettera+'&tipo=attuali&pagina=1&paginaLanding=' + landingOffset;
            }
            else if(type && type =="elencoCover") {
                urlPrev= URL_API+'local_json.php?fn='+type+
                    '&dbg=&npiattaforma='+npiattaforma+
                    '&LETTERA='+lettera+'&pagina=1&paginaLanding=' + landingOffset;
            } 
            else if(type && type =="elencoRecensioni") {
                urlPrev= URL_API+'local_json.php?fn='+type+
                    '&dbg=&npiattaforma='+npiattaforma+
                    '&ncategoria_recensioni='+ncategoria_recensioni+
                    '&COD_AUTORE='+autore_recensioni+
                    '&LETTERA='+lettera+'&pagina=1&paginaLanding=' + landingOffset;
            }
            else if(type && type =="elencoNews") {
                var cod_prodotto = $('#elencoNews').data('cod-prodotto');
                    //se ho cod prodotto sono in scheda gioco altrimenti in archivio news
                    if( $('#elencoNews').data('cod-prodotto') && $('#elencoNews').data('cod-prodotto') !== undefined && $('#elencoNews').data('cod-prodotto')!==null) {
                       // urlPrev=URL_API+'local_json.php?fn=schedaGioco'+
                       // '&dbg=&sezione=news'+
                       // '&COD_PRODOTTO='+ $('#elencoNews').data('cod-prodotto') +
                       // '&pagina=1&paginaLanding=' + landingOffset;
                    }
                    else {
                        urlPrev=URL_API+'local_json.php?fn=elencoNews'/*+type*/+
                        '&dbg=&npiattaforma='+npiattaforma+
                        '&pagina=1&paginaLanding=' + landingOffset; 
                    }
            }
            else if(type && type =="elencoMessaggiGuestbook") {
                cod_utente = $('#elencoMessaggiGuestbook').data('cod-utente');
                urlPrev= URL_API+'local_json.php?fn='+type+ 
                    '&id_utente_request=' +cod_utente+
                    '&pagina=1&paginaLanding=' + landingOffset;
            } 
            else if(type && type =="elencoUtenti") { 
                urlPrev= URL_API+'local_json.php?fn='+type+
                    '&dbg=&LETTERA='+lettera+
                    '&PAROLA='+parola_chiave+'&pagina=1&paginaLanding=' + landingOffset;
            }
            if (urlPrev) { 
                if(onScreen.find('.gs-box-filter').length > 0){
                    $('<div class="loadMorePrev"><button class="gs-button">Carica risultati precedenti</button></div>').insertAfter(onScreen.find('.gs-box-filter'));
                }
                else {
                    onScreen.prepend('<div class="loadMorePrev"><button class="gs-button">Carica risultati precedenti</button></div>');  
                } 
                
                onScreen.find('.loadMorePrev').one('click',function(){ 
                    $('<div class="loading loadMorePrevLoading"><i class="fa fa-refresh fa-spin"></i></div>').insertAfter(this);
                    $(this).remove();
                    
                    $.ajax({
                        dataType: "json",
                        url: urlPrev,
                        data: {},
                        success: function(data) {
                            $('.loadMorePrevLoading').fadeOut('fast',function() {
                                if(data) {
                                    if(type && ( /*type =="elencoImmagini" ||*/ type =="elencoCover")) { 
                                        if( type =="elencoCover") { 
                                            $(".gs-box-content-gallery").prepend(data);  
                                            $.grid.appendData(data);
                                            $.grid.refresh();
                                        }
                                        else {
                                            $(".gs-box-content-gallery").prepend(data); 
                                        } 
                                    }
                                        /*
                                            else if(type && ( type =="elencoMessaggiGuestbook" || type =="elencoMessaggi")) { 
                                                var h = data.replace(/&amp;/g, '&');  
                                                if(onScreen.siblings('.loadMore').length > 0 || onScreen.find('.loadMore').length > 0) {
                                                    //se ho tasto viewMore sono partite troppe chiamate ajax..
                                                    //fixo mettendo data prima di tasto load more
                                                    $(this).siblings('.loadMore').before(h); 
                                                }
                                                else {
                                                    $(this).before(h); 
                                                }
                                            } */
                                    else {
                                        if(onScreen.find('.gs-box-filter').length > 0){
                                            $(data).insertAfter(onScreen.find('.gs-box-filter'));
                                        }
                                        else {
                                            onScreen.prepend(data);  
                                        }
                                    } 
                                    $.setLazy('update'); // Add animations to appended elements
                                    if(type && ( type =="elencoTrucchi" || type =="elencoCommenti")) {
                                        //js valutazione trucchi / commenti
                                        $('.textRating').textRating();
                                    }
                                        /*if(type && type =="elencoVideo") {
                                                //trigger evento custom x reload flowplayer
                                                $(document).trigger('refreshVideo');
                                            }*/

                                }
                                
                                $(this).remove();
                                
                            });  
                        }
                    });
                }); 
            }
        }
        
        endOnScreen.onScreen({ 
            throttle: 50,
            doIn: function() {
                //json get data  
                
                if(type && type =="elencoMessaggi") {
                    loadMoreButton = true;
                    url= URL_API+'local_json.php?fn='+type+
                        '&tipo=' +tipo+
                        '&mittente=' +mittente+
                        '&pagina=' +offset;
                    
                    urlPushState = "?pagina="+offset;
                } 
                else if(type && type =="elencoMessaggiGuestbook") {
                    cod_utente = $('#elencoMessaggiGuestbook').data('cod-utente');
                    url= URL_API+'local_json.php?fn='+type+ 
                        '&id_utente_request=' +cod_utente+
                        '&pagina=' +offset;
                    urlPushState = "?pagina="+offset;
                } 
                else if(type && type =="elencoGiochiUtente") {
                    cod_utente = $('#elencoGiochiUtente').data('cod-utente');
                    url= URL_API+'local_json.php?fn='+type+
                        '&id_utente_request=' +cod_utente+
                        '&npiattaforma='+npiattaforma+
                        '&LETTERA='+lettera+
                        '&pagina=' +offset;
                   
                        urlPushState = "?pagina="+offset;
                        if(lettera) urlPushState +="&LETTERA="+lettera ;
                        if(npiattaforma) urlPushState +="&npiattaforma="+npiattaforma ; 
                } 
                else if(type && type =="elencoGiochi") {
                    loadMoreButton = true;
                    url= URL_API+'local_json.php?fn='+type+
                        '&dbg=&npiattaforma='+npiattaforma+
                        '&LETTERA='+lettera+'&tipo=tutti&pagina=' +offset;
                    
                        urlPushState = "?pagina="+offset;
                        if(lettera) urlPushState +="&LETTERA="+lettera ;
                        if(npiattaforma) urlPushState +="&npiattaforma="+npiattaforma ; 
                } 
                else if(type && type =="elencoCover") {
                    loadMoreButton = true;
                    url= URL_API+'local_json.php?fn='+type+
                        '&dbg=&npiattaforma='+npiattaforma+
                        '&LETTERA='+lettera+'&pagina=' +offset;
                    
                        urlPushState = "?pagina="+offset;
                        if(lettera) urlPushState +="&LETTERA="+lettera ;
                        if(npiattaforma) urlPushState +="&npiattaforma="+npiattaforma ; 
                } 
                else if(type && type =="elencoRecensioni") {
                    loadMoreButton = true;
                    url= URL_API+'local_json.php?fn='+type+
                        '&dbg=&npiattaforma='+npiattaforma+
                        '&ncategoria_recensioni='+ncategoria_recensioni+
                        '&COD_AUTORE='+autore_recensioni+
                        '&LETTERA='+lettera+'&pagina=' +offset;
                    
                        urlPushState = "?pagina="+offset;
                        if(lettera) urlPushState +="&LETTERA="+lettera ;
                        if(autore_recensioni) urlPushState +="&COD_AUTORE="+autore_recensioni ; 
                        if(ncategoria_recensioni) urlPushState +="&ncategoria_recensioni="+ncategoria_recensioni ; 
                        if(npiattaforma) urlPushState +="&npiattaforma="+npiattaforma ; 
                }
                else if(type && type =="elencoGiochiCategoria") {
                    loadMoreButton = true;
                    url= URL_API+'local_json.php?fn='+type+
                        '&dbg=&npiattaforma='+npiattaforma+
                        '&ncategoria='+ncategoria+
                        '&LETTERA='+lettera+'&tipo=tutti&pagina=' +offset;
                    
                        urlPushState = "?pagina="+offset;
                        if(lettera) urlPushState +="&LETTERA="+lettera ;
                        if(ncategoria) urlPushState +="&ncategoria="+ncategoria ; 
                        if(npiattaforma) urlPushState +="&npiattaforma="+npiattaforma ; 
                }
                else if(type && type =="elencoGiochiFuturi") {
                    loadMoreButton = true;
                    url= URL_API+'local_json.php?fn='+type+
                        '&dbg=&npiattaforma='+npiattaforma+
                        '&LETTERA='+lettera+'&tipo=futuri&pagina=' +offset;
                    
                        urlPushState = "?pagina="+offset;
                        if(lettera) urlPushState +="&LETTERA="+lettera ;
                        if(npiattaforma) urlPushState +="&npiattaforma="+npiattaforma ; 
                }
                else if(type && type =="elencoGiochiAttuali") {
                    loadMoreButton = true;
                    url= URL_API+'local_json.php?fn='+type+
                        '&dbg=&npiattaforma='+npiattaforma+
                        '&LETTERA='+lettera+'&tipo=attuali&pagina=' +offset;
                    
                        urlPushState = "?pagina="+offset;
                        if(lettera) urlPushState +="&LETTERA="+lettera ;
                        if(npiattaforma) urlPushState +="&npiattaforma="+npiattaforma ; 
                }
                else if(type && type =="elencoImmagini") {
                    var cod_prodotto = $('#elencoImmagini').data('cod-prodotto');
                    url= URL_API+'local_json.php?fn=schedaGioco'/*+type*/+
                        '&dbg=&sezione=foto'+
                        '&COD_PRODOTTO='+ cod_prodotto +
                        '&pagina=' +offset;
                        //TODO da capire poi con active routing
                        urlPushState = "?sezione=foto&pagina="+offset;
                        //urlPushState = "?pagina="+offset;
                        //if(lettera) urlPushState +="&LETTERA="+lettera ;
                        //if(npiattaforma) urlPushState +="&npiattaforma="+npiattaforma ; 
                }
                else if(type && type =="elencoNews") {
                    loadMoreButton = true;
                    var cod_prodotto = $('#elencoNews').data('cod-prodotto');
                    //se ho cod prodotto sono in scheda gioco altrimenti in archivio news
                    if( cod_prodotto && cod_prodotto !== undefined && cod_prodotto!==null) {
                        url=URL_API+'local_json.php?fn=schedaGioco'/*+type*/+
                        '&dbg=&sezione=news'+
                        '&COD_PRODOTTO='+ cod_prodotto +
                        '&pagina=' +offset;
                        //TODO da capire poi con active routing
                        urlPushState = "?sezione=news&pagina="+offset;
                        //urlPushState = "?pagina="+offset;
                        //if(lettera) urlPushState +="&LETTERA="+lettera ;
                        //if(npiattaforma) urlPushState +="&npiattaforma="+npiattaforma ; 
                    }
                    else {
                        url=URL_API+'local_json.php?fn=elencoNews'/*+type*/+
                        '&dbg=&npiattaforma='+npiattaforma+
                        '&pagina=' +offset;
                        
                        urlPushState = "?pagina="+offset;
                        if(lettera) urlPushState +="&LETTERA="+lettera ;
                        if(npiattaforma) urlPushState +="&npiattaforma="+npiattaforma ; 
                    }
                }
                else if(type && type =="elencoTrucchi") {
                    loadMoreButton = true;
                    var cod_prodotto = $('#elencoTrucchi').data('cod-prodotto');
                    if(cod_prodotto === "") {
                        //sezione trucchi generale
                        url=URL_API+'local_json.php?fn='+type+
                        '&dbg=&npiattaforma='+npiattaforma+
                        '&LETTERA='+lettera+'&tipo=attuali&pagina=' +offset;
                        
                        urlPushState = "?pagina="+offset;
                        if(lettera) urlPushState +="&LETTERA="+lettera ;
                        if(npiattaforma) urlPushState +="&npiattaforma="+npiattaforma ; 
                    }
                    else {
                        //scheda gioco sezione trucchi
                        url=URL_API+'local_json.php?fn=schedaGioco'/*+type*/+
                        '&dbg=&sezione=trucchi'+
                        '&COD_PRODOTTO='+ cod_prodotto +
                        '&keyword=' +parola_chiave+
                        '&pagina=' +offset;
                        //TODO da capire poi con active routing
                        urlPushState = "?sezione=trucchi&pagina="+offset;
                        //if(lettera) urlPushState +="&LETTERA="+lettera ;
                        //if(npiattaforma) urlPushState +="&npiattaforma="+npiattaforma ; 
                    }
                }
                else if(type && type =="elencoArticoli") {
                    var cod_prodotto = $('#elencoArticoli').data('cod-prodotto');
                    url=URL_API+'local_json.php?fn=schedaGioco'/*+type*/+
                        '&dbg=&sezione=articoli'+
                        '&COD_PRODOTTO='+ cod_prodotto +
                        '&pagina=' +offset;
                        //TODO da capire poi con active routing
                        //urlPushState = "?pagina="+offset;
                        //if(lettera) urlPushState +="&LETTERA="+lettera ;
                        //if(npiattaforma) urlPushState +="&npiattaforma="+npiattaforma ; 
                }
                else if(type && type =="elencoCommenti") {
                    loadMoreButton = true;
                    var cod_prodotto = $('#elencoCommenti').data('cod-prodotto');
                    var cod_news = $('#elencoCommenti').data('cod-news');
                    var cod_recensione = $('#elencoCommenti').data('cod-recensione');
                    var cod_anteprima = $('#elencoCommenti').data('cod-anteprima');
                    if(cod_news && cod_news!==undefined && cod_news!==null) {
                        url=URL_API+'local_json.php?fn=schedaNews'/*+type*/+
                        '&dbg=&sezione=commenti'+
                        '&COD_NEWS='+ cod_news +
                        '&pagina=' +offset;
                        //TODO da capire poi con active routing
                        //urlPushState = "?pagina="+offset;
                        //if(lettera) urlPushState +="&LETTERA="+lettera ;
                        //if(npiattaforma) urlPushState +="&npiattaforma="+npiattaforma ; 
                    }
                    else if(cod_prodotto && cod_recensione && cod_recensione!==undefined && cod_recensione!==null) {
                        url=URL_API+'local_json.php?fn=schedaGioco'/*+type*/+
                        '&dbg=&sezione=commenti'+
                        '&COD_RECENSIONE='+ cod_recensione +
                        '&COD_PRODOTTO='+ cod_prodotto +
                        '&pagina=' +offset;
                        //TODO da capire poi con active routing
                        //urlPushState = "?pagina="+offset;
                        //if(lettera) urlPushState +="&LETTERA="+lettera ;
                        //if(npiattaforma) urlPushState +="&npiattaforma="+npiattaforma ; 
                    }
                    else if(cod_prodotto && cod_anteprima && cod_anteprima!==undefined && cod_anteprima!==null) {
                        url=URL_API+'local_json.php?fn=schedaGioco'/*+type*/+
                        '&dbg=&sezione=commenti'+
                        '&COD_ANTEPRIMA='+ cod_anteprima +
                        '&COD_PRODOTTO='+ cod_prodotto +
                        '&pagina=' +offset;
                        //TODO da capire poi con active routing
                        //urlPushState = "?pagina="+offset;
                        //if(lettera) urlPushState +="&LETTERA="+lettera ;
                        //if(npiattaforma) urlPushState +="&npiattaforma="+npiattaforma ; 
                    }
                    else if(cod_prodotto && cod_prodotto!==undefined && cod_prodotto!==null) {
                        url=URL_API+'local_json.php?fn=schedaGioco'/*+type*/+
                        '&dbg=&sezione=commenti'+
                        '&COD_PRODOTTO='+ cod_prodotto +
                        '&pagina=' +offset;
                        //TODO da capire poi con active routing
                        urlPushState = "?sezione=commenti&pagina="+offset;
                        //urlPushState = "?sezione=commenti&COD_PRODOTTO="+cod_prodotto+"&pagina="+offset;
                        //if(lettera) urlPushState +="&LETTERA="+lettera ;
                        //if(npiattaforma) urlPushState +="&npiattaforma="+npiattaforma ; 
                    }
                }
                else if(type && type =="elencoVideo") {
                    var cod_prodotto = $('#elencoVideo').data('cod-prodotto');
                    url=URL_API+'local_json.php?fn=schedaGioco'/*+type*/+
                        '&dbg=&sezione=video'+
                        '&COD_PRODOTTO='+ cod_prodotto +
                        '&pagina=' +offset;
                        //TODO da capire poi con active routing
                        urlPushState = "?sezione=video&pagina="+offset;
                        //urlPushState = "?pagina="+offset;
                        //if(lettera) urlPushState +="&LETTERA="+lettera ;
                        //if(npiattaforma) urlPushState +="&npiattaforma="+npiattaforma ; 
                }
                else if(type && type =="elencoUtenti") { 
                        //scheda gioco sezione trucchi
                        url=URL_API+'local_json.php?fn=elencoUtenti'/*+type*/+ 
                        '&LETTERA='+ lettera +
                        '&PAROLA=' +parola_chiave+
                        '&pagina=' +offset; 
                    
                        urlPushState = "?pagina="+offset;
                        if(parola_chiave) urlPushState +="&PAROLA="+parola_chiave ;
                        if(lettera) urlPushState +="&LETTERA="+lettera ;
                }
                else if(type && type =="gsSearchResultFull") {
                    loadMoreButton = true;
                    if(tipo=="ricevuti")tipo="";
                    url=URL_API+'local_json.php?fn=searchFull'+ 
                        '&npiattaforma='+npiattaforma+
                        '&tipo='+tipo+
                        '&testoRicerca=' +parola_chiave+
                        '&pagina=' +offset;
                        //TODO senza ajax qui non funziona nulla al momento -da capire poi 
                        //urlPushState = "?pagina="+offset;
                        //if(lettera) urlPushState +="&LETTERA="+lettera ;
                        //if(npiattaforma) urlPushState +="&npiattaforma="+npiattaforma ; 
                }
                
                //con active routing e con urlpushState url push è diverso ( cambia solo numero pagina )
                if(GS_ACTIVE_ROUTING == 1 && typeof GS_LINK_PARAMETERS != "undefined" && typeof urlPushState != "undefined" && urlPushState) {
                    
                    ////-if(npiattaforma === undefined ) npiattaforma="";
                    ////if(ncategoria === undefined ) ncategoria="";
                    //--if(ncategoria_recensioni === undefined ) ncategoria_recensioni="";
                    //--if(autore_recensioni === undefined ) autore_recensioni="";
                    ////-if(lettera === undefined ) lettera="";
                    //if(tipo === undefined ) tipo="ricevuti";
                    //if(mittente === undefined ) mittente="";
                    //if(parola_chiave === undefined ) parola_chiave="";
                    if(type =="elencoCommenti") urlPushState= window.location.origin + window.location.pathname;
                    else if(type =="elencoImmagini") urlPushState= window.location.origin + window.location.pathname;
                    else if(type =="elencoVideo") urlPushState= window.location.origin + window.location.pathname;
                    else if(type =="elencoNews" && cod_prodotto ) urlPushState= window.location.origin + window.location.pathname;
                    else if(type =="elencoNews") urlPushState= URL_WEB + 'news';
                    else if(type =="elencoTrucchi" && cod_prodotto === "") urlPushState= URL_WEB + 'trucchi';
                    else if(type =="elencoTrucchi" ) urlPushState= window.location.origin + window.location.pathname;
                    else if(type =="elencoGiochi") urlPushState= URL_WEB + 'giochi';
                    else if(type =="elencoGiochiCategoria") urlPushState= URL_WEB + 'categorie';
                    else if(type =="elencoCover") urlPushState= URL_WEB + 'copertine';
                    else if(type =="elencoRecensioni") urlPushState= URL_WEB + 'recensioni';
                    else if(type =="elencoGiochiFuturi") urlPushState= URL_WEB + 'prossime_uscite';
                    else if(type =="elencoGiochiAttuali") urlPushState= URL_WEB + 'ultimi_usciti';
                    else if(type =="elencoUtenti") urlPushState= URL_WEB + 'users';
                    else if(type =="elencoGiochiUtente" && cod_utente) urlPushState= URL_WEB + 'user/'+cod_utente+'/giochi';
                    else if(type =="elencoMessaggiGuestbook" && cod_utente) urlPushState= URL_WEB + 'user/'+cod_utente+'/guestbook';
                    
					
                    if(type =="elencoImmagini") {
						//immagini scheda gioco
                        urlPushState += "?sezione=foto&pagina="+offset;
					}
                    else if(type =="elencoVideo") {
						//video scheda gioco
                        urlPushState += "?sezione=video&pagina="+offset;
					}
                    else if(type =="elencoCommenti") {
						//commenti scheda gioco
                        urlPushState += "?sezione=commenti&pagina="+offset;
					}
                    else if(type =="elencoTrucchi" && cod_prodotto) {
						//trucchi in scheda gioco
                        urlPushState += "?sezione=trucchi&pagina="+offset;
					}
                    else if(type =="elencoNews" && cod_prodotto) {
						//news in scheda gioco
                        urlPushState += "?sezione=news&pagina="+offset;
					}
                    //casistica elenco utenti
                    else if(type =="elencoUtenti" && parola_chiave && lettera) {
                        urlPushState+= '/' + lettera.toUpperCase()+'/' + offset + "/"+parola_chiave;
                    }
                    else if(type =="elencoUtenti" && parola_chiave) {
                        urlPushState+= '/ALL/' + offset+ "/"+parola_chiave;
                    }
                    else if(type =="elencoUtenti" && lettera) {
                        urlPushState+= '/' + lettera.toUpperCase()+'/' + offset;
                    }
                    else if(type =="elencoUtenti") {
                        urlPushState+=  "";
                    }
                    //guesbook utente
                    else if(type =="elencoMessaggiGuestbook") {
                        urlPushState+= '/' + offset;
                    }
                    //casistica elenco recensioni
                    else if(autore_recensioni) {
                        urlPushState+=  '/ALL/' +ncategoria_recensioni.toUpperCase() +'/ALL/' + offset + '/' + autore_recensioni;
                    }
                    else if(ncategoria_recensioni && npiattaforma && lettera) {
                        urlPushState+= '/' +npiattaforma.toUpperCase() + '/' +ncategoria_recensioni.toUpperCase() +'/' +lettera.toUpperCase() + "/" + offset;
                    }
                    else if(ncategoria_recensioni && npiattaforma ) {
                        urlPushState+= '/' +npiattaforma.toUpperCase() + '/' +ncategoria_recensioni.toUpperCase() +"/ALL/" + offset;
                    }
                    else if(ncategoria_recensioni && lettera) {
                        urlPushState+= '/ALL' + '/' +ncategoria_recensioni.toUpperCase() +'/' +lettera.toUpperCase() + "/" + offset;
                    }
                    else if(ncategoria_recensioni) {
                        urlPushState+= '/ALL' + '/' +ncategoria_recensioni.toUpperCase() +'/ALL/' + offset;
                    }
                    else if(npiattaforma && type =="elencoRecensioni") {
                        urlPushState+=  '/' +npiattaforma.toUpperCase() + '/RECENSIONI/ALL/' + offset;
                    }
                    else if(type =="elencoRecensioni") {
                        urlPushState+=  '/ALL/RECENSIONI/ALL/' + offset;
                    }
        
                    //casistica elenco giochi categoria
                    else if(ncategoria && npiattaforma && lettera) {
                        urlPushState+= '/' +npiattaforma.toUpperCase() + '/' +ncategoria.toUpperCase() +'/' +lettera.toUpperCase() + "/" + offset;
                    }
                    else if(ncategoria && npiattaforma) {
                        urlPushState+= '/' +npiattaforma.toUpperCase() + '/' +ncategoria.toUpperCase() +'/ALL/' + offset;
                    }
                    else if(ncategoria && lettera) {
                        urlPushState+= '/ALL' + '/' +ncategoria.toUpperCase() +'/' +lettera.toUpperCase() + "/" + offset;
                    }
                    else if(ncategoria) {
                        urlPushState+= '/ALL' + '/' +ncategoria.toUpperCase() +'/ALL/' + offset;
                    }
                    
                    //casistica elenco news 
                    else if(type =="elencoNews" && npiattaforma){
                        urlPushState+= '/' +npiattaforma.toUpperCase() + "/" + offset;
                    }
                    else if(type =="elencoNews") {
                        urlPushState+=  '/ALL/' + offset;
                    }
                    
                    //casistiche trucchi giochi cover ecc
                    else if(npiattaforma && lettera) {
                        urlPushState+= '/' +npiattaforma.toUpperCase() +'/' +lettera.toUpperCase() + "/" + offset;
                    }
                    else if(npiattaforma){
                        urlPushState+= '/' +npiattaforma.toUpperCase() + "/ALL/" + offset;
                    }
                    else if(lettera) {
                        urlPushState+= '/ALL/' +lettera.toUpperCase() + "/" + offset;
                    }
                    else {
                        urlPushState+=  '/ALL/ALL/' + offset;
                    }
                }
                
                //in alcuni casi posso disattivare load ajax - es guestbook non attivo
                //oppure in elenchi giochi con loadmore ogni tot caricamenti
                if(onScreen.data('attivo') != "1" && ( loadMoreButton === true || type =="elencoMessaggiGuestbook" )) { 
                    return false;
                }
                //prevent chiamate doppie
                if(loadMoreButton === true && onScreen.data('offset') && offset == onScreen.data('offset')) return false;
                
                loadingOnScreen.fadeIn('fast'); 
                onScreen.data('offset',offset); 
                $.ajax({
                    dataType: "json",
                    url: url,
                    data: {},
                    success: function(data) {
                        offset++;
                        loadingOnScreen.fadeOut('fast',function() {
                            if(data) {
                                //aggiorno url con nuovo stato pagina
                                if(typeof urlPushState != "undefined" && urlPushState) history.pushState(null,null, urlPushState);
                                if(type && ( type =="elencoImmagini" || type =="elencoCover")) { 
                                    if(type && (   type =="elencoCover")) { 
                                        $(".gs-box-content-gallery").append(data);  
                                        $.grid.appendData(data);
                                    }
                                    else {
                                        $(".gs-box-content-gallery").append(data); 
                                    } 
                                }
                                else if(type && ( type =="elencoMessaggiGuestbook" || type =="elencoMessaggi")) { 
                                    var h = data.replace(/&amp;/g, '&');  
                                    if(onScreen.siblings('.loadMore').length > 0 || onScreen.find('.loadMore').length > 0) {
                                        //se ho tasto viewMore sono partite troppe chiamate ajax..
                                        //fixo mettendo data prima di tasto load more
                                        $(this).siblings('.loadMore').before(h); 
                                    }
                                    else {
                                        $(this).before(h); 
                                    }
                                } 
                                else {
                                    if(onScreen.siblings('.loadMore').length > 0 || onScreen.find('.loadMore').length > 0) {
                                        //se ho tasto viewMore sono partite troppe chiamate ajax..
                                        //fixo mettendo data prima di tasto load more
                                        $(this).siblings('.loadMore').before(data); 
                                    }
                                    else {
                                        $(this).before(data); 
                                    }
                                } 
                                $.setLazy('update'); // Add animations to appended elements
                                if(type && ( type =="elencoTrucchi" || type =="elencoCommenti")) {
                                    //js valutazione trucchi / commenti
                                    $('.textRating').textRating();
                                }
                                if(type && type =="elencoVideo") {
                                    //trigger evento custom x reload flowplayer
                                    $(document).trigger('refreshVideo');
                                }
                                //check x tasto loadmore e blocco infinte scroll 
                                if(onScreen.siblings('.loadMore').length > 0) {
                                    onScreen.data('attivo',0);  
                                    onScreen.siblings('.loadMore').one('click',function(){
                                        onScreen.data('attivo',1); 
                                        $(this).remove();
                                        //richiamo append onscreen x ripartire subito con load
                                        tt = onScreen.data('offset')+1; 
                                        offset = 0 ;
                                        $.appendOnScreen(type,tt); 
                                    }); 
                                }
                                else if(onScreen.find('.loadMore').length > 0) {
                                    onScreen.data('attivo',0);  
                                    onScreen.find('.loadMore').one('click',function(){
                                        onScreen.data('attivo',1); 
                                        $(this).remove();
										//richiamo append onscreen x ripartire subito con load
                                        tt = onScreen.data('offset')+1; 
                                        offset = 0 ;
                                        if(type && type =="elencoCommenti") {
											//aggiorno la pagina mostrata altrimenti mi rimanda sempre alla pag 2
											$('#elencoCommenti').data('page',tt);
										}
                                        $.appendOnScreen(type,tt); 
                                    }); 
                                }
                                
                                //check fine elenco commenti - distruggo js x evitare chiamate a pagina 0
                                if(type && type =="elencoCommenti"  ) {
                                    var totPages = $('#elencoCommenti').data('tot-pages');
                                    
                                    if(totPages !== undefined && offset > totPages) {
                                        onScreen.data('attivo',0); 
                                    }
                                }
                            }
                        });
                    }
                }); 
            }
        });
    },
    setGsSubscribeForm: function () {
        //reinizializzo foundation nella form per chiamare controlli js della form di registrazione
        $('form#gsSubscribe').foundation({
            abide : {
                live_validate : true,
                focus_on_invalid : false,
                error_labels: true, // labels with a for="inputId" will recieve an `error` class
                timeout : 1000,
                patterns : {
                    username: /^[a-zA-Z0-9ÀÈÌÒÙàèìòùÁÉÍÓÚÝáéíóúýÂÊÎÔÛâêîôûÃÑÕãñõÄËÏÖÜŸäëïöüŸ¡¿çÇŒœßØøÅåÆæÞþÐð.!#$%&*+=?^_-]{4,20}$/,
                    alpha: /^[a-zA-ZÀÈÌÒÙàèìòùÁÉÍÓÚÝáéíóúýÂÊÎÔÛâêîôûÃÑÕãñõÄËÏÖÜŸäëïöüŸ¡¿çÇŒœßØøÅåÆæÞþÐð.!#$%&*+=?^_-]+$/,
                    alpha_numeric : /^[a-zA-Z0-9ÀÈÌÒÙàèìòùÁÉÍÓÚÝáéíóúýÂÊÎÔÛâêîôûÃÑÕãñõÄËÏÖÜŸäëïöüŸ¡¿çÇŒœßØøÅåÆæÞþÐð.!#$%&*+=?^_-]+$/,
                    integer: /^[-+]?\d+$/,
                    number: /^[-+]?[1-9]\d*$/, 
                    email : /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                    url: /(https?|ftp|file|ssh):\/\/(((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?/,
                    // abc.de
                    domain: /^([a-zA-Z0-9]([a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}$/,
                    // YYYY-MM-DD
                    date: /(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:30))|(?:(?:0[13578]|1[02])-31))/,
                    // MM/DD/YYYY
                    month_day_year : /(0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])[- \/.](19|20)\d\d/,
                    // DD/MM/YYYY
                    day_month_year : /(0[1-9]|[12][0-9]|3[01])[- \/.](0[1-9]|1[012])[- \/.](19|20)\d\d/ 
                },
                validators: {
                    checkName: function (el, required, parent) {
                        var str = el.value.trim();
                        if(!str || str.length < 2 ) { 
                            $(el).next('small').html('Nome non inserito o troppo corto');
                            return false; 
                        } 
                        else if( str.length > 50  ) { 
                            $(el).next('small').html('Nome troppo lungo');
                            return false; 
                        }
                        else {
                            return true;
                        }
                    },
                    checkSurname: function (el, required, parent) {
                        var str = el.value.trim();
                        if(!str || str.length < 2 ) { 
                            $(el).next('small').html('Cognome non inserito o troppo corto');
                            return false; 
                        } 
                        else if( str.length > 50  ) { 
                            $(el).next('small').html('Cognome troppo lungo');
                            return false; 
                        }
                        else {
                            return true;
                        }
                    },
                    checkEmail: function(el, required, parent) {
                        //return true se il controllo è superato con successo
                        var str = el.value;
                        var result = str.match(Foundation.libs.abide.settings.patterns.email);
                        if(!typeof($(el).attr('ajax-check'))) {
                            $(el).attr('ajax-check','invalid');
                        }
                        if(!str || str.length < 1 ) { 
                            $(el).next('small').html('E-mail non inserita');
                            return false; 
                        } 
                        else if(result === null || result === undefined || result === false ) {
                            $(el).next('small').html('E-mail non valida');
                            return false; 
                        }
                        else if($(el).attr('ajax-check') == 'valid'){
                            return true;
                        }
                        else {
                            var url=URL_API+'jsonRegistrazione.php?fn=checkEmail&EMAIL='+ str;
                            $.ajax({
                                dataType: "json",
                                url: url,
                                data: true,
                                success: function(data) { 
                                    if(data.data === false ) {
                                        $(el).attr('ajax-check','invalid');
                                        $(el).next('small').html('E-mail già in uso');
                                        return false; 
                                    } 
                                    else {
                                        //email valida
                                        $(el).attr('ajax-check','valid');
                                        $(parent).removeClass('error');
                                        $("label[for='"+el.id+"']").removeClass('error');
                                        $(el).attr('aria-invalid',false);
                                        $(el).removeAttr('aria-describedby');
                                        $(el).removeAttr('data-invalid');
                                        $(el).next('small').html('');
                                        return true; 
                                    }
                                }
                            }); 
                        }
                    },
                    checkUsername: function(el, required, parent) {
                        //return true se il controllo è superato con successo
                        var str = el.value;
                        var result = str.match(Foundation.libs.abide.settings.patterns.username);
                        if(!typeof($(el).attr('ajax-check'))) {
                            $(el).attr('ajax-check','invalid');
                        }
                        if(!str || str.length < 4 ) { 
                            $(el).next('small').html('Username non inserita o troppo corta, minimo 4 caratteri');
                            return false; 
                        }
                        else if( str.length > 20  ) { 
                            $(el).next('small').html('Username troppo lunga');
                            return false; 
                        }
                        else if(result === null || result === undefined || result === false ) {
                            $(el).next('small').html('Username non valida, sono presenti dei caratteri non consentiti');
                            return false; 
                        }
                        else if($(el).attr('ajax-check') == 'valid'){
                            return true;
                        }
                        else {
                            var url=URL_API+'jsonRegistrazione.php?fn=checkUsernameExist&USERNAME='+ str;
                            $.ajax({
                                dataType: "json",
                                url: url,
                                data: true,
                                success: function(data) { 
                                    if(data.data === false ) {
                                        $(el).attr('ajax-check','invalid');
                                        $(el).next('small').html('Username già in uso');
                                        return false; 
                                    } 
                                    else {
                                        //username valida
                                        $(el).attr('ajax-check','valid');
                                        $(parent).removeClass('error');
                                        $("label[for='"+el.id+"']").removeClass('error');
                                        $(el).attr('aria-invalid',false);
                                        $(el).removeAttr('data-invalid');
                                        $(el).removeAttr('aria-describedby');
                                        $(el).next('small').html('');
                                        return true; 
                                    }
                                }
                            }); 
                        }
                    }
                }
            }
        });
        $('form#gsSubscribe').on('valid.fndtn.abide', Foundation.utils.debounce(function() {
            //Faccio submit della form di registrazione - con debounce prevengo click ripetuti
            $('form#gsSubscribe').hide('fast'); 
            $('div.loading').removeClass('hide'); 
            $('div.loading').fadeIn(); 
            $('.smoothLink').trigger('click'); 
            //mostro loading e faccio chiamata x registrarmi IN POST!
            var url=URL_API+'jsonRegistrazione.php?fn=registrazione' ;
            $.ajax({
                dataType: "json",
                url: url,
                data: $('form#gsSubscribe').serialize(),
                type: "POST",
                success: function(data) { 
                    $('div.loading').hide();
                    if(data.data === true ) { 
                        //mostro messaggio di conferma modifica
                        $('div.success').html(
                            '<i class="fa fa-check"></i><br>Registrazione effettuata correttamente.<br>Ti è stata inviata una mail contenente i dati di accesso.<br>Entro qualche secondo verrai riportato in home page'
                        ); 
                        $('div.success').fadeIn(); 
                        setTimeout(function(){
                            //ritorno in hp con sessione impostata
                            window.location = URL_INDEX;
                        }, 5000); 
                        //load toolbar utente loggato ?
                    }
                    else {
                        $('form#gsSubscribe').show('fast');
                    }
                    return false;
                }
            });  
        }, 1000, true));
        $('form#gsSubscribe').on('invalid.fndtn.abide', function() {
            // se ho errori faccio scrolltop sul primo errore trovato - è smoothlink
            var target = $("form#gsSubscribe label.error:first");
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top  -50
                }, 1000);
                return false;
            }
        }); 
    },
    setGsRecuperoPasswordForm: function () {
        //reinizializzo foundation nella form per chiamare controlli js della form di registrazione
        $('form#gsRecuperoPassword').foundation({
            abide : {
                live_validate : true,
                focus_on_invalid : false,
                error_labels: true, // labels with a for="inputId" will recieve an `error` class
                timeout : 1000,
                patterns : {
                    alpha: /^[a-zA-ZÀÈÌÒÙàèìòùÁÉÍÓÚÝáéíóúýÂÊÎÔÛâêîôûÃÑÕãñõÄËÏÖÜŸäëïöüŸ¡¿çÇŒœßØøÅåÆæÞþÐð.!#$%&*+=?^_-]+$/,
                    alpha_numeric : /^[a-zA-Z0-9ÀÈÌÒÙàèìòùÁÉÍÓÚÝáéíóúýÂÊÎÔÛâêîôûÃÑÕãñõÄËÏÖÜŸäëïöüŸ¡¿çÇŒœßØøÅåÆæÞþÐð.!#$%&*+=?^_-]+$/,
                    email : /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
                },
                validators: {  
                    checkEmail: function(el, required, parent) {
                        //return true se il controllo è superato con successo
                        var str = el.value;
                        var result = str.match(Foundation.libs.abide.settings.patterns.email);
                        if(!str || str.length < 1 ) { 
                            $(el).next('small').html('E-mail non inserita');
                            return false; 
                        } 
                        else if(result === null || result === undefined || result === false ) {
                            $(el).next('small').html('E-mail non valida');
                            return false; 
                        }
                        else  {
                            return true;
                        } 
                    }
                }
            }
        });
        $('form#gsRecuperoPassword').on('valid.fndtn.abide', Foundation.utils.debounce(function() {
            //Faccio submit della form di registrazione - con debounce prevengo click ripetuti
            $('form#gsRecuperoPassword').hide('fast'); 
            $('div.loading').removeClass('hide'); 
            $('div.loading').fadeIn(); 
            $('.smoothLink').trigger('click'); 
            //mostro loading e faccio chiamata x registrarmi IN POST!
            var url=URL_API+'jsonRegistrazione.php?fn=recuperoPassword' ;
            $.ajax({
                dataType: "json",
                url: url,
                data: $('form#gsRecuperoPassword').serialize(),
                type: "POST",
                success: function(data) { 
                    $('div.loading').hide();
                    if(data.data === true ) { 
                        //mostro messaggio di conferma modifica
                        $('div.success').html(
                            '<i class="fa fa-check"></i><br>Richiesta effettuata correttamente.<br>I dati ti sono appena stati inviati via email.<br>Entro qualche secondo verrai riportato in home page'
                        ); 
                        $('div.success').fadeIn(); 
                        setTimeout(function(){
                            //ritorno in hp con sessione impostata
                            window.location = URL_INDEX;
                        }, 5000); 
                        //load toolbar utente loggato ?
                    }
                    else {
                        $('form#gsRecuperoPassword').show('fast');
                        setTimeout(function(){
                            //mostro messaggio di errore ricevuto dopo 0.5 secondi ( bugfix al primo errore perdo la classe error )
                            $('form#gsRecuperoPassword #emailError').html(data.msg);
                            $('form#gsRecuperoPassword #emailError').parent().addClass('error');
                        }, 500); 
                    }
                    return false;
                }
            });  
        }, 1000, true));
        $('form#gsRecuperoPassword').on('invalid.fndtn.abide', function() {
            // se ho errori faccio scrolltop sul primo errore trovato - è smoothlink
            var target = $("form#gsRecuperoPassword label.error:first");
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top  -50
                }, 1000);
                return false;
            }
        }); 
    },
    setGsLogout: function() {
        $('#gsLogout').on('click',function(){
            $.userAjaxCall('logout');
        });
        $('#gsLogoutMobile').on('click',function(){
            $.userAjaxCall('logout');
        });
    },
    setGsSearchForm: function() {
        alpha_numericS = /^[a-zA-Z0-9ÀÈÌÒÙàèìòùÁÉÍÓÚÝáéíóúýÂÊÎÔÛâêîôûÃÑÕãñõÄËÏÖÜŸäëïöüŸ¡¿çÇŒœßØøÅåÆæÞþÐð.!#$%&*+=?^_-\w\s]+$/;
        checkSearch= function() {
            var word =$('form#gsSearch #testoRicerca').val().trim();
            if(!word || word === "" || word === "undefined" || word.length <3) { 
                $('form#gsSearch #searchError').parent().addClass('error');  
                $('form#gsSearch #searchError .error').text("Parola chiave non inserita o troppo corta");   
                $('form#gsSearch #searchError').fadeIn();   
                return false; 
            }
            else {
                var res1 = word.match(alpha_numericS); 
                if(res1 === null || res1 === undefined || res1 === false   ) {
                    $('form#gsSearch #searchError').parent().addClass('error');  
                    $('form#gsSearch #searchError .error').text("Caratteri non validi");   
                    $('form#gsSearch #searchError').fadeIn();   
                    return false; 
                }
                else return true;
            }
        };
        $('form#gsSearch').on('submit', Foundation.utils.debounce(function() {
            //Faccio submit della form di ricerca
            $('form#gsSearch #gsSearchResult').html(""); 
            offsetAjaxSearch = 0;
            //mostro loading e faccio chiamata x registrarmi IN POST!
            if(checkSearch() === true ) { 
                $('form#gsSearch #searchError').hide();   
                $('form#gsSearch #gsSearchLoading').fadeIn('fast'); 
                var objRequest = {};
                objRequest.url=URL_API+'local_json.php';
                objRequest.type='search';
                objRequest.serialized=$('form#gsSearch').serialize()+ '&pagina=1'; 
                $.userAjaxCall(objRequest , function(data){
                    $('form#gsSearch #gsSearchLoading').fadeOut('fast',function() {
                        if(data) {
                            var el = $(data) , time = 1;
                            $.each(el,function(){ 
                                var data = this;
                                $(data).addClass('animated fadeInRight');
                                $("form#gsSearch #gsSearchResult").delay(time).queue(function (next) {
                                    $(this).append(data);
                                    //solo in styicki cosi $.setLazy('update','.expanded-search'); // Add animations to appended elements
                                    $.setLazy('update'); // Add animations to appended elements
                                    next();
                                }); 
                                time += 2;
                            });
                        }
                    });
                    $.setLazy('update'); // Add animations to appended elements
                    //solo in styicki cosi $.setLazy('update','.expanded-search'); // Add animations to appended elements
                }); 
            }
            return false;
        }, 1000, true));
    },
    setGsSearchFormMobile: function() {
        alpha_numericS = /^[a-zA-Z0-9ÀÈÌÒÙàèìòùÁÉÍÓÚÝáéíóúýÂÊÎÔÛâêîôûÃÑÕãñõÄËÏÖÜŸäëïöüŸ¡¿çÇŒœßØøÅåÆæÞþÐð.!#$%&*+=?^_-\w\s]+$/;
        checkSearchMobile= function() {
            var word =$('form#gsSearchMobile #testoRicerca').val().trim(); 
            if(!word || word === "" || word === "undefined" || word.length <3) { 
                $('form#gsSearchMobile #searchError').parent().addClass('error');  
                $('form#gsSearchMobile #searchError .error').text("Parola chiave non inserita o troppo corta");   
                $('form#gsSearchMobile #searchError').fadeIn();   
                return false; 
            }
            else {
                var res1 = word.match(alpha_numericS); 
                if(res1 === null || res1 === undefined || res1 === false   ) {
                    $('form#gsSearchMobile #searchError').parent().addClass('error');  
                    $('form#gsSearchMobile #searchError .error').text("Caratteri non validi");   
                    $('form#gsSearchMobile #searchError').fadeIn();   
                    return false; 
                }
                else return true;
            }
        };
        $('form#gsSearchMobile').on('submit', Foundation.utils.debounce(function() {
            //Faccio submit della form di ricerca
            $('form#gsSearchMobile #gsSearchResult').html(""); 
            offsetAjaxSearch = 0;
            //mostro loading e faccio chiamata x registrarmi IN POST!
            if(checkSearchMobile() === true ) { 
                $('form#gsSearchMobile #searchError').hide();   
                $('form#gsSearchMobile #gsSearchLoading').fadeIn('fast'); 
                var objRequest = {};
                objRequest.url=URL_API+'local_json.php';
                objRequest.type='search';
                objRequest.serialized=$('form#gsSearchMobile').serialize()+ '&pagina=1'; 
                $.userAjaxCall(objRequest , function(data){
                    $('form#gsSearchMobile #gsSearchLoading').fadeOut('fast',function() {
                        if(data) {
                            var el = $(data) , time = 1;
                            $.each(el,function(){ 
                                var data = this;
                                $(data).addClass('animated fadeInUp');
                                $("form#gsSearchMobile #gsSearchResult").delay(time).queue(function (next) {
                                    $(this).append(data);
                                    animation="fadeIn";  //se faccio ricerca in scheda gioco o immagini devo aggioranre animation!
                                    $.setLazy('update','.expanded-search'); // Add animations to appended elements
                                    //$.setLazy('update'); // Add animations to appended elements
                                    next();
                                }); 
                                time += 2;
                            });
                            
                            //push banner google
                            //googletag.cmd.push(function() { googletag.display('div-gpt-ad-1371485439280-0'); });
        
                        }
                    });
                    //$.setLazy('update'); // Add animations to appended elements
                    $.setLazy('update','.expanded-search'); // Add animations to appended elements
                }); 
            }
            return false;
        }, 1000, true));
    },
    setGsFormSearchFull: function() {
        $('#keyword-label').on('keypress',function(event) { 
            if(event.keyCode != 13) return event.keyCode;
            else {
                $('form#gsFormSearchFull button').trigger('click');
                return false;
            }
        });
        $('form#gsFormSearchFull').on('submit', function() {
            $('form#gsFormSearchFull button').trigger('click');
        });
        $('form#gsFormSearchFull button').on('click', function() {
            offset =0;
            parola_chiave =$('form#gsFormSearchFull #keyword-label').val(); 
            
            //aggiorno url tool browser
            //if(tipo=="ricevuti")tipo="";
            //var newUrl = URL_CERCA + '?npiattaforma=' + npiattaforma + '&tipo=' + tipo+ '&PAROLA='+parola_chiave;
            //window.history.pushState('page2', 'Gamestorm - pagina di ricerca', newUrl);
            
            $('#gsSearchResultFull').data('attivo',1);
            $('#gsSearchResultFull').data('offset',0); 
            $('form#gsFormSearchFull').parents('.gs-box').find('.loadMore.js-generated').off('click').remove(); 
            $('form#gsFormSearchFull').parents('.gs-box').find('.gs-box-content').remove(); 
            $('form#gsFormSearchFull').parents('.gs-box').find('.gs-box-content-expanded').remove(); 
            $('.endOnScreen').show(); 
            $.appendOnScreen('gsSearchResultFull',1);   
        });
    },
    setGsFormCercaUtenti: function() {
        $('form#gsFormCercaUtenti #PAROLA').on('keypress',function(event) { 
            if(event.keyCode != 13) return event.keyCode;
            else {
                $('form#gsFormCercaUtenti button').trigger('click');
                return false;
            }
        });
        $('form#gsFormCercaUtenti').on('submit', function() {
            $('form#gsFormCercaUtenti button').trigger('click');
        });
        $('form#gsFormCercaUtenti button').on('click', function() {
            offset =0;
            //.."resetto" lettera
            $('.gs-box-filter li.active').removeClass('active');
            lettera = "";
            parola_chiave =$('form#gsFormCercaUtenti #PAROLA').val(); 
            
            $('form#gsFormCercaUtenti').parents('.gs-box').find('.gs-box-content.js-generated').remove();  
            $('.endOnScreen').show(); 
            $.appendOnScreen('elencoUtenti',1);   
        });
    },
    setGsLoginForm: function() {
        //reinizializzo foundation nella form per chiamare controlli js della form di registrazione
        alpha_numeric = /^[a-zA-Z0-9ÀÈÌÒÙàèìòùÁÉÍÓÚÝáéíóúýÂÊÎÔÛâêîôûÃÑÕãñõÄËÏÖÜŸäëïöüŸ¡¿çÇŒœßØøÅåÆæÞþÐð.!#$%&*+=?^_-\s]+$/;
        checkLogin = function() {
            var us =$('form#gsLogin #usernameLogin').val().trim();
            var paswd =$('form#gsLogin #passwordLogin').val().trim();
            //controllo username e passwod 
            if(!us || us === "" || us === "undefined" || !paswd || paswd === "" || paswd === "undefined" ) { 
                $('form#gsLogin #loginError').parent().addClass('error');  
                $('form#gsLogin #loginError').fadeIn();   
                return false; 
            }
            else {
                var res1 = us.match(alpha_numeric);
                var res2 = paswd.match(alpha_numeric);
                if(res1 === null || res1 === undefined || res1 === false || res2 === null || res2 === undefined || res2 === false ) {
                    $('form#gsLogin #loginError').parent().addClass('error');  
                    $('form#gsLogin #loginError').fadeIn();   
                    return false; 
                }
                else {
                    $('form#gsLogin #loginError').parent().removeClass('error');  
                    $('form#gsLogin #loginError').fadeOut();   
                    return true;  
                }
            } 
        };      
        $('form#gsLogin').on('submit', Foundation.utils.debounce(function() {
            //Faccio submit della form di registrazione - con debounce prevengo click ripetuti
            //$('form#gsSubscribe').hide('fast');
            //$('div.loading').fadeIn(); 
            //mostro loading e faccio chiamata x registrarmi IN POST!
            if(checkLogin() === true ) {
                $.userAjaxCall('login');
            }
            return false;
        }, 1000, true)); 
        //btns login social
        $('.fb-login').on('click', function(e) {
            //e.preventDefault();
            FB.login(checkLoginState, {scope:'public_profile,email,publish_actions'});
            return false;
        }); 
        $('.gplus-login').on('click', function(e) {
            //e.preventDefault();
            return false;
        });
    },
    setGsLoginFormMobile: function() {
        //reinizializzo foundation nella form per chiamare controlli js della form di registrazione
        alpha_numeric = /^[a-zA-Z0-9ÀÈÌÒÙàèìòùÁÉÍÓÚÝáéíóúýÂÊÎÔÛâêîôûÃÑÕãñõÄËÏÖÜŸäëïöüŸ¡¿çÇŒœßØøÅåÆæÞþÐð.!#$%&*+=?^_-\s]+$/;
        checkLoginM = function() {
            var us =$('form#gsLoginMobile #usernameLogin').val().trim();
            var paswd =$('form#gsLoginMobile #passwordLogin').val().trim();
            //controllo username e passwod 
            if(!us || us === "" || us === "undefined" || !paswd || paswd === "" || paswd === "undefined" ) { 
                $('form#gsLoginMobile #loginError').parent().addClass('error');  
                $('form#gsLoginMobile #loginError').fadeIn();   
                return false; 
            }
            else {
                var res1 = us.match(alpha_numeric);
                var res2 = paswd.match(alpha_numeric);
                if(res1 === null || res1 === undefined || res1 === false || res2 === null || res2 === undefined || res2 === false ) {
                    $('form#gsLoginMobile #loginError').parent().addClass('error');  
                    $('form#gsLoginMobile #loginError').fadeIn();   
                    return false; 
                }
                else {
                    $('form#gsLoginMobile #loginError').parent().removeClass('error');  
                    $('form#gsLoginMobile #loginError').fadeOut();   
                    return true;  
                }
            } 
        };      
        $('form#gsLoginMobile').on('submit', Foundation.utils.debounce(function() {
            //Faccio submit della form di registrazione - con debounce prevengo click ripetuti
            //$('form#gsSubscribe').hide('fast');
            //$('div.loading').fadeIn(); 
            //mostro loading e faccio chiamata x registrarmi IN POST!
            if(checkLoginM() === true ) {
                $.userAjaxCall('loginMobile');
            }
            return false;
        }, 1000, true));
    },
    setGsFormCercaTrucchi: function() {
        $('#keyword-label').on('keypress',function(event) { 
            if(event.keyCode != 13) return event.keyCode;
            else {
                $('form#gsFormCercaTrucchi button').trigger('click');
                return false;
            }
        });
        $('form#gsFormCercaTrucchi').on('submit', function() {
            $('form#gsFormCercaTrucchi button').trigger('click');
        });
        $('form#gsFormCercaTrucchi button').on('click', function() {
            offset =0;
            parola_chiave =$('form#gsFormCercaTrucchi #keyword-label').val(); 
            $('.gs-box-content.js-generated').remove(); 
            $('.endOnScreen').show(); 
            $.appendOnScreen('elencoTrucchi',1);  
            //$('form#gsFormCercaTrucchi')[0].reset();
        });
    },
    setGsFormRiscattoCodici: function() {
        //gsRiscattoCodici   
        //reinizializzo foundation nella form per chiamare controlli js della form di registrazione
        $('form#gsRiscattoCodici').foundation({
            abide : {
                live_validate : true,
                focus_on_invalid : false,
                error_labels: true, // labels with a for="inputId" will recieve an `error` class
                timeout : 1000,
                patterns : {
                    codice: /^([0-9]{4})-([0-9]{4})-([0-9]{4})$/
                }
            }
        });
        $('form#gsRiscattoCodici').on('valid.fndtn.abide', Foundation.utils.debounce(function() {
            //Faccio submit della form di registrazione - con debounce prevengo click ripetuti
            if($.userLogin) { 
                $('form#gsRiscattoCodici').hide('fast'); 
                $('form#gsRiscattoCodici').siblings('div.loading').removeClass('hide'); 
                $('form#gsRiscattoCodici').siblings('div.loading').fadeIn(); 
                //$('.smoothLink').trigger('click'); 
                //mostro loading e faccio chiamata x registrarmi IN POST!
                var url=URL_API+'jsonRiscattaCodici.php?fn=riscatta' ;
                $.ajax({
                    dataType: "json",
                    url: url,
                    data: $('form#gsRiscattoCodici').serialize(),
                    type: "POST",
                    success: function(data) { 
                        $('form#gsRiscattoCodici').siblings('div.loading').hide();
                        if(data.data === true ) { 
                            //mostro messaggio   
                            $('form#gsRiscattoCodici').siblings('div.success').html(
                                '<i class="fa fa-check"></i><br>'+data.msg
                            ); 
                            $('form#gsRiscattoCodici').siblings('div.success').fadeIn(); 
                            //rifaccio reload dopo 15 secondi
                            setTimeout(function(){
                                //ritorno in hp 
                                window.location = URL_INDEX;
                            }, 15000); 
                        }
                        else {
                            $('form#gsRiscattoCodici')[0].reset();
                            $('form#gsRiscattoCodici #codiceError').text(data.msg); 
                            $('form#gsRiscattoCodici').fadeIn('fast');
                        }
                        return false;
                    }
                }); 
            }
            else {
                //gs alert x login o registrazione.
                $('#gsModalLoginAlert').foundation('reveal','open');  
                var target = $(this).parents('.gs-box-white');
                if (target.length) {
                    $('html,body').animate({
                        scrollTop: target.offset().top
                    }, 1000); 
                }
            }
        }, 1000, true));
        $('form#gsRiscattoCodici').on('invalid.fndtn.abide', function() {
            // se ho errori faccio scrolltop sul primo errore trovato - è smoothlink
            var target = $("form#gsRiscattoCodici label.error:first");
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top  -50
                }, 1000);
                return false;
            }
        }); 
    },
    setGsRevealUtentiOnline: function() {
        $('#utentiOnline').on('click',function(e){
            e.preventDefault();
            $('#gsModalUtentiOnline').foundation('reveal','open'); 
            //mostro loading e nascondo div txt
            $('#gsModalUtentiOnline div.result').hide(); 
            $('#gsModalUtentiOnline div.loading').removeClass('hide'); 
            $('#gsModalUtentiOnline div.loading').fadeIn(); 
            //chiamata ajax con callback mostro txt 
            objRequest={};
            objRequest["type"]="utentiOnline"; 
            var callbackAction = function(data) {
                $('#gsModalUtentiOnline div.loading').hide(); 
                if(data && data.data === true ) {
                    //se data è true allora lo posso votare ... altrimenti era gia stato votato! 
                    var text="Utenti connessi: <b>"+data.totUtenti+"</b><br><br>";
                    if(data.totUtenti && parseInt(data.totUtenti)>0) {
                        var cont = 0;
                        $.each(data.utenti,function(){
                            var ut = this;
                            if(cont>0) text +=" -  ";
                            if(GS_ACTIVE_ROUTING ==1) {
                                text +="<a href='"+URL_WEB+"user/"+ut[1]+"'>"+ut[0]+"</a> ";
                            }
                            else {
                                text +="<a href='"+URL_UTENTE_SCHEDA+"?COD_UTENTE="+ut[1]+"'>"+ut[0]+"</a> ";
                            }
                            cont++;
                        });
                    }
                    $('#gsModalUtentiOnline div.result').html(text); 
                    $('#gsModalUtentiOnline div.result').fadeIn(); 
                }
                else {
                            
                }
            } 
            $.userAjaxCall(objRequest , callbackAction );
        });
    }
});