//Star Rating: widget x votare gioco
$.fn.starRating = function ( options ) {
    var settings = $.extend({
        // These are the defaults.
        refreshVoto: ""
    }, options );
    var container= $(this);
    var stars= container.children('.rating').children('.star');
    var valutazione ="";
    var textIndicator = [
        "Il peggior gioco di tutti i tempi",
        "Gioco veramente orrendo",
        "Pessimo videogioco",
        "Nulla di speciale",
        "Gioco appena decente",
        "Titolo giocabile",
        "Un buon gioco, da provare",
        "Ottimo titolo, da avere",
        "Gioco eccellente da non farsi mancare",
        "Il miglior gioco di sempre!"
    ];
    if(parseInt(settings.refreshVoto)) {
        valutazione=parseInt(settings.refreshVoto);
        stars.each(function(){ 
            if( Math.abs(stars.index(this) - 10) <= valutazione ) {
                $(this).delay(10).queue(function(){ 
                    $(this).addClass("selected").clearQueue(); 
                });
            }
        }); 
        $("#valutazioneStarRating").hide().html( valutazione).fadeIn(1000);
        container.children(".rating-indicator").html(valutazione + " - " + textIndicator[valutazione-1]);
        return false;
    }
    stars.on('click',function( e ) {
        valutazione = Math.abs(stars.index(this) - 10);
        //check sessione ...
        if($.userLogin) {   
            stars.each(function(){
                $(this).removeClass("selected animated tada");
                if( Math.abs(stars.index(this) - 10) <= valutazione ) {
                    $(this).delay(10).queue(function(){ 
                        $(this).addClass("selected animated tada").clearQueue(); 
                    });
                }
            }); 
            $("#valutazioneStarRating").hide().html( valutazione).fadeIn(1000);
            container.children(".rating-indicator").html(valutazione + " - " + textIndicator[valutazione-1]);
            //chiamata ajax
            $.userAjaxCall("votaGioco");
        }
        else {
            //gs alert x login o registrazione.
            $('#gsModalLoginAlert').foundation('reveal','open');  
            var target = $(this).parents('.gs-box-white');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top
                }, 1000); 
            }
        }
    })
    .on("mouseenter",function(){
        var valutazioneT = Math.abs(stars.index(this) - 10);
        container.children(".rating-indicator").html(valutazioneT + " - " + textIndicator[valutazioneT-1]);
    })
    .on("mouseleave",function(){
        if(valutazione) {
            container.children(".rating-indicator").html(valutazione + " - " + textIndicator[valutazione-1]);
        }
        else {
            container.children(".rating-indicator").html("&nbsp;");
        }
    });  
};
//Text Rating: widget x votare trucchi commenti ecc ( e azioni associate  => segnalare abusi / azioni admin / contattare autore commento )
$.fn.textRating = function ( ) { 
    //sono istanzianti N widgets 
    $(this).each(function() { 
        var textRatingElement= this;
        var body= $('body');
        var container= $(textRatingElement);
        var codProdotto=(body.data('cod-prodotto'));
        var codRecensione=(body.data('cod-recensione'));
        var codAnteprima=(body.data('cod-anteprima'));
        var codNews=(body.data('cod-news'));
        var codCommento=parseInt(container.data('cod-commento'));
        var codUtenteCommento=parseInt(container.data('cod-utente-commento'));
        var nomeUtenteCommento=container.data('nome-utente-commento');
        var sendPrivateMessage= container.children('.fa-envelope');
        var reportAbuse= container.children('.fa-exclamation-triangle'); 
        var codTrucco=parseInt(container.data('cod-trucco'));
        var up= container.children('.text-rating').children('.up');
        var voto= container.children('.text-rating').children('.voto');
        var down= container.children('.text-rating').children('.down');
        var valutazione = parseInt(voto.text()); 
        var objRequest = {};
        var callbackAction = function(data) {
            //a fine voto esegui aggiornamento del widget
            if(data && data.data === true ) {
                //se data è true allora lo posso votare ... altrimenti era gia stato votato!
                if(valutazione == 0 ) { voto.removeClass("offline online"); }
                else if(valutazione > 0) { voto.addClass("online"); } 
                else if(valutazione < 0) { voto.addClass("offline"); } 
                voto.hide().html( valutazione).fadeIn(1000); 
            }
            container.addClass("votato");
            up.removeClass("infinite").addClass("disabled"); 
            down.removeClass("infinite").addClass("disabled");      
        } 
        if(container.hasClass('votato')) {  
            //se elemento ha classe "votato" ... 
            //vado al prossimo senza settare up / down / ecc ( casistica dopo ajax appendOnScreen => escludo elementi gia votati )
            return;
        }
        //setto Off e poi on x evitare duplicazione di eventi ( caso di ajax - es. infine scroll appendOnScreen )
        up.off('click').on('click',function( e ) {
            //check sessione ...
            if($.userLogin) {   
                valutazione = valutazione + 1 ;
                down.off('click');
                down.removeClass("selected animated infinite pulse"); 
                $(this).off('click');
                $(this).addClass("selected animated infinite pulse"); 
                //chiamata ajax
                if(codTrucco && codTrucco > 0) {
                    objRequest["type"]="votaTruccoPlus";
                    objRequest["trucco"]=codTrucco;
                    objRequest["prodotto"]=codProdotto;
                }
                else {
                    objRequest["type"]="votaCommentoPlus";
                    objRequest["commento"]=codCommento;
                    objRequest["mittente"]=codUtenteCommento;
                    if(codNews && parseInt(codNews) > 0) {
                        objRequest["news"]=codNews;
                    }
                    if(codRecensione && parseInt(codRecensione) > 0) {
                        objRequest["recensione"]=codRecensione;
                    }
                    if(codAnteprima && parseInt(codAnteprima) > 0) {
                        objRequest["anteprima"]=codAnteprima;
                    }
                    if(codProdotto && parseInt(codProdotto) > 0) {
                        objRequest["prodotto"]=codProdotto;
                    }
                }
                $.userAjaxCall(objRequest , callbackAction );
            }
            else {
                //gs alert x login o registrazione.
                $('#gsModalLoginAlert').foundation('reveal','open');  
                var target = $(this).parents('.textRating');
                if (target.length) {
                    $('html,body').animate({
                        scrollTop: target.offset().top
                    }, 1000); 
                }
            }
        }) 
        down.off('click').on('click',function( e ) {
            //check sessione ...
            if($.userLogin) {   
                valutazione = valutazione - 1 ;
                up.off('click');
                up.removeClass("selected animated infinite pulse"); 
                $(this).off('click');
                $(this).addClass("selected animated infinite pulse"); 
                //chiamata ajax
                if(codTrucco && codTrucco > 0) {
                    objRequest["type"]="votaTruccoMinus";
                    objRequest["trucco"]=codTrucco;
                    objRequest["prodotto"]=codProdotto;
                }
                else {
                    objRequest["type"]="votaCommentoMinus";
                    objRequest["commento"]=codCommento;
                    objRequest["mittente"]=codUtenteCommento;
                    if(codNews && parseInt(codNews) > 0) {
                        objRequest["news"]=codNews;
                    }
                    if(codRecensione && parseInt(codRecensione) > 0) {
                        objRequest["recensione"]=codRecensione;
                    }
                    if(codAnteprima && parseInt(codAnteprima) > 0) {
                        objRequest["anteprima"]=codAnteprima;
                    }
                    if(codProdotto && parseInt(codProdotto) > 0) {
                        objRequest["prodotto"]=codProdotto;
                    }
                }
                $.userAjaxCall(objRequest , callbackAction );
            }
            else {
                //gs alert x login o registrazione.
                $('#gsModalLoginAlert').foundation('reveal','open');  
                var target = $(this).parents('.textRating');
                if (target.length) {
                    $('html,body').animate({
                        scrollTop: target.offset().top
                    }, 1000); 
                }
            }
        });
        //se ho codUtenteCommento potrebbe esserci tasto x inviare msg privato
        sendPrivateMessage.off('click').on('click',function( e ) {
            //check sessione ...
            if($.userLogin && nomeUtenteCommento) {   
                //redirect in msg privati 
                window.location = URL_UTENTE_MESSAGGI + '?destinatario=' + nomeUtenteCommento;
            }
            else if(nomeUtenteCommento){
                //gs alert x login o registrazione.
                $('#gsModalLoginAlert').foundation('reveal','open');  
                var target = $(this).parents('.textRating');
                if (target.length) {
                    $('html,body').animate({
                        scrollTop: target.offset().top
                    }, 1000); 
                }
            }
        });
        //se ho commento posso segnalare abusi
        reportAbuse.off('click').on('click',function( e ) {
            //check sessione ...
            if($.userLogin && codCommento) {   
                //mostro popup conferma segnalazione abuso 
                ///giochi/copiato_action.php?CC=24&amp;CG=2010" cg = gioco cc = commento 
                $('#gsModalConfirm p').text('Segnalare il messaggio come offensivo o inopportuno ?'); 
                $('#gsModalConfirm').foundation('reveal','open'); 
                $('#gsModalConfirm').data('cod-commento',codCommento);  
                $('#gsModalConfirm').data('cod-prodotto',codProdotto);  
                $('#gsModalConfirm').data('cod-news',codNews);  
                $('#gsModalConfirm').data('cod-recensione',codRecensione);
                $('#gsModalConfirm').data('cod-anteprima',codAnteprima); 
                $('#gsModalConfirm').data('azione','segnala'); 
            }
            else if(codCommento){
                //gs alert x login o registrazione.
                $('#gsModalLoginAlert').foundation('reveal','open');  
                var target = $(this).parents('.textRating');
                if (target.length) {
                    $('html,body').animate({
                        scrollTop: target.offset().top
                    }, 1000); 
                }
            }
        });
    });
};
//Plugin x aggiungere rimuovere gioco da mygame
$.fn.addGame = function() {
    if($.userLogin) {
        $(this).on('click',function(e){
            e.preventDefault();   
            var gioco = $(this).data('cod-prodotto');
            var action = $(this).data('action');
            $(this).hide(); 
            if(action == 'rimuovi' ) {  
                $.userAjaxCall("deleteMyGame");
                $(this).html('<i class="fa fa-plus"></i> Aggiungi ai miei giochi</a>');
                $(this).data('action','aggiungi');
            }
            else {
                $.userAjaxCall("addMyGame");
                $(this).html('<i class="fa fa-minus"></i> Rimuovi dai miei giochi</a>'); 
                $(this).data('action','rimuovi');
            }
            $(this).fadeIn(); 
        });
    }
    else {
        $(this).on('click',function(e){
            e.preventDefault();  
            $('#gsModalLoginAlert').foundation('reveal','open');  
            //trick x forzare modalita topbar sticky in caso di login desktop
            var target = $(this).parents('.gs-box');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top
                }, 1000); 
            }
        });
    }
};
//Link x andare a top pagina con effetto animate
$.fn.smoothLink = function () {
    var smoothOn= false;
    $(this).click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = (this.hash == "#body") ? $('body') : $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top
                }, 1000);
                return false;
            }
        }
    });
    var l  = this;
    $(document).scroll(function () {
        if($(window).scrollTop()>$.smoothLinkLimit && smoothOn===false) {
            $(l).fadeIn();
            smoothOn=true;
        }
        else if(smoothOn===true && $(window).scrollTop() <$.smoothLinkLimit) {
            smoothOn=false; 
            $(l).fadeOut(); 
        }
    });
};
//Script per gsBoxNav x aprire e chiudere con effetto slide
$.fn.gsBoxNav = function () { 
    var gsNav = this;
    if($.breakpointMain() && boxNavMode != 'small' ) {
        //console.log('collapse version');
        boxNavMode = 'small'; 
        $(gsNav).children("ul" ).css('display','none'); 
        $(gsNav).children('a:first-child').off();
        $(gsNav).children('a:first-child').on('click',  Foundation.utils.debounce(function(e){
            // Handle Click
            $(gsNav).children('ul').slideToggle('slow');       
        }, 200, true));
    }
    else if(!$.breakpointMain() && boxNavMode == 'small') {
        //console.log('no collapse version');
        boxNavMode ='';
        $(gsNav).children("ul" ).css('display','block');  
    }
    $( window ).on( "resize", function( event ) {
        $(gsNav).gsBoxNav(); 
        $( this ).off( event );
    });
};
//Script x mostrare testo di un box a mousehover .... non usato
$.fn.gsToggleContentBox = function(type){
    var gsDiv = this;
    /*$(gsDiv).each(function(){
        if($(this).hasClass('expanded')){
           //controllo se devo fixare altezza img
             if(type=='chart') {
                var hDivParent = $(this).height();
                var hImg = $(this).find('img').height();
                if(hImg<hDivParent) { 
                    console.log(hDivParent + ' ' + hImg);
                    $(this).find('img').css('height',hDivParent+'px');
                }
             }
        }
    });*/
    $(gsDiv).hover( function(){$( this  ).addClass('expanded');});
    /*$( window ).on( "resize", function( event ) {
        $(gsDiv).gsToggleContentBox(type);
        $( this ).off( event );
    });*/
};
//contatore animato da 0 a n...usato in calcolo rank .. valutazione gioco ecccc
$.fn.gsCountTo = function(options) {
    // merge the default plugin settings with the custom options
    var settings = $.extend({
        // These are the defaults.
        from: 0,  // the number the element should start at
        to: 100,  // the number the element should end at
        speed: 1000,  // how long it should take to count between the target numbers
        refreshInterval: 100,  // how often the element should be updated
        decimals: 2,  // the number of decimal places to show
        onUpdate: null,  // callback method for every time the element is updated,
        onComplete: null
    }, options );
    // how many times to update the value, and how much to increment the value on each update
    var loops = Math.ceil(settings.speed / settings.refreshInterval),
        increment = (settings.to - settings.from) / loops;
    
    return $(this).each(function() {
        var _this = this,
            loopCount = 0,
            value = settings.from,
            interval = setInterval(updateTimer, settings.refreshInterval);

        function updateTimer() {
            value += increment;
            loopCount++;
            $(_this).html(value.toFixed(settings.decimals));
            
            if (typeof(settings.onUpdate) == 'function') {
                settings.onUpdate.call(_this, value);
            }
            
            if (loopCount >= loops) {
                clearInterval(interval);
                value = settings.to;

                if (typeof(settings.onComplete) == 'function') {
                    settings.onComplete.call(_this, value);
                }
            }
        }
    });
};
//funzione per fixare box onscroll down della pagina 
$.fn.gsFixedOnScroll = function() {
    $(this).each(function() {
        var div = $(this);
        var siblingsDiv = div.siblings();
        var calcFixedWidth = $(this).width();
        //var calcFixedWidthMobile = $('body').find('.gs-row').first().width();
        var calcHeightMobile = 4000;
        var calcHeight = $(this).height() + $('body').find('.gs-row').first().height() ; 
        $.each(siblingsDiv,function() {
            calcHeight = calcHeight + $(this).height();
        });

        var fixDiv = function() { 
            if($.breakpointMain()) {
                //mobile.... faccio dopo una soglia fissa 
                //x ora funziona solo solo x .h50 
                if ($(window).scrollTop() > calcHeightMobile && div.hasClass('h50')) {
                    distanceFromBottom = $('body').height()  - ( $(document).height() - ( $('body').scrollTop())  ) + ( div.height() + $('.gs-row-footer').height() + $('.gs-row-footer-bottom').height() );
                    if(distanceFromBottom > 0 ) { 
                        div.css({
                            'position': 'fixed',
                            'bottom': distanceFromBottom+'px' ,
                            'left': '0px',
                            'top': 'auto',
                            'max-width': '100%',
                            'margin-bottom':'0'
                        });
                       if(div.is(':visible')) div.hide();
                    }
                    else {
                        div.css({
                            'position': 'fixed',
                            'bottom': '0px' ,
                            'left': '0px',
                            'top': 'auto',
                            'max-width': '100%',
                            'margin-bottom':'0'
                        });
                       if(!div.is(':visible')) div.show();
                    }
                }
                else {
                    div.css({
                        'position': 'relative',
                        'bottom': 'auto',
                        'left': 'auto',
                        'top': 'auto',
                        'max-width': 'auto',
                        'margin-bottom':'0.9375rem'
                    });
                    if(!div.is(':visible')) div.show();
                }
            }
            else {
                if ($(window).scrollTop() > calcHeight && div.hasClass('h250')) {
                    //60 from top - eventuale differenza (footer) x non andare in sovrapposizione footer
                    //15 è margine bottom di banner
                    distanceFromBottom = $(document).height() - (div.height() + 15 + $('body').scrollTop()) - 60 - $('.gs-row-footer').height() - $('.gs-row-footer-bottom').height();
                    if(distanceFromBottom <0 ) { 
                        div.css({
                            'position': 'fixed',
                            'top': (60+distanceFromBottom)+'px' ,
                            'bottom': 'auto',
                            'max-width': calcFixedWidth+'px'
                        });
                    }
                    else {
                        div.css({
                            'position': 'fixed',
                            'top': '60px' ,
                            'bottom': 'auto',
                            'max-width': calcFixedWidth+'px'
                        });
                    }
                }
                else { 
                    div.css({
                        'position': 'relative',
                        'top': 'auto',
                        'bottom': 'auto',
                        'max-width': 'auto'
                    });
                }
            }
        }
        $(window).on('scroll', function(){ fixDiv(); });
        $(window).on('resize', function(){ fixDiv(); });
    });
}; 
//funzione per ottimizzare visualizzazione di immagini con larghezza uguale
$.fn.gsMosaic = function( options ) {
    var settings = $.extend({
        // These are the defaults.
        itemSelector: ".cover",
        mode: "vertical",
        width: 158,
        effect: null
    }, options );
    
    var mosaic= $(this);
    var map= {}         //qui salvo tutte le celle calcolare x ogni riga trovata 
    var rows= 0;       //numero totale di righe
    var cells= 0;       //numero totale di items per riga 
    var oldCells= 0;       //usato x ricalcolare on resize
    var indexCell = 0;  //puntatore su cella ( disponibile )
    var items=mosaic.children(settings.itemSelector); 
    var item= "";
    var nextPos = { };
    //calcolo width in percentuale da usare x position left
    var widthPercentuale = Math.round( 100 * parseFloat(mosaic.children(settings.itemSelector).css('width')) / parseFloat(mosaic.css('width')));
    var posToTop =0; 
    var posToLeft =0; 
    var totHeight =0; 
    var forceRefresh =false; 
    var run = function() {
        //analizzo ogni items identificando la prima riga di items...i successivi verranno elaborati
        $.each(items.not('.mosaic-calculated'),function() {

            item = $(this); 
            if(rows == 0) {
                //ricavo distanta da inizio contenitore
                //posToTop = item.offset().top - mosaic.offset().top - mosaic.scrollTop();
                posToTop = item.position().top;
            } 
            if(posToTop == 0 && rows == 0) {
                //prima riga di item - aggiorno map css e contatori celle
                item.css('position','relative'); 
                item.css('top','auto'); 
                item.css('left','auto'); 
                
                indexCell++;
                cells++ ;
                //updateMap();
                updateCss(); 
            }
            else {  
                //passo a successive righe di items
                if(rows == 0 ) rows ++; 
                item.css('position','absolute'); 
                //ricavo prossima cella disponibile e aggiorno contatori 
                //aggiorna oggetto nextPos , indexCell , posTop e posLeft) 
                updateIndex();  
                indexCell= nextPos.cell;
                //aggiorno css e map
                //updateMap();
                updateCss();  
            }
            if(item.position().top + item.height() > totHeight) {
                totHeight=item.position().top + item.height();
            }
        });
        //fisso altezza contenitore x non avere "buco"
        mosaic.animate({'padding-bottom':(totHeight - item.height()) + 'px'});
    } 
    var checkCellsChange = function() {
        var newCells = 0 , itemTop = 0 , itemLeft = 0;
        $.each(items,function() {
            item = $(this); 
            itemTop=item.css('top'); 
            //item.css('left'); mi restituisce sempre px io voglio anche % e 
            itemLeft=item[0].style.left; 
            item.css({'position':'relative','top':'auto','left':'auto'}); 
            posToTop = item.position().top;
            if(posToTop == 0) {
                //prima riga di item  
                newCells++ ; 
            }
            else {  
                //fine prima riga  
                item.css({'position':'absolute','top':itemTop,'left':itemLeft}); 
                return false;
            }
        });
        if(newCells == cells) {
            return false;
        }
        else {
            //true = sono cambiate le celle della prima riga
            return true;
        }
    };
    var refresh = function () {
        //se riga resta uguale allora aggiorno solo distanze oriz .. altrimenti tutto 
        var newCells = 0 , itemTop = 0 , itemLeft = 0;
        //resetto classi last e tot height
        oldCells = cells;
        totHeight =0; 
        widthPercentuale = Math.round( 100 * parseFloat(mosaic.children(settings.itemSelector).css('width')) / parseFloat(mosaic.css('width')));
        
        mosaic.children('.mosaic-last-cell').removeClass('mosaic-last-cell');
        //resetto posizione item x calcolo
        resetCss(); 
        $.each(items,function() {

            item = $(this); 
            //itemTop=item.css('top');
            //itemLeft=item.css('left');
            //resetCss(); 
            //ricavo distanta da inizio contenitore
            posToTop = item.position().top;
            
            if(posToTop == 0) {
                //prima riga di item  
                newCells++ ;
                //salvo cella in item 
                item.addClass('mosaic-cell-'+newCells + ' mosaic-last-cell mosaic-calculated'); 
            }
            else {  
                //fine prima riga
                item.css('position','absolute'); 
                if(newCells == cells) {
                    //aggiorno solo distanze orizzontali
                    //item.css({'top':itemTop,'left':itemLeft});
                    updateIndex();
                    indexCell= nextPos.cell;
                    updatePosition();
                }
                else {
                    //è cambiato numero di celle x riga - setto nuovo numero di celle
                    cells=newCells;
                    //ricalcolo
                    updateIndex();
                    indexCell= nextPos.cell;
                    updateCss(); 
                } 
            } 
            if(item.position().top + item.height() > totHeight) {
                totHeight=item.position().top + item.height();
            }
        });
        //fisso altezza contenitore x non avere "buco"
        mosaic.animate({'padding-bottom':(totHeight - item.height()) + 'px'});
        //azzero flag di richiesta refresh
        forceRefresh = false ;
    };
    var updateContainerHeight = function() {
        //reimposto altezza contenitore
        mosaic.animate({'padding-bottom':(totHeight - item.height()) + 'px'});
    };
    var updatePosition = function() {
        mosaic.children('.mosaic-last-cell.mosaic-cell-'+indexCell).removeClass('mosaic-last-cell');
        item.addClass('mosaic-last-cell mosaic-cell-'+indexCell + ' mosaic-calculated');  
        //sosposto  
        if(widthPercentuale) {
            item.css({'top':((posToTop)+2)+'px','left':(posToLeft)+'%'});
        }
        else {
            item.css({'top':((posToTop)+2)+'px','left':(posToLeft)+'px'});
        }
    };
    var resetCss = function () {
        var i = 1 , str =""; 
        while( i <= oldCells) { 
            //item.removeClass('mosaic-cell-'+i); 
            str += 'mosaic-cell-'+i+' ';
            i++;
        }
        //reset di tutti gli items
        items.removeClass('mosaic-last-cell mosaic-calculated '+str); 
        items.css({'position':'relative','top':'auto','left':'auto'}); 
    };
    var updateCss = function () {
        if(indexCell) {
            //rimuovo flag ultimo item inserito
            mosaic.children('.mosaic-last-cell.mosaic-cell-'+indexCell).removeClass('mosaic-last-cell');
            //setto flag
            item.addClass('mosaic-calculated mosaic-cell-'+indexCell + ' mosaic-last-cell'); 
            
            if(rows>0) {
                //sosposto  
                if(widthPercentuale) {
                    item.css({'top':((posToTop)+2)+'px','left':(posToLeft)+'%'});
                }
                else {
                    item.css({'top':((posToTop)+2)+'px','left':(posToLeft)+'px'});
                }
            }
        } 
    };
    var updateIndex = function () { 
        var i = 1, 
            top = 0 , 
            left= 0 ;
        //resetto nextpos cell
        nextPos.cell="";
        while( i <= cells) {  
            top=mosaic.children('.mosaic-last-cell.mosaic-cell-'+i).position().top;
            top=top+mosaic.children('.mosaic-last-cell.mosaic-cell-'+i).height();
            if(widthPercentuale) {
                left = (i -1 ) * widthPercentuale ;
            }
            else {
                left = mosaic.children('.mosaic-last-cell.mosaic-cell-'+i).position().left;
            }
            if(top<nextPos.top || !nextPos.cell ) {
                posToTop=top;
                posToLeft=left;
                nextPos.top=top;
                nextPos.left=left;
                nextPos.cell=i; 
            }
            i++;
        } 
    };
    var init = function() {
        run();
        //eventi x ricalcolare mosaic
        $(document.body).on("closed_gsclearing", function(event) {
            //ricalcolo altezza container
            updateContainerHeight();
            if(widthPercentuale) {
            //verifico se è cambiato numero di celle altrimenti esco
                if(checkCellsChange() === true || forceRefresh === true ) refresh();
            }
            else {
                refresh();
            }
        });
        $(window).on('resize', function() {
            if(widthPercentuale) {
            //verifico se è cambiato numero di celle altrimenti esco
                if(checkCellsChange() === true || forceRefresh === true ) refresh();
            }
            else {
                refresh();
            }
        });
    };
    init();
 
    //metodi pubblici richiamabili dall'utente
    return {
        nextPos: function () { return nextPos; },
        appendData: function (data) {  
            //aggiorno lista items 
            items=mosaic.children(settings.itemSelector);
            //eseguo calcoli
            run();
        },
        refresh: function() {
            //ricalcolo subito  ( è un reset utile x loadmorePrev ) 
            refresh();
        },
        refreshOnNextEvent: function() {
            //setto flag x forzare ricalcolo ( è un reset ) 
            forceRefresh = true;
            //quindi aggiorno items 
            items=mosaic.children(settings.itemSelector); 
        }
    }
};