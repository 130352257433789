//script x video con flowplayer
//a id=\"player\" data-code=\"".$video["ref_code"]."\" class=\"player\"
function initFlowPlayerVideo( domObj) {
    var id = $(domObj).data('code');
    var idDom = $(domObj).attr('id');
    if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i)) {
        $f(idDom, {
                src: "/flowplayer/OK/flowplayer-3.2.18.swf",
                wmode: 'opaque'
            }, {
                key: '#$09ce03a35b17ee17229',
                flashfit: 'true',
                clip: {
					eventCategory: 'Video '+id,
					autoPlay: true,
				},
				playlist: [
					{
						eventCategory: 'Splash Video',
						url: 'https://video.gamestorm.it/thm/'+id+'.jpg',
						//scaling: 'scale'
					},
					{
						eventCategory: 'Video in mobile',
						url: 'https://video.gamestorm.it/src/'+id+'.mp4',
						provider: 'lighttpd',
						scaling: 'fit'
					}
				],
                plugins: {
					lighttpd: {
            					url: "/flowplayer/OK/flowplayer.pseudostreaming-3.2.13.swf",
						// use ${start} as a placeholder for the target keyframe
        					queryString: escape('?start=${start}')
        				},
					   gatracker: {
							url: "/flowplayer/OK/flowplayer.analytics-3.2.8.swf",
							events: {
				                all: true
				            },
							debug: false,
							accountId: "UA-381278-2"
						},
						ova: {
							url: "/flowplayer/OK/ova.swf",
							"ads": {
							   "schedule": [ 
								  {
									 "position": "pre-roll",
									 "tag": "http://adx.4strokemedia.com/www/delivery/fc.php?script=apVideo:vast2__amp__zoneid=605"
									 //"tag": "http://ads.intergi.com/adrawdata/3.0/5205/3576292/0/1013/ADTECH;cors=yes;width=__WIDTH__;height=__HEIGHT__;referring_url=__WEB_URL__;content_url=__CONTENT_URL__;media_id=__MEDIA_ID__;title=__TITLE__;device=__DEVICE__;model=__MODEL__;os=__OS__;osversion=__OSVERSION__;ua=__UA__;ip=__IP__;uniqueid:__UNIQUEID__;tags=__TAGS__;number=__RANDOM__;time=__TIME__"
								  } 
							   ]
							},
							"debug": {
						        "levels": "none"
						    }
						}
				},
				debug: false
            }).ipad({ simulateiDevice: true, controls: true });
    }
    else {
        $f(idDom, {
                src: "/flowplayer/OK/flowplayer-3.2.18.swf",
                wmode: 'opaque'
            }, {
                key: '#$09ce03a35b17ee17229',
                clip: {
					eventCategory: 'Video '+id,
					autoPlay: true,	
					urlResolvers: 'brselect',
					bitrates: [

			            { url: "https://video.gamestorm.it/src/SD/SD."+id+".mp4", bitrate: 800, sd: true},
			
			            // this is the HD item, marked with hd: true
			            { url: "https://video.gamestorm.it/src/"+id+".mp4", bitrate: 1600, hd: true, isDefault: true}
			       	]
				},
				playlist: [
					{
						eventCategory: 'Splash Video',
						url: 'https://video.gamestorm.it/thm/'+id+'.jpg'
                        //,scaling: 'scale'
					},
					{
						eventCategory: 'Video',
						url: 'https://video.gamestorm.it/src/'+id+'.mp4',
						provider: 'lighttpd',
						scaling: 'fit'
					}
				],
                plugins: {
					brselect: {
			            url: "/flowplayer/OK/flowplayer.bitrateselect-3.2.14.swf",
			            hdButton:  {
			            	place: 'controls',
				            splash: {'width': '10%', 'offLabel': 'OFF', 'top': '10', 'left': '10', 'onLabel': 'ON', 'height': '10%'}
				    	}
			        },
			        dock: {
			            gap: 5,
			            autoHide: {			 
			                // make it hide faster
			                hideDelay: 0,
			                mouseOutDelay: 200
			            }
			        },
					lighttpd: {
            					url: "/flowplayer/OK/flowplayer.pseudostreaming-3.2.13.swf",
						// use ${start} as a placeholder for the target keyframe
        					queryString: escape('?start=${start}')
        				},
					   gatracker: {
							url: "/flowplayer/OK/flowplayer.analytics-3.2.8.swf",
							events: {
				                all: true
				            },
							debug: false,
							accountId: "UA-381278-2"
						},
						ova: {
							url: "/flowplayer/OK/ova.swf",
							"ads": {
							   "schedule": [ 
								  {
									 "position": "pre-roll",
									 "tag": "http://adx.4strokemedia.com/www/delivery/fc.php?script=apVideo:vast2__amp__zoneid=605"
									 //"tag": "http://ads.intergi.com/adrawdata/3.0/5205/3576292/0/1013/ADTECH;cors=yes;width=__WIDTH__;height=__HEIGHT__;referring_url=__WEB_URL__;content_url=__CONTENT_URL__;media_id=__MEDIA_ID__;title=__TITLE__;device=__DEVICE__;model=__MODEL__;os=__OS__;osversion=__OSVERSION__;ua=__UA__;ip=__IP__;uniqueid:__UNIQUEID__;tags=__TAGS__;number=__RANDOM__;time=__TIME__"
								  } 
							   ]
							},
							"debug": {
						        "levels": "none"
						    }
						}
				},

				debug: false
            }).ipad();
        }
}
$(document).ready(function() {
    $.each($('.video-flowplayer-container > a'),function(){
        initFlowPlayerVideo(this);
        $(this).addClass('loaded');
    });
    $(document).on('refreshVideo',function(){ 
        $.each($('.video-flowplayer-container > a:not(.loaded)'),function(){
            initFlowPlayerVideo(this);
            $(this).addClass('loaded');
        });
    });
});