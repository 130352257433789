$.fn.gsPasswordStrength = function () {  
    
    var elementToValidate = this;
    var passwordDiv = "";
    // Validation settings
    var noSpecialChars = true;     // Sets if special characters (punctuation etc.) can be in password
    var isPasswordRequired = true;  // Sets if the password is a required field
    var showTip = true;             // Show a tip to users if their password is not perfect

    // Custom strings for personalisation or i18n
    var strSpecialChars = "Special characters not allowed";     // Displays when user enters special chars
    var strWeak = "Password troppo facile!";       // Displays when password is weak strength
    var strMedium = "Password può essere più sicura";   // Displays when password is medium strength
    var strStrong = "Password sicura!";          // Displays when password is perfect
 


    /*************** End of user specified settings **********/
    /*************** DO NOT EDIT BELOW THIS LINE ****************/


    var tip = 'Consigli per creare la giusta password<br><br>1.Non deve essere una parola comune. Queste password sono facili da scoprire!<br><br>2.Deve contenere almeno un numero, una lettera in maiuscolo ed una in minuscolo.';

    /************** Create the validator **************/
    function createPasswordValidator( )
	{	 
		// Get the element to validate 
		if($(elementToValidate).length <= 0)
		{
			//alert('Password Validator could not find your password field identified by id='+elementToValidate);
			return;
		}
		
		// Create visual output
        var elmId = $(elementToValidate).prop("id");
		var output = '<div id="_pwdvalid'+elmId+'" class="pwdvalid"></div>';
        //uso next xke dopo input ci sta span usato come label di errore nella validazioen della form .. quindi lo metto dopo lo span
		$( output ).insertAfter( $(elementToValidate).next() );
		passwordDiv = $("#_pwdvalid"+elmId);
		
		// Register event handlers
		// Use quirksmode idea for flexible registration by copying existing events
		// onKeyUp
        $(elementToValidate).on('keypress keyup keydown',validatePassword); 	
	}
	
    function validatePassword( ) 
	{ 
		var passwordString = $(elementToValidate).val();
		if(passwordString.length == 0)
		{ 
			passwordDiv.html( "" );
			passwordDiv.removeClass("val1 val2 val3");
			return;
		} 
		// Match special characters
		//if(passwordString.match(/\W/))
		//{
		//	passwordDiv.innerHTML = strSpecialChars;
		//	return;
		//}			
		var strength = 0;
		// Match upper case characters
		if(passwordString.match(/[a-z]/))
		{
			strength++;
		}
		// Match lower case characters
		if(passwordString.match(/[A-Z]/))
		{
			strength++;
		}
		// Match digits
		if(passwordString.match(/\d/))
		{
			strength++;
		}		
		switch(strength)
		{
			case 1: passwordDiv.html( strWeak);
					displayTip( );
                    passwordDiv.addClass("val1");
                    passwordDiv.removeClass("val2 val3");
					break;
			case 2: passwordDiv.html( strMedium);
                    passwordDiv.addClass("val2");
			        passwordDiv.removeClass("val1 val3");
					displayTip( );
					break;
			case 3: passwordDiv.html( strStrong);
					displayTip( );
                    passwordDiv.addClass("val3");
			        passwordDiv.removeClass("val1 val2");
					break;
            default:
                    passwordDiv.removeClass("val1 val2 val3");
                    passwordDiv.html( "" );
                break;
		}				
	}
		
	function displayTip(  )
	{		
		// Show tip 	
        if(passwordDiv.hasClass("val3")) {
			passwordDiv.append( '&nbsp;'+'<i class="fa fa-check " ></i>');  
        }
        else {
			passwordDiv.append( '&nbsp;'+'<i class="fa fa-info-circle " data-tooltip aria-haspopup="true"  data-options="disable_for_touch:false" class="has-tip" title="'+tip+'"></i>');  
            //x tooltip
            $(document).foundation('tooltip', 'reflow');
        }
    //                           <a href="javascript:alert(\''+tip+'\');" style="font-size:smaller; text-decoration: none">Informazioni</a>');
	}

    createPasswordValidator();
};
							
							 
		
	
